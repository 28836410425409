import {Location} from '@reach/router';
import {State} from 'react-powerplug';
import {colors, layers} from '@atlaskit/theme';
import AddIcon from '@atlaskit/icon/glyph/add';
import AkNavigation, {
  AkContainerLogo,
  AkCreateDrawer,
  AkNavigationItem,
} from '@atlaskit/navigation';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import MediaQuery from 'react-responsive';
import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import ym from 'react-yandex-metrika';

import {G} from 'src/types/graphql';
import {OkautoIcon} from 'src/components/OkautoIcon';
import {UnderlinelessLink} from 'src/components/styled';
import {is} from 'shared/utils/data-types';

import {AccountDropdownMenuContainer} from './containers/AccountDropdownMenuContainer';
import {AppBar} from './components/AppBar';
import {CreateDrawer} from './components/CreateDrawer';

const ContainerLogoWrapper = styled.div`
  & > div:first-child {
    height: inherit;
  }
`;
const NavigationBlanket = styled.div<{isTinted: boolean}>`
  background: ${colors.N100A};
  bottom: 0;
  left: 0;
  opacity: ${props => (props.isTinted ? 1 : 0)};
  pointer-events: ${props => (props.isTinted ? 'initial' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 220ms;
  z-index: ${layers.navigation() - 1};
`;

interface AppNavigationProps {
  viewer: G.AppNavigation_viewer;
  containerTitle: string;
  containerLinks: [string, string, React.ComponentType<any>, string?][];
  createLinks?: [string, string, string, React.ComponentType<any>][];
  initiallyClosed?: boolean;
}

export class AppNavigation extends React.Component<AppNavigationProps> {
  public static fragments = {
    viewer: gql`
      fragment AppNavigation_viewer on User {
        id
        role
        hubUsername
      }
    `,
  };

  public render() {
    return (
      <State
        initial={{
          resizeState: {
            isOpen: !this.props.initiallyClosed,
            width: this.props.initiallyClosed ? 64 : 304,
          },
          showOverlay: false,
          openedDrawerName: null as string | null,
        }}
      >
        {({state, setState}) => {
          const globalPrimaryIcon = (
            <OkautoIcon
              label="OKauto"
              size="large"
              primaryColor={state.resizeState.isOpen ? 'white' : colors.B500}
            />
          );

          return (
            <MediaQuery
              query="(min-width: 1024px)"
              onBeforeChange={onDesktop => {
                setState({showOverlay: !onDesktop});
              }}
            >
              {onDesktop => (
                <>
                  {!onDesktop && (
                    <AppBar
                      navigationShown={state.showOverlay}
                      onShowNavigation={() => {
                        setState({showOverlay: true});
                      }}
                    />
                  )}
                  {(onDesktop || state.showOverlay) && (
                    <AkNavigation
                      containerHeaderComponent={() => (
                        <ContainerLogoWrapper>
                          <AkContainerLogo>
                            <h3>{this.props.containerTitle}</h3>
                          </AkContainerLogo>
                        </ContainerLogoWrapper>
                      )}
                      globalPrimaryIcon={globalPrimaryIcon}
                      globalPrimaryItemHref="/"
                      globalSecondaryActions={[
                        <AccountDropdownMenuContainer
                          key="account"
                          viewer={this.props.viewer}
                        />,
                      ]}
                      isOpen={state.resizeState.isOpen}
                      width={state.resizeState.width}
                      onResize={(resizeState: typeof state['resizeState']) => {
                        setState({resizeState});
                      }}
                      hasScrollHintTop
                      {...(this.props.createLinks &&
                      this.props.createLinks.length !== 0
                        ? {
                            globalCreateIcon: <AddIcon label="Создать" />,
                            drawers: [
                              <AkCreateDrawer
                                backIcon={
                                  <ArrowLeftIcon label="Назад" size="medium" />
                                }
                                isOpen={state.openedDrawerName === 'create'}
                                key="create"
                                onBackButton={() => {
                                  setState({openedDrawerName: null});
                                }}
                                primaryIcon={globalPrimaryIcon}
                              >
                                <CreateDrawer links={this.props.createLinks} />
                              </AkCreateDrawer>,
                            ],
                            onCreateDrawerOpen: () => {
                              setState({openedDrawerName: 'create'});
                            },
                          }
                        : {})}
                    >
                      {this.props.containerLinks.map(link => {
                        const [url, title, Icon, eventName] = link;

                        const sendMetricaEvent = () => {
                          is.nonEmptyString(eventName) &&
                            ym('reachGoal', eventName);
                        };

                        return (
                          <Location key={url}>
                            {({location}) => (
                              <UnderlinelessLink
                                to={url}
                                onClick={sendMetricaEvent}
                              >
                                <AkNavigationItem
                                  icon={<Icon label={title} size="medium" />}
                                  text={title}
                                  isSelected={location.pathname === url}
                                />
                              </UnderlinelessLink>
                            )}
                          </Location>
                        );
                      })}
                    </AkNavigation>
                  )}
                  <NavigationBlanket
                    isTinted={!onDesktop && state.showOverlay}
                    onClick={() => {
                      setState({showOverlay: false});
                    }}
                  />
                </>
              )}
            </MediaQuery>
          );
        }}
      </State>
    );
  }
}
