import {colors} from '@atlaskit/theme';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {AllHubUsersTable} from '../components/AllHubUsersTable';
import {HUB_USER_UPDATE_MUTATION} from '../graphql';
import {HubUserUpdateDropdownMenu} from '../components/HubUserUpdateDropdownMenu';

interface HubUserUpdateContainerProps {
  hubUser: G.AllHubUsersTable_user;
}

export const HubUserUpdateDropdownMenuContainer: React.FunctionComponent<
  HubUserUpdateContainerProps
> = props => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Mutation
        mutation={HUB_USER_UPDATE_MUTATION}
        update={(cache, {data}) => {
          if (data && data.hubUserUpdate) {
            cache.writeFragment<G.AllHubUsersTable_user>({
              id: props.hubUser.id,
              fragment: AllHubUsersTable.fragments.user,
              data: data.hubUserUpdate.hubUser,
            });
          }
        }}
        onCompleted={() => {
          onAddFlag({
            autoDismiss: true,
            icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
            title: `Админ «${props.hubUser.hubUsername}» обновлён`,
          });
        }}
        onError={err => {
          onAddFlag({
            autoDismiss: true,
            icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
            title: 'Произошла ошибка',
            description: `Не удалось обновить админа «${
              props.hubUser.hubUsername
            }»: ${messageForApolloError(err)}`,
          });
        }}
      >
        {(hubUserUpdate, {loading: updatingHubUser}) => (
          <HubUserUpdateDropdownMenu
            hubUser={props.hubUser}
            onHubUserUpdate={hubUserUpdate}
            updatingHubUser={updatingHubUser}
          />
        )}
      </Mutation>
    )}
  </FlagsConsumer>
);
