import InlineEdit from '@atlaskit/inline-edit';
import Lozenge from '@atlaskit/lozenge';
import React, {useState} from 'react';
import Select from '@atlaskit/select';

import {G} from 'src/types/graphql';
import {is} from 'shared/utils/data-types';

interface InsuranceApplicationStatusProps {
  insuranceApplication: G.AllInsuranceApplicationsTable_insuranceApplication;
  onInsuranceApplicationStatusUpdate: (
    status: G.InsuranceApplicationStatus,
  ) => void;
}

export const InsuranceApplicationStatus: React.FunctionComponent<
  InsuranceApplicationStatusProps
> = props => {
  const [status, setStatus] = useState(props.insuranceApplication.status);

  return (
    <InlineEdit
      isLabelHidden
      disableEditViewFieldBase
      isConfirmOnBlurDisabled
      onConfirm={() => {
        props.onInsuranceApplicationStatusUpdate(status);
      }}
      editView={
        <Select
          id="input-status"
          name="status"
          value={
            is.nonEmptyString(status)
              ? {
                  label: status,
                  value: status,
                }
              : null
          }
          placeholder="Выберите статус"
          onChange={({
            value,
          }: {
            label: string;
            value: G.InsuranceApplicationStatus;
          }) => {
            setStatus(value);
          }}
          options={Object.values(G.InsuranceApplicationStatus).map(
            insuranceApplicationStatus => ({
              label: insuranceApplicationStatus,
              value: insuranceApplicationStatus,
            }),
          )}
        />
      }
      readView={
        <Lozenge
          appearance={(() => {
            if (
              props.insuranceApplication.status ===
              G.InsuranceApplicationStatus.SUCCEEDED
            ) {
              return 'success';
            }
            if (
              props.insuranceApplication.status ===
              G.InsuranceApplicationStatus.CANCELLED
            ) {
              return 'removed';
            }

            return 'default';
          })()}
        >
          {props.insuranceApplication.status}
        </Lozenge>
      }
    />
  );
};
