import {ApolloError} from 'apollo-client';
import {Checkbox} from '@atlaskit/checkbox';
import {DatePicker} from '@atlaskit/datetime-picker';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import Button from '@atlaskit/button/dist/es5/components/Button';
import Form, {FormFooter, FormSection} from '@atlaskit/form';
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';

import {FormField} from 'src/components/FormField';
import {G} from 'src/types/graphql';
import {InlineModalFormWrapper} from 'src/components/styled';
import {Mutation} from 'src/utils/react-apollo';
import {is, Opt} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {EDIT_OR_CREATE_PARTNER_MUTATION} from '../graphql';

interface PartnerEditOrCreateModalDialogProps {
  isEditOrCreateModalOpen: boolean;
  setEditOrCreateModalOpenState: (isEditOrCreateModalOpen: boolean) => any;
  partnerEditOrCreateError: ApolloError | null;
  setPartnerEditOrCreateErrorState: (
    partnerEditOrCreateError: ApolloError | null,
  ) => any;
  partner?: G.AllPartnersTable_partners_edges_node;
}

const PAY_MODES = [
  {label: 'Оплата не доступна', value: G.PartnerPayMode.DISABLED},

  {label: 'Оплачивает сам', value: G.PartnerPayMode.CARD},
  {
    label: 'Оплачивают с автоматического депозита',
    value: G.PartnerPayMode.DEPOSIT_AUTO,
  },
  {
    label: 'Оплачивают с ручного депозита',
    value: G.PartnerPayMode.DEPOSIT_MANUAL,
  },
];

export const PAY_MODES_TO_SELECT_MAP = {
  [G.PartnerPayMode.DISABLED]: {
    label: 'Оплата не доступна',
    value: G.PartnerPayMode.DISABLED,
  },
  [G.PartnerPayMode.DEPOSIT_MANUAL]: {
    label: 'Оплачивают с депозита',
    value: G.PartnerPayMode.DEPOSIT_MANUAL,
  },
  [G.PartnerPayMode.DEPOSIT_AUTO]: {
    label: 'Оплачивают с автоматического депозита',
    value: G.PartnerPayMode.DEPOSIT_AUTO,
  },
  [G.PartnerPayMode.CARD]: {
    label: 'Оплачивает сам',
    value: G.PartnerPayMode.CARD,
  },
};

export const PartnerEditOrCreateModalDialog: React.FunctionComponent<
  PartnerEditOrCreateModalDialogProps
> = props => {
  const closeModal = () => {
    props.setEditOrCreateModalOpenState(false);
    props.setPartnerEditOrCreateErrorState(null);
  };

  return (
    <Mutation
      refetchQueries={['allPartners']}
      mutation={EDIT_OR_CREATE_PARTNER_MUTATION}
      // provide a noop here, otherwise an unhandled error is thrown
      onError={error => props.setPartnerEditOrCreateErrorState(error)}
      onCompleted={closeModal}
    >
      {(partnerEditOrCreate, {loading}) => {
        const headingMessage = props.partner
          ? `Редактировать: ${props.partner.name}`
          : `Окно добавления партнера`;
        const modalFooter = () => <ModalFooter />;

        return props.isEditOrCreateModalOpen ? (
          <ModalTransition>
            <ModalDialog
              onClose={closeModal}
              footer={modalFooter}
              width="small"
              heading={headingMessage}
            >
              <InlineModalFormWrapper>
                <Formik
                  initialValues={{
                    sample: '',
                    name: props.partner ? props.partner.name : '',
                    email: props.partner
                      ? Opt.nonEmptyString(props.partner.email).getOrElse('')
                      : '',
                    active: props.partner ? props.partner.active : false,
                    aisbddParsingEnabled: props.partner
                      ? props.partner.aisbddParsingEnabled
                      : false,
                    blackParsingEnabled: props.partner
                      ? props.partner.blackParsingEnabled
                      : false,
                    blueParsingEnabled: props.partner
                      ? props.partner.blueParsingEnabled
                      : false,
                    slackToken: props.partner
                      ? Opt.nonEmptyString(props.partner.slackToken).getOrElse(
                          '',
                        )
                      : '',
                    slackAisbddPenaltiesConversationId: props.partner
                      ? Opt.nonEmptyString(
                          props.partner.slackAisbddPenaltiesConversationId,
                        ).getOrElse('')
                      : '',
                    penaltyRelevanceDateThresholdIso: props.partner
                      ? Opt.nonEmptyString(
                          props.partner.penaltyRelevanceDateThreshold,
                        ).getOrElse('')
                      : new Date().toISOString(),
                    binNumber: props.partner
                      ? Opt.nonEmptyString(props.partner.binNumber).getOrElse(
                          '',
                        )
                      : '',
                    registrationDate:
                      props.partner && props.partner.registrationDate,
                    individualEntrepreneur: props.partner
                      ? props.partner.individualEntrepreneur
                      : false,
                    iinNumber: props.partner
                      ? Opt.nonEmptyString(
                          props.partner.identityCard &&
                            props.partner.identityCard.iinNumber,
                        ).getOrElse('')
                      : '',
                    identityCardNumber: props.partner
                      ? Opt.nonEmptyString(
                          props.partner.identityCard &&
                            props.partner.identityCard.number,
                        ).getOrElse('')
                      : '',
                    ccEmail:
                      props.partner && is.nonEmptyArray(props.partner.ccEmails)
                        ? props.partner.ccEmails[0]
                        : '',
                    carLimit:
                      props.partner && is.positiveInt(props.partner.carLimit)
                        ? props.partner.carLimit
                        : '',
                    payMode: props.partner
                      ? PAY_MODES_TO_SELECT_MAP[props.partner.payMode]
                      : PAY_MODES_TO_SELECT_MAP[G.PartnerPayMode.DISABLED],
                  }}
                  validate={values => {
                    const mutableErrors: FormikErrors<typeof values> = {};
                    if (
                      is.nonEmptyArray(values.name) &&
                      values.name.length === 12 &&
                      is.positiveInt(Number(values.name))
                    ) {
                      mutableErrors.name = 'Пожалуйста, введите имя партнера.';
                    }
                    if (!is.nonEmptyString(values.registrationDate)) {
                      mutableErrors.registrationDate =
                        'Пожалуйста, выберите дату регистрации';
                    }

                    return mutableErrors;
                  }}
                  onSubmit={values => {
                    partnerEditOrCreate({
                      variables: {
                        input: {
                          id: props.partner && props.partner.id,
                          name: props.partner
                            ? props.partner.name
                            : values.name,
                          email: values.email,
                          active: values.active,
                          aisbddParsingEnabled: values.aisbddParsingEnabled,
                          slackToken: values.slackToken,
                          slackAisbddPenaltiesConversationId:
                            values.slackAisbddPenaltiesConversationId,
                          penaltyRelevanceDateThresholdIso:
                            values.penaltyRelevanceDateThresholdIso,
                          binNumber: values.binNumber,
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          registrationDate: values.registrationDate!,
                          ccEmails: [values.ccEmail],
                          carLimit: Number(values.carLimit),
                          payMode: values.payMode.value,
                          blackParsingEnabled: values.blackParsingEnabled,
                          blueParsingEnabled: values.blueParsingEnabled,
                          individualEntrepreneur: values.individualEntrepreneur,
                          iinNumber: values.iinNumber,
                          identityCardNumber: values.identityCardNumber,
                        },
                      },
                    });
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} onReset={handleReset}>
                      <FormSection>
                        {props.partnerEditOrCreateError ? (
                          <SectionMessage appearance="error">
                            {messageForApolloError(
                              props.partnerEditOrCreateError,
                            )}
                          </SectionMessage>
                        ) : (
                          // HACK: `form` prop is injected, so can't be just null here
                          <div />
                        )}
                        {!props.partner ? (
                          <FormField
                            label="Наименование партнера"
                            inputId="input-name"
                            inputRequired
                            invalid={
                              touched.name && is.nonEmptyString(errors.name)
                            }
                            invalidMessage={errors.name}
                          >
                            <FieldTextStateless
                              id="input-name"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Введите имя партнера"
                              value={values.name}
                              required
                              isLabelHidden
                              shouldFitContainer
                            />
                          </FormField>
                        ) : (
                          <></>
                        )}
                        <FormField label="Email" inputId="input-email">
                          <FieldTextStateless
                            id="input-email"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите Email"
                            value={values.email}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="Вторичный получатель"
                          inputId="input-ccEmail"
                        >
                          <FieldTextStateless
                            id="input-ccEmail"
                            type="text"
                            name="ccEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите Email"
                            value={values.ccEmail}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="Slack Token"
                          inputId="input-slackToken"
                        >
                          <FieldTextStateless
                            id="input-slackToken"
                            type="text"
                            name="slackToken"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите SlackToken"
                            value={values.slackToken}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="Slack Penalties Conversation Id"
                          inputId="input-slackAisbddPenaltiesConversationId"
                        >
                          <FieldTextStateless
                            id="input-slackAisbddPenaltiesConversationId"
                            type="text"
                            name="slackAisbddPenaltiesConversationId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите Slack Penalties Conversation Id"
                            value={values.slackAisbddPenaltiesConversationId}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField inputId="input-individualEntrepreneur">
                          <Checkbox
                            id="input-individualEntrepreneur"
                            name="individualEntrepreneur"
                            onChange={(value: {target: {checked: boolean}}) => {
                              setFieldValue(
                                'individualEntrepreneur',
                                value.target.checked,
                              );
                              if (value.target.checked) {
                                setFieldValue('binNumber', '');
                                setFieldValue('regNumber', '');
                              } else {
                                setFieldValue('iinNumber', '');
                                setFieldValue('identityCardNumber', '');
                              }
                            }}
                            value={values.individualEntrepreneur}
                            isChecked={values.individualEntrepreneur}
                            label="ИП"
                          />
                        </FormField>
                        {values.individualEntrepreneur ? (
                          <>
                            <FormField label="ИИН" inputId="input-iinNumber">
                              <FieldTextStateless
                                id="input-iinNumber"
                                type="text"
                                name="iinNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Введите ИИН"
                                value={values.iinNumber}
                                isLabelHidden
                                shouldFitContainer
                              />
                            </FormField>
                            <FormField
                              label="Номер удостоверения"
                              inputId="input-identityCardNumber"
                            >
                              <FieldTextStateless
                                id="input-identityCardNumber"
                                type="text"
                                name="identityCardNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Введите номер удостоверения"
                                value={values.identityCardNumber}
                                isLabelHidden
                                shouldFitContainer
                              />
                            </FormField>
                          </>
                        ) : (
                          <>
                            <FormField label="БИН" inputId="input-binNumber">
                              <FieldTextStateless
                                id="input-binNumber"
                                type="text"
                                name="binNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Введите БИН"
                                value={values.binNumber}
                                isLabelHidden
                                shouldFitContainer
                              />
                            </FormField>
                            <FormField
                              label="Дата регистрации"
                              inputId="input-registrationDate"
                              inputRequired
                              invalid={
                                touched.name &&
                                is.nonEmptyString(errors.registrationDate)
                              }
                              invalidMessage={errors.registrationDate}
                            >
                              <DatePicker
                                id="input-registrationDate"
                                name="registrationDate"
                                onChange={(value: string) => {
                                  setFieldValue('registrationDate', value);
                                }}
                                onBlur={handleBlur}
                                defaultValue={values.registrationDate}
                                value={values.registrationDate}
                                required
                              />
                            </FormField>
                          </>
                        )}
                        <FormField
                          label="Штрафы актуальны с"
                          inputId="input-penaltyRelevanceDateThresholdIso"
                        >
                          <DatePicker
                            id="input-penaltyRelevanceDateThresholdIso"
                            name="penaltyRelevanceDateThresholdIso"
                            onChange={(value: string) => {
                              setFieldValue(
                                'penaltyRelevanceDateThresholdIso',
                                value,
                              );
                            }}
                            onBlur={handleBlur}
                            defaultValue={
                              values.penaltyRelevanceDateThresholdIso
                            }
                            value={values.penaltyRelevanceDateThresholdIso}
                          />
                        </FormField>
                        <FormField
                          label="Лимит машин"
                          inputId="input-carLimit"
                          invalid={
                            touched.carLimit && is.positiveInt(errors.carLimit)
                          }
                        >
                          <FieldTextStateless
                            id="input-carLimit"
                            type="number"
                            name="carLimit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.carLimit.toString()}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="Способ оплаты штрафов"
                          inputId="input-payMode"
                        >
                          <Select
                            inputId="input-payMode"
                            options={PAY_MODES}
                            onChange={(value: G.PartnerPayMode) => {
                              setFieldValue('payMode', value);
                            }}
                            defaultValue={values.payMode}
                            value={values.payMode}
                          />
                        </FormField>
                        <FormField inputId="input-active">
                          <Checkbox
                            id="input-active"
                            name="active"
                            onChange={handleChange}
                            value={values.active}
                            isChecked={values.active}
                            label="Активировать"
                          />
                        </FormField>
                        <FormField inputId="input-aisbddParsingEnabled">
                          <Checkbox
                            id="input-aisbddParsingEnabled"
                            name="aisbddParsingEnabled"
                            onChange={handleChange}
                            value={values.aisbddParsingEnabled}
                            isChecked={values.aisbddParsingEnabled}
                            label="Активировать парсинг АИСБДД"
                          />
                        </FormField>
                        <FormField inputId="input-blackParsingEnabled">
                          <Checkbox
                            id="input-blackParsingEnabled"
                            name="blackParsingEnabled"
                            onChange={handleChange}
                            value={values.blackParsingEnabled}
                            isChecked={values.blackParsingEnabled}
                            label="Активировать черный парсинг"
                          />
                        </FormField>
                        <FormField inputId="input-blueParsingEnabled">
                          <Checkbox
                            id="input-blueParsingEnabled"
                            name="blueParsingEnabled"
                            onChange={handleChange}
                            value={values.blueParsingEnabled}
                            isChecked={values.blueParsingEnabled}
                            label="Активировать синий парсинг"
                          />
                        </FormField>
                      </FormSection>
                      <FormFooter>
                        <Button
                          appearance="primary"
                          type="submit"
                          isDisabled={loading}
                          isLoading={loading}
                        >
                          {props.partner ? `Сохранить` : `Добавить`}
                        </Button>
                        <Button
                          appearance="link"
                          type="button"
                          isDisabled={loading}
                          onClick={closeModal}
                        >
                          Отмена
                        </Button>
                      </FormFooter>
                    </Form>
                  )}
                </Formik>
              </InlineModalFormWrapper>
            </ModalDialog>
          </ModalTransition>
        ) : null;
      }}
    </Mutation>
  );
};
