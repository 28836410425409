import {State} from 'react-powerplug';
import React from 'react';

import {Query} from 'src/utils/react-apollo';
import {offsetToCursor} from 'src/utils/graphql-relay';

import {ALL_HUB_USERS_FOR_ADMIN_QUERY} from '../graphql';
import {AllHubUsersTable} from '../components/AllHubUsersTable';
import {HubUserUpdateDropdownMenuContainer} from './HubUserUpdateDropdownMenuContainer';

const ROWS_PER_PAGE = 25;

interface AllHubUsersTableContainerProps {}

export const AllHubUsersTableContainer: React.FunctionComponent<
  AllHubUsersTableContainerProps
> = () => (
  <State initial={{pageIdx: 0}}>
    {({state, setState}) => {
      const lastElementOnPageIdx = ROWS_PER_PAGE * state.pageIdx - 1;

      return (
        <Query
          query={ALL_HUB_USERS_FOR_ADMIN_QUERY}
          variables={{
            after:
              lastElementOnPageIdx === -1
                ? null
                : offsetToCursor(lastElementOnPageIdx),
            first: ROWS_PER_PAGE,
          }}
        >
          {({loading, error, data}) => (
            <AllHubUsersTable
              pageIdx={state.pageIdx}
              onSetPageIdx={pageIdx => {
                setState({pageIdx});
              }}
              MoreCellComponent={HubUserUpdateDropdownMenuContainer}
              loading={loading}
              error={error}
              data={data}
            />
          )}
        </Query>
      );
    }}
  </State>
);
