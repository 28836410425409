export enum PenaltyCheckSearchBy {
  Iin = 'iin',
  Car = 'car',
}

export interface PenaltyCheckQuery {
  iinNumber?: string;
  carNumber?: string;
  carTechPassport?: string;
}
