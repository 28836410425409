import {ApolloError} from 'apollo-client';
import {State} from 'react-powerplug';
import {gridSize} from '@atlaskit/theme';
import React from 'react';
import ReactJson from 'react-json-view';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import gql from 'graphql-tag';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {omit} from 'shared/utils/object';

const SettingsFormContainer = styled.div`
  margin: ${gridSize() * 3}px 0;
`;

interface SettingValueMap {
  [key: string]: G.SettingsForm_setting_value;
}

interface SettingsFormProps {
  onPatchSetting: (key: string, value: G.SettingsForm_setting_value) => any;
  allSettingsLoading: boolean;
  allSettingsError?: ApolloError;
  allSettingsData?: G.allSettingsForAdmin;
}

export class SettingsForm extends React.Component<SettingsFormProps> {
  public static fragments = {
    setting: gql`
      fragment SettingsForm_setting on Setting {
        id
        key
        value {
          default
          ios
          android
        }
      }
    `,
  };

  public render() {
    if (this.props.allSettingsLoading) return <Spinner size="large" />;
    if (this.props.allSettingsError || !this.props.allSettingsData) {
      return (
        <SectionMessage appearance="error">
          {this.props.allSettingsError
            ? messageForApolloError(this.props.allSettingsError)
            : 'Ничего не найдено'}
        </SectionMessage>
      );
    }

    return (
      <State
        initial={{
          settingValuesMap: (
            this.props.allSettingsData.allSettings || []
          ).reduce<SettingValueMap>(
            (acc, setting) => ({
              ...acc,
              [setting.key]: omit(
                ['__typename'],
                setting.value,
              ) as G.SettingsForm_setting_value,
            }),
            {},
          ),
        }}
      >
        {({state, setState}) => (
          <SettingsFormContainer>
            <ReactJson
              src={state.settingValuesMap}
              name={false}
              enableClipboard={false}
              displayObjectSize={false}
              onEdit={edit => {
                const newSettingValueMap = edit.updated_src as SettingValueMap;
                const updatedSettingKey = edit.namespace[0];
                if (is.nonEmptyString(updatedSettingKey)) {
                  setState({
                    settingValuesMap: newSettingValueMap,
                  });
                  this.props.onPatchSetting(
                    updatedSettingKey,
                    newSettingValueMap[updatedSettingKey],
                  );
                }
              }}
            />
          </SettingsFormContainer>
        )}
      </State>
    );
  }
}
