import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument, QueryDocument} from 'src/utils/graphql';

import {AllCarsTable} from './components/AllCarsTable';

export const ALL_CARS_FOR_PARTNER_QUERY: QueryDocument<
  G.allPartnerCars,
  G.allPartnerCarsVariables
> = gql`
  query allPartnerCars($after: String, $first: Int, $searchText: String) {
    allPartnerCars(after: $after, first: $first, searchText: $searchText) {
      ...AllCarsTable_partnerCars
    }
  }
  ${AllCarsTable.fragments.partnerCars}
`;

export const DELETE_PARTNER_CAR_MUTATION: MutationDocument<
  G.partnerCarDelete,
  G.partnerCarDeleteVariables
> = gql`
  mutation partnerCarDelete($input: PartnerCarDeleteInput!) {
    partnerCarDelete(input: $input) {
      ...AllCarsTable_deletedPartnerCar
    }
  }
  ${AllCarsTable.fragments.deletedPartnerCar}
`;

export const CREATE_PARTNER_CAR_MUTATION: MutationDocument<
  G.partnerCarCreate,
  G.partnerCarCreateVariables
> = gql`
  mutation partnerCarCreate($input: PartnerCarCreateInput!) {
    partnerCarCreate(input: $input) {
      ...AllCarsTable_createdPartnerCar
    }
  }
  ${AllCarsTable.fragments.createdPartnerCar}
`;

export const PARTNER_QUERY: QueryDocument<G.partner> = gql`
  query partner {
    partner {
      ...AllCarsTable_partner
    }
  }
  ${AllCarsTable.fragments.partner}
`;

export const IIN_NUMBER_INFO_QUERY: QueryDocument<
  G.AllCarsTable_iinNumber
> = gql`
  query iinNumberInfo($iinNumber: String!) {
    iinNumberInfo(iinNumber: $iinNumber) {
      ...AllCarsTable_iinNumber
    }
  }
  ${AllCarsTable.fragments.iinNumber}
`;
