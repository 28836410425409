import {ApolloError} from 'apollo-client';
import {ApolloLink, split} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from 'apollo-cache-inmemory';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {onError} from 'apollo-link-error';

import {TypedApolloClient} from 'src/utils/apollo-client';
import introspectionQueryResultData from 'src/__generated__/schema-fragment-types.json';

export const apolloClient = new TypedApolloClient<NormalizedCacheObject>({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      /* eslint-disable no-console */
      if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) => {
          console.log('[GraphQL error]:', {message, locations, path});
        });
      }
      if (networkError) {
        console.log('[Network error]:', networkError);
      }
      /* eslint-enable no-console */
    }),
    split(
      // split based on operation type
      ({query}) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      new WebSocketLink({
        uri: `${process.env.API_LIVE_URL}/hub/graphql`
          .replace('http://', 'ws://')
          .replace('https://', 'wss://'),
        options: {
          reconnect: true,
        },
      }),
      new HttpLink({
        uri: `${process.env.API_LIVE_URL}/hub/graphql`,
        credentials: 'include',
      }),
    ),
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id,
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
  name: 'OKauto Hub',
});

export const messageForApolloError = (err: ApolloError) =>
  err.message.startsWith('GraphQL error: ')
    ? err.message.replace('GraphQL error: ', '')
    : 'Что-то пошло не так. Пожалуйста, попробуйте ещё раз.';
