import {Draggable} from 'react-beautiful-dnd';
import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import styled from 'styled-components';

import {AllFaqsTable_faq} from 'src/__generated__/graphql-types.gen';
import {formatIsoDateTime} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';

import {MoreDropdownMenu} from '../containers/MoreDropdownMenu';

interface AllFaqsDragAndDropTableRowProps {
  faq: AllFaqsTable_faq;
  id: string;
  index: number;
}

const TableRow = styled.tr``;

export const AllFaqsDragAndDropTableRow: React.FunctionComponent<
  AllFaqsDragAndDropTableRowProps
> = props => (
  <Draggable draggableId={props.faq.id} index={props.index}>
    {provided => (
      <TableRow
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        innerRef={provided.innerRef}
      >
        <td>{props.faq.title}</td>
        <td>{props.faq.voicesPositive}</td>
        <td>{props.faq.voicesNegative}</td>
        <td>{formatIsoDateTime(props.faq.createdAt)}</td>
        <td>
          <Lozenge
            appearance={is.nil(props.faq.archivedAt) ? 'success' : 'removed'}
          >
            {is.nil(props.faq.archivedAt) ? 'да' : 'нет'}
          </Lozenge>
        </td>
        <td>
          <MoreDropdownMenu faq={props.faq} />
        </td>
      </TableRow>
    )}
  </Draggable>
);
