import Icon, {IconProps} from '@atlaskit/icon';
import React from 'react';

import {Omit} from 'shared/ts-utils';

type BigCrossIconProps = Omit<IconProps, 'glyph' | 'dangerouslySetGlyph'>;

export const BigCrossIcon: React.FunctionComponent<
  BigCrossIconProps
> = props => (
  <Icon
    dangerouslySetGlyph={`
    <svg width="100px" height="100px" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g fill="currentColor" fill-rule="evenodd">
          <path d="M437.105,74.896c-99.863-99.863-262.35-99.859-362.209,0c-99.859,99.859-99.863,262.346,0,362.209
            s262.351,99.86,362.209,0S536.968,174.759,437.105,74.896z M362.324,338.697c6.526,6.526,6.522,17.105,0,23.627
            c-6.522,6.522-17.101,6.526-23.627,0l-82.696-82.696l-82.696,82.696c-6.522,6.522-17.101,6.526-23.627,0
            c-6.526-6.526-6.522-17.105,0-23.627l82.696-82.696l-82.696-82.696c-6.526-6.526-6.522-17.106,0-23.627s17.101-6.526,23.627,0
            l82.696,82.696l82.696-82.696c6.522-6.522,17.101-6.526,23.627,0c6.526,6.526,6.522,17.106,0,23.627l-82.696,82.696
            L362.324,338.697z"/>
        </g>
      </g>
    </svg>`}
    {...props}
  />
);
