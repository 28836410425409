import React from 'react';

import {Query} from 'src/utils/react-apollo';

import {ALL_DRIVERS_FOR_PARTNER_QUERY} from '../graphql';
import {AllDriversTable} from '../components/AllDriversTable';
import {PartnerDriverDeleteButton} from './PartnerDriverDeleteButton';

interface AllDriversTableContainerProps {}

export const AllDriversTableContainer: React.FunctionComponent<
  AllDriversTableContainerProps
> = () => (
  <Query query={ALL_DRIVERS_FOR_PARTNER_QUERY} fetchPolicy="network-only">
    {({loading, error, data}) => (
      <AllDriversTable
        DeleteButton={PartnerDriverDeleteButton}
        loading={loading}
        error={error}
        data={data}
      />
    )}
  </Query>
);
