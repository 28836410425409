import {ApolloError} from 'apollo-client';
import Button from '@atlaskit/button/dist/es5/components/Button';
import React from 'react';

import {AllDriversTableContainer} from './AllDriversTableContainer';
import {PartnerDriverCreateModalDialog} from './PartnerDriverCreateModalDialog';

interface AllDriversContainerProps {
  location: Window['location'] | undefined;
}
interface AllDriversContainerState {
  location?: Window['location'];
  isCreateModalOpen: boolean;
  partnerDriverCreateError: ApolloError | null;
}

export class AllDriversContainer extends React.Component<
  AllDriversContainerProps,
  AllDriversContainerState
> {
  public state: AllDriversContainerState = {
    isCreateModalOpen: false,
    partnerDriverCreateError: null,
  };

  public render() {
    const openCreateModal = () => this.setState({isCreateModalOpen: true});

    return (
      <>
        <Button appearance="primary" type="button" onClick={openCreateModal}>
          Добавить водителя
        </Button>
        <PartnerDriverCreateModalDialog
          partnerDriverCreateError={this.state.partnerDriverCreateError}
          setPartnerDriverCreateErrorState={partnerDriverCreateError =>
            this.setState({partnerDriverCreateError})
          }
          isCreateModalOpen={this.state.isCreateModalOpen}
          setCreateModalOpenState={isCreateModalOpen =>
            this.setState({isCreateModalOpen})
          }
        />
        <AllDriversTableContainer />
      </>
    );
  }
}
