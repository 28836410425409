import Icon, {IconProps} from '@atlaskit/icon';
import React from 'react';

import {Omit} from 'shared/ts-utils';

type BigCheckIconProps = Omit<IconProps, 'glyph' | 'dangerouslySetGlyph'>;

export const BigCheckIcon: React.FunctionComponent<
  BigCheckIconProps
> = props => (
  <Icon
    dangerouslySetGlyph={`
    <svg width="100px" height="100px" x="0px" y="0px"
      viewBox="0 0 512 512">
        <g>
          <g fill="currentColor" fill-rule="evenodd">
            <path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256
              C512,114.509,397.503,0,256,0z M391.514,186.065L227.509,350.07c-6.662,6.662-17.472,6.664-24.136,0l-82.887-82.887
              c-6.665-6.665-6.665-17.471,0-24.136c6.664-6.665,17.471-6.665,24.136,0l70.82,70.819l151.938-151.938
              c6.664-6.665,17.471-6.665,24.136,0C398.179,168.595,398.179,179.4,391.514,186.065z"/>
          </g>
        </g>
    </svg>`}
    {...props}
  />
);
