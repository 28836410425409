import React from 'react';

import {G} from 'src/types/graphql';
import {is} from 'shared/utils/data-types';

interface PenaltyAltIdsInfoProps {
  penalty: G.PenaltyCheck_penalty;
}

export const PenaltyAltIdsInfo: React.FunctionComponent<
  PenaltyAltIdsInfoProps
> = props => (
  <div>
    {is.nonEmptyString(props.penalty.mvdId) && (
      <>
        <small>ID штрафа в МВД: </small>
        {props.penalty.mvdId}
        <br />
      </>
    )}
    {is.nonEmptyString(props.penalty.shekteuAltId) && (
      <>
        <small>Номер материала: </small>
        {props.penalty.shekteuAltId}
        <br />
      </>
    )}
    {is.nonEmptyString(props.penalty.protocol.blankNumber) && (
      <>
        <small>Предписание: </small>
        {props.penalty.protocol.blankNumber}
        <br />
      </>
    )}
  </div>
);
