import {State} from 'react-powerplug';
import {colors} from '@atlaskit/theme';
import Button from '@atlaskit/button';
import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';

import {G} from 'src/types/graphql';

import {PartnerDriverDeleteModalDialog} from './PartnerDriverDeleteModalDialog';

interface PartnerDriverDeleteButtonContainerProps {
  partnerDriver: G.allPartnerDrivers_allPartnerDrivers_edges_node;
}

export const PartnerDriverDeleteButton: React.FunctionComponent<
  PartnerDriverDeleteButtonContainerProps
> = props => (
  <State initial={{isDeleteModalOpen: false}}>
    {({state, setState}) => {
      const openDeleteModal = () => setState({isDeleteModalOpen: true});

      return (
        <>
          <Button onClick={openDeleteModal} appearance="subtle">
            {<TrashIcon label="Удалить" primaryColor={colors.R300} />}
          </Button>
          <PartnerDriverDeleteModalDialog
            isDeleteModalOpen={state.isDeleteModalOpen}
            setDeleteModalOpenState={isDeleteModalOpen =>
              setState({isDeleteModalOpen})
            }
            partnerDriver={props.partnerDriver}
          />
        </>
      );
    }}
  </State>
);
