import {ApolloError} from 'apollo-client';
import {Formik} from 'formik';
import {gridSize} from '@atlaskit/theme';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import gql from 'graphql-tag';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {SearchBar} from 'src/components/SearchBar';
import {TableContainer} from 'src/components/styled';
import {currencyCentsToUnits} from 'shared/utils/currency';
import {
  formatIsoDate,
  formatIsoDateTime,
  formatCurrencyAmount,
} from 'src/utils/formatting';
import {fromGlobalId} from 'src/utils/graphql-relay';
import {is, Opt} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {PenaltyAltIdsInfo} from './PenaltyAltIdsInfo';
import {PenaltyCheckSearchBy, PenaltyCheckQuery} from '../types';
import {PenaltyViolatorInfo} from './PenaltyViolatorInfo';

const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSize() * 2}px;
`;
const SearchBarWrapper = styled.div`
  display: flex;
`;
const SearchBySelectWrapper = styled.div`
  width: 180px;
  margin-right: ${gridSize()}px;
`;
const SearchButtonWrapper = styled.div`
  margin-left: ${gridSize()}px;

  > button {
    height: 40px;
  }
`;

const PENALTY_SEARCH_BY_TO_LABEL = {
  [PenaltyCheckSearchBy.Iin]: 'По ИИН',
  [PenaltyCheckSearchBy.Car]: 'По авто',
};

interface PenaltyCheckProps {
  checkQuery: PenaltyCheckQuery;
  onSetCheckQuery: (checkQuery: PenaltyCheckQuery) => any;
  loading: boolean;
  error?: ApolloError;
  data?: G.runPenaltyCheck;
}

export class PenaltyCheck extends React.Component<PenaltyCheckProps> {
  public static fragments = {
    penalty: gql`
      fragment PenaltyCheck_penalty on Penalty {
        id
        mvdId
        shekteuAltId
        iinNumber
        carNumber
        carTechPassport
        amountInCents
        paidAt
        violator {
          firstName
          lastName
        }
        violation {
          date
        }
        protocol {
          blankNumber
        }
        beneficiary {
          taxOfficeName
        }
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <>
        <Formik
          initialValues={{
            searchBy:
              is.nonEmptyString(this.props.checkQuery.carNumber) &&
              is.nonEmptyString(this.props.checkQuery.carTechPassport)
                ? PenaltyCheckSearchBy.Car
                : PenaltyCheckSearchBy.Iin,
            searchText: (() => {
              if (
                is.nonEmptyString(this.props.checkQuery.carNumber) &&
                is.nonEmptyString(this.props.checkQuery.carTechPassport)
              ) {
                return `${this.props.checkQuery.carNumber} / ${
                  this.props.checkQuery.carTechPassport
                }`;
              }

              return Opt.nonEmptyString(
                this.props.checkQuery.iinNumber,
              ).getOrElse('');
            })(),
          }}
          onSubmit={values => {
            if (values.searchBy === PenaltyCheckSearchBy.Iin) {
              this.props.onSetCheckQuery({
                iinNumber: values.searchText.trim(),
              });
            }
            if (values.searchBy === PenaltyCheckSearchBy.Car) {
              const [
                untrimmedCarNumber,
                untrimmedCarTechPassport,
              ] = values.searchText.split('/');

              this.props.onSetCheckQuery({
                carNumber: untrimmedCarNumber.trim(),
                carTechPassport: untrimmedCarTechPassport.trim(),
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form target="_self" onSubmit={handleSubmit} onReset={handleReset}>
              <SearchFormContainer>
                <SearchBarWrapper>
                  <SearchBySelectWrapper>
                    <Select
                      name="searchBy"
                      value={{
                        label: PENALTY_SEARCH_BY_TO_LABEL[values.searchBy],
                        value: values.searchBy,
                      }}
                      onChange={({
                        value,
                      }: {
                        label: string;
                        value: PenaltyCheckSearchBy;
                      }) => {
                        setFieldValue('searchBy', value);
                      }}
                      options={[
                        PenaltyCheckSearchBy.Iin,
                        PenaltyCheckSearchBy.Car,
                      ].map(searchBy => ({
                        label: PENALTY_SEARCH_BY_TO_LABEL[searchBy],
                        value: searchBy,
                      }))}
                      shouldFitContainer
                    />
                  </SearchBySelectWrapper>
                  <SearchBar
                    name="searchText"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    pattern={
                      values.searchBy === PenaltyCheckSearchBy.Car
                        ? '\\w+ ?/ ?\\w+'
                        : undefined
                    }
                    placeholder={
                      values.searchBy === PenaltyCheckSearchBy.Car
                        ? 'Госномер / техпаспорт'
                        : undefined
                    }
                    value={values.searchText}
                    required
                    shouldFitContainer
                  />
                  <SearchButtonWrapper>
                    <Button
                      appearance="default"
                      type="submit"
                      isDisabled={this.props.loading}
                      isLoading={this.props.loading}
                    >
                      Проверить
                    </Button>
                  </SearchButtonWrapper>
                </SearchBarWrapper>
              </SearchFormContainer>
            </form>
          )}
        </Formik>
        <TableContainer>
          {(is.nonEmptyString(this.props.checkQuery.iinNumber) ||
            (is.nonEmptyString(this.props.checkQuery.carNumber) &&
              is.nonEmptyString(this.props.checkQuery.carTechPassport))) && (
            <DynamicTable
              caption={`Штрафы для ${
                is.nonEmptyString(this.props.checkQuery.carNumber) &&
                is.nonEmptyString(this.props.checkQuery.carTechPassport)
                  ? `авто с госномером:${
                      this.props.checkQuery.carNumber
                    }, техпаспортом:${this.props.checkQuery.carTechPassport}`
                  : `ИИН:${this.props.checkQuery.iinNumber}`
              }`}
              head={{
                cells: [
                  {
                    key: 'id',
                    content: 'ID',
                  },
                  {
                    key: 'altIds',
                    content: 'Другие ID',
                  },
                  {
                    key: 'violator',
                    content: 'Нарушитель',
                  },
                  {
                    key: 'violationDate',
                    content: 'Дата нарушения',
                  },
                  {
                    key: 'beneficiaryTaxOfficeName',
                    content: 'Налоговый орган',
                  },
                  {
                    key: 'amount',
                    content: 'Сумма без комиссии',
                  },
                  {
                    key: 'paidAt',
                    content: 'Оплачено',
                  },
                ],
              }}
              rows={
                this.props.data && this.props.data.runPenaltyCheck
                  ? this.props.data.runPenaltyCheck.penalties.map(penalty => ({
                      key: penalty.id,
                      cells: [
                        {
                          key: penalty.id,
                          content: fromGlobalId(penalty.id).id,
                        },
                        {
                          content: <PenaltyAltIdsInfo penalty={penalty} />,
                        },
                        {
                          content: <PenaltyViolatorInfo penalty={penalty} />,
                        },
                        {
                          key: penalty.violation.date,
                          content: Opt.nonEmptyString(penalty.violation.date)
                            .map(formatIsoDate)
                            .toNullable(),
                        },
                        {
                          key: penalty.beneficiary.taxOfficeName,
                          content: penalty.beneficiary.taxOfficeName,
                        },
                        {
                          key: penalty.amountInCents,
                          content: Opt.positiveInt(penalty.amountInCents)
                            .map(amountInCents =>
                              formatCurrencyAmount(
                                currencyCentsToUnits(amountInCents),
                              ),
                            )
                            .toNullable(),
                        },
                        {
                          key: penalty.paidAt,
                          content: is.nonEmptyString(penalty.paidAt) ? (
                            formatIsoDateTime(penalty.paidAt)
                          ) : (
                            <Lozenge appearance="removed">Нет</Lozenge>
                          ),
                        },
                      ],
                    }))
                  : []
              }
              emptyView={(() => {
                const errorMessage = this.props.error
                  ? messageForApolloError(this.props.error)
                  : this.props.data &&
                    this.props.data.runPenaltyCheck &&
                    this.props.data.runPenaltyCheck.errorMessage;

                return (
                  <SectionMessage
                    appearance={
                      is.nonEmptyString(errorMessage) ? 'error' : 'warning'
                    }
                  >
                    {Opt.nonEmptyString(errorMessage).getOrElse(
                      'Ничего не найдено',
                    )}
                  </SectionMessage>
                );
              })()}
              loadingSpinnerSize="large"
              isLoading={this.props.loading}
            />
          )}
        </TableContainer>
      </>
    );
  }
}
