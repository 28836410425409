import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument} from 'src/utils/graphql';

export const HUB_USER_PASSWORD_CHANGE_MUTATION: MutationDocument<
  G.hubUserPasswordChange,
  G.hubUserPasswordChangeVariables
> = gql`
  mutation hubUserPasswordChange($input: HubUserPasswordChangeInput!) {
    hubUserPasswordChange(input: $input) {
      hubUser {
        id
        hubUsername
        role
      }
    }
  }
`;
