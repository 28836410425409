import {State} from 'react-powerplug';
import React from 'react';

import {Query} from 'src/utils/react-apollo';
import {offsetToCursor} from 'src/utils/graphql-relay';

import {ALL_CARS_FOR_PARTNER_QUERY} from '../graphql';
import {AllCarsTable} from '../components/AllCarsTable';
import {PartnerCarDeleteButton} from './PartnerCarDeleteButton';

interface AllCarsTableContainerProps {
  debouncedSearchText: string;
  rowsPerPage: number;
  onSetRowsPerPage: (rowsPerPage: number) => any;
}

export const AllCarsTableContainer: React.FunctionComponent<
  AllCarsTableContainerProps
> = props => (
  <State initial={{pageIdx: 0, prevDebouncedSearchText: ''}}>
    {({state, setState}) => {
      const lastElementOnPageIdx = props.rowsPerPage * state.pageIdx - 1;

      return (
        <Query
          query={ALL_CARS_FOR_PARTNER_QUERY}
          // fetchPolicy="network-only"
          variables={{
            after:
              lastElementOnPageIdx === -1
                ? null
                : offsetToCursor(lastElementOnPageIdx),
            first: props.rowsPerPage === 0 ? null : props.rowsPerPage,
            // HACK: use fixed value to avoid rerendering with empty search
            // text
            searchText: props.debouncedSearchText,
          }}
          onCompleted={() => {
            if (state.prevDebouncedSearchText !== props.debouncedSearchText) {
              setState({pageIdx: 0});
            }
            window.scrollTo(0, 0);
            setState({prevDebouncedSearchText: props.debouncedSearchText});
          }}
        >
          {({loading, error, data}) => (
            <AllCarsTable
              pageIdx={state.pageIdx}
              onSetPageIdx={pageIdx => {
                setState({pageIdx});
              }}
              searchText={props.debouncedSearchText}
              DeleteButton={PartnerCarDeleteButton}
              loading={loading}
              error={error}
              data={data}
              onSetRowsPerPage={rowsPerPage => {
                setState({pageIdx: 0});
                props.onSetRowsPerPage(rowsPerPage);
              }}
              rowsPerPage={props.rowsPerPage}
            />
          )}
        </Query>
      );
    }}
  </State>
);
