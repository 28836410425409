import {ErrorPolicy, FetchPolicy} from 'apollo-client';
// eslint-disable-next-line no-restricted-imports
import {
  ApolloProvider,
  ApolloConsumer as WeaklyTypedApolloConsumer,
  QueryResult,
  MutationResult,
  QueryProps,
  MutationProps,
  MutationOptions,
  SubscriptionProps,
  FetchResult,
  Query as WeaklyTypedQuery,
  Mutation as WeaklyTypedMutation,
  Subscription as WeaklyTypedSubscription,
} from 'react-apollo';
import React from 'react';

import {
  MutationDocument,
  QueryDocument,
  SubscriptionDocument,
} from 'src/utils/graphql';
import {Omit} from 'shared/ts-utils';
import {
  TypedMutationUpdaterFn,
  TypedApolloClient,
} from 'src/utils/apollo-client';

export {QueryResult, MutationResult};

export interface TypedMutationOptions<TData, TVariables>
  extends MutationOptions<TData, TVariables> {
  errorPolicy?: ErrorPolicy;
  fetchPolicy?: Extract<FetchPolicy, 'no-cache'>;
}

export type TypedMutationFn<TData, TVariables> = (
  options?: TypedMutationOptions<TData, TVariables>,
) => Promise<void | FetchResult<TData>>;

export interface TypedApolloConsumerProps {
  children: (client: TypedApolloClient<any>) => React.ReactElement | null;
}
export interface TypedQueryProps<TData, TVariables>
  extends Omit<QueryProps<TData, TVariables>, 'query'> {
  query: QueryDocument<TData, TVariables>;
}
export interface TypedMutationProps<TData, TVariables>
  extends Omit<
    MutationProps<TData, TVariables>,
    'mutation' | 'update' | 'children'
  > {
  mutation: MutationDocument<TData, TVariables>;
  update?: TypedMutationUpdaterFn<TData>;
  children: (
    mutateFn: TypedMutationFn<TData, TVariables>,
    result: MutationResult<TData>,
  ) => React.ReactNode;
}
export interface TypedSubscriptionProps<TData, TVariables>
  extends Omit<SubscriptionProps<TData, TVariables>, 'subscription'> {
  subscription: SubscriptionDocument<TData, TVariables>;
}

/* eslint-disable react/no-multi-comp */
export class TypedQueryComponent<TData, TVariables> extends React.Component<
  TypedQueryProps<TData, TVariables>
> {}
export class TypedMutationComponent<TData, TVariables> extends React.Component<
  TypedMutationProps<TData, TVariables>
> {}
export class TypedSubscriptionComponent<
  TData,
  TVariables
> extends React.Component<TypedSubscriptionProps<TData, TVariables>> {}
/* eslint-enable react/no-multi-comp */

export {ApolloProvider};
export const ApolloConsumer = (WeaklyTypedApolloConsumer as any) as React.SFC<
  TypedApolloConsumerProps
>;
export const Query = (WeaklyTypedQuery as any) as typeof TypedQueryComponent;
export const Mutation = (WeaklyTypedMutation as any) as typeof TypedMutationComponent;
export const Subscription = (WeaklyTypedSubscription as any) as typeof TypedSubscriptionComponent;
