import AppAccessIcon from '@atlaskit/icon/glyph/app-access';
import CreditcardFilledIcon from '@atlaskit/icon/glyph/creditcard-filled';
import IssuesIcon from '@atlaskit/icon/glyph/issues';
import MarketplaceIconIcon from '@atlaskit/icon/glyph/marketplace';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import React from 'react';
import SettingsIcon from '@atlaskit/icon/glyph/settings';

import {AppNavigation} from 'src/containers/AppNavigation';
import {G} from 'src/types/graphql';
import {UserRole} from 'src/types/auth';
import {compact} from 'shared/utils/array';

interface AdminNavigationProps {
  viewer: G.AppNavigation_viewer;
}

export const AdminNavigation: React.FunctionComponent<
  AdminNavigationProps
> = props => (
  <AppNavigation
    viewer={props.viewer}
    containerTitle="OKauto – Админ"
    containerLinks={compact<[string, string, React.ComponentType<any>]>([
      ['/admin/payments/penalties', 'Платежи за штрафы', CreditcardFilledIcon],
      ['/admin/penalty-check', 'Проверка штрафов', IssuesIcon],
      ['/admin/faqs', 'FAQ', QuestionCircleIcon],
      props.viewer.role === UserRole.Superadmin && [
        '/admin/partners',
        'Партнеры',
        PeopleGroupIcon,
      ],
      props.viewer.role === UserRole.Superadmin && [
        '/admin/hub-users',
        'Админы',
        AppAccessIcon,
      ],
      props.viewer.role === UserRole.Superadmin && [
        '/admin/settings',
        'Настройки',
        SettingsIcon,
      ],
      props.viewer.role === UserRole.Superadmin && [
        '/admin/push',
        'Уведомление',
        NotificationIcon,
      ],
      props.viewer.role === UserRole.Superadmin && [
        '/admin/payment-do',
        'Оплата штрафа',
        MarketplaceIconIcon,
      ],
    ])}
    createLinks={compact<[string, string, string, React.ComponentType<any>]>([
      props.viewer.role === UserRole.Superadmin && [
        '/admin/hub-users/create',
        'Новый админ',
        'Админ',
        AppAccessIcon,
      ],
      ['/admin/faqs/create', 'Новый FAQ', 'Админ', AppAccessIcon],
    ])}
    initiallyClosed
  />
);
