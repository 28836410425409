import gql from 'graphql-tag';

import {AppNavigation} from 'src/containers/AppNavigation';
import {G} from 'src/types/graphql';
import {QueryDocument} from 'src/utils/graphql';

export const VIEWER_QUERY: QueryDocument<G.viewer> = gql`
  query viewer {
    viewer {
      ...AppNavigation_viewer
    }
  }
  ${AppNavigation.fragments.viewer}
`;
