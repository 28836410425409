import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {AllInsuranceApplicationsTable} from './components/AllInsuranceApplicationsTable';

export const ALL_INSURANCE_APPLICATIONS_FOR_PARTNER_QUERY: QueryDocument<
  G.allInsuranceApplicationsForPartner,
  G.allInsuranceApplicationsForPartnerVariables
> = gql`
  query allInsuranceApplicationsForPartner($after: String, $first: Int) {
    allInsuranceApplications(after: $after, first: $first) {
      edges {
        node {
          ...AllInsuranceApplicationsTable_insuranceApplication
        }
      }
      totalPageCount
    }
  }
  ${AllInsuranceApplicationsTable.fragments.insuranceApplication}
`;

export const INSURANCE_APPLICATION_UPDATE_MUTATION: MutationDocument<
  G.insuranceApplicationUpdate,
  G.insuranceApplicationUpdateVariables
> = gql`
  mutation insuranceApplicationUpdate(
    $input: InsuranceApplicationUpdateInput!
  ) {
    insuranceApplicationUpdate(input: $input) {
      insuranceApplication {
        ...AllInsuranceApplicationsTable_insuranceApplication
      }
    }
  }
  ${AllInsuranceApplicationsTable.fragments.insuranceApplication}
`;
