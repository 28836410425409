import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';

import {AdminNavigation} from '../navigation';
import {SettingsFormContainer} from './containers/SettingsFormContainer';

interface SettingsPageParams {}
interface SettingsPageProps extends RouteComponentProps<SettingsPageParams> {}

export const SettingsPage: React.FunctionComponent<
  SettingsPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={AdminNavigation}
    roles={[UserRole.Superadmin]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader>Настройки</PageHeader>
        <SettingsFormContainer />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
