import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button, {ButtonGroup} from '@atlaskit/button';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UnderlinelessLink} from 'src/components/styled';
import {UserRole} from 'src/types/auth';

import {AdminNavigation} from '../navigation';
import {AllFaqsContainer} from './containers/AllFaqsContainer';

interface AllFaqsPageParams {}
interface AllFaqsPageProps extends RouteComponentProps<AllFaqsPageParams> {}

export const AllFaqsPage: React.FunctionComponent<AllFaqsPageProps> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={AdminNavigation}
    roles={[UserRole.Superadmin, UserRole.SupportOperator]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader
          actions={
            <ButtonGroup>
              <UnderlinelessLink to="/admin/faqs/create">
                <Button
                  appearance="primary"
                  iconBefore={<AddIcon label="Создать" />}
                >
                  Новый FAQ
                </Button>
              </UnderlinelessLink>
            </ButtonGroup>
          }
        >
          FAQ
        </PageHeader>
        <AllFaqsContainer location={props.location} />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
