import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {HubUserCreateForm} from './components/HubUserCreateForm';

export const ALL_PARTNERS_QUERY: QueryDocument<G.allPartners> = gql`
  query allPartners {
    allPartners {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const HUB_USER_CREATE_MUTATION: MutationDocument<
  G.hubUserCreate,
  G.hubUserCreateVariables
> = gql`
  mutation hubUserCreate($input: HubUserCreateInput!) {
    hubUserCreate(input: $input) {
      hubUser {
        ...HubUserCreateForm_user
      }
    }
  }
  ${HubUserCreateForm.fragments.user}
`;
