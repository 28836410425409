import {Checkbox} from '@atlaskit/checkbox';
import {gridSize} from '@atlaskit/theme';
import Button from '@atlaskit/button';
import React from 'react';
import Select from '@atlaskit/select';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {SearchBar} from 'src/components/SearchBar';
import {TypedMutationFn} from 'src/utils/react-apollo';

const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSize() * 2}px;
`;
const SearchBarWrapper = styled.div`
  display: flex;
`;
const SearchBySelectWrapper = styled.div`
  width: 180px;
  margin-right: ${gridSize()}px;
`;
const FiltersContainer = styled.div`
  display: flex;
  margin-top: ${gridSize() * 2}px;
`;
const RightFiltersContainer = styled.div`
  margin-left: auto;
`;

const PAYMENT_SEARCH_BY_TO_LABEL = {
  [G.PenaltyPaymentSearchBy.IIN_NUMBER]: 'По ИИН',
  [G.PenaltyPaymentSearchBy.CAR_NUMBER]: 'По госномеру авто',
  [G.PenaltyPaymentSearchBy.ID]: 'По ID платежа',
  [G.PenaltyPaymentSearchBy.REVERSE_ID]: 'По ID противоположного платежа',
};

interface AllPenaltyPaymentsSearchFormProps {
  searchBy: G.PenaltyPaymentSearchBy;
  onSetSearchBy: (searchBy: G.PenaltyPaymentSearchBy) => any;
  searchText: string;
  onSetSearchText: (searchText: string) => any;
  filters: G.PenaltyPaymentFilters;
  onSetFilters: (filters: {
    forRegressingPenaltiesOnly?: boolean | null;
    forStuckPaymentsOnly?: boolean | null;
  }) => any;
  disabledFilterKeys: (keyof G.PenaltyPaymentFilters)[];
  restartRepaymentButton: boolean;
  selectedPenaltyIds: string[];
  onClickResendPayments: TypedMutationFn<
    G.restartPenaltyPaymentsForAdmin,
    G.restartPenaltyPaymentsForAdminVariables
  >;
}

export const AllPenaltyPaymentsSearchForm: React.FunctionComponent<
  AllPenaltyPaymentsSearchFormProps
> = props => (
  <SearchFormContainer>
    <SearchBarWrapper>
      <SearchBySelectWrapper>
        <Select
          name="searchBy"
          value={{
            label: PAYMENT_SEARCH_BY_TO_LABEL[props.searchBy],
            value: props.searchBy,
          }}
          onChange={({
            value,
          }: {
            label: string;
            value: G.PenaltyPaymentSearchBy;
          }) => {
            props.onSetSearchBy(value);
            props.onSetSearchText('');
          }}
          options={[
            G.PenaltyPaymentSearchBy.IIN_NUMBER,
            G.PenaltyPaymentSearchBy.CAR_NUMBER,
            G.PenaltyPaymentSearchBy.ID,
            G.PenaltyPaymentSearchBy.REVERSE_ID,
          ].map(searchBy => ({
            label: PAYMENT_SEARCH_BY_TO_LABEL[searchBy],
            value: searchBy,
          }))}
          shouldFitContainer
        />
      </SearchBySelectWrapper>
      <SearchBar
        value={props.searchText}
        onChange={event => {
          const untrimmedSearchText =
            props.searchBy === G.PenaltyPaymentSearchBy.CAR_NUMBER
              ? event.target.value.toUpperCase()
              : event.target.value;

          props.onSetSearchText(untrimmedSearchText.trim());
        }}
        shouldFitContainer
      />
    </SearchBarWrapper>
    <FiltersContainer>
      {!props.disabledFilterKeys.includes('forRegressingPenaltiesOnly') && (
        <Checkbox
          name="forRegressingPenaltiesOnly"
          value="forRegressingPenaltiesOnly"
          label="Только платежи для непогашенных штрафов"
          isChecked={props.filters.forRegressingPenaltiesOnly}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetFilters({
              ...props.filters,
              forRegressingPenaltiesOnly: event.target.checked,
            });
          }}
        />
      )}
      {!props.disabledFilterKeys.includes('forStuckPaymentsOnly') && (
        <Checkbox
          name="forStuckPaymentsOnly"
          value="forStuckPaymentsOnly"
          label="Только неуспешные платежи"
          isChecked={props.filters.forStuckPaymentsOnly}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetFilters({
              ...props.filters,
              forStuckPaymentsOnly: event.target.checked,
            });
          }}
        />
      )}
      {props.restartRepaymentButton && props.selectedPenaltyIds.length > 0 && (
        <RightFiltersContainer>
          <Button
            appearance="primary"
            type="button"
            onClick={() =>
              props.onClickResendPayments({
                variables: {input: {paymentIds: props.selectedPenaltyIds}},
              })
            }
          >
            Переотправить платеж
            {props.selectedPenaltyIds.length > 1 ? 'и' : ''}
          </Button>
        </RightFiltersContainer>
      )}
    </FiltersContainer>
  </SearchFormContainer>
);
