import gql from 'graphql-tag';

import {AppNavigation} from 'src/containers/AppNavigation';
import {G} from 'src/types/graphql';
import {MutationDocument} from 'src/utils/graphql';

export const LOG_IN_MUTATION: MutationDocument<G.logIn, G.logInVariables> = gql`
  mutation logIn($input: LogInInput!) {
    logIn(input: $input) {
      hubUser {
        ...AppNavigation_viewer
      }
    }
  }
  ${AppNavigation.fragments.viewer}
`;
