import PersonIcon from '@atlaskit/icon/glyph/person';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import React from 'react';
import TrayIcon from '@atlaskit/icon/glyph/tray';

import {AppNavigation} from 'src/containers/AppNavigation';
import {G} from 'src/types/graphql';

interface PartnerCarparkNavigationProps {
  viewer: G.AppNavigation_viewer;
}

export const PartnerCarparkNavigation: React.FunctionComponent<
  PartnerCarparkNavigationProps
> = props => (
  <AppNavigation
    viewer={props.viewer}
    containerTitle="OKauto – Личный кабинет таксопарка"
    containerLinks={[
      ['/partner/carpark', 'Мои машины', TrayIcon],
      ['/partner/drivers', 'Мои водители', PersonIcon],
      ['//okauto.kz/faq/', 'FAQ', QuestionCircleIcon, 'HubToFaq'],
    ]}
    initiallyClosed
  />
);
