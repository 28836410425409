// copied from https://github.com/jonschlinkert/dashify
export const kebabCase = (str: string, options?: {condense?: boolean}) =>
  str
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\W/g, m => (/[À-ž]/.test(m) ? m : '-'))
    .replace(/^-+|-+$/g, '')
    .replace(/-{2,}/g, m => (options && options.condense ? '-' : m))
    .toLowerCase();

export const capitalize = (str: string) =>
  str === '' ? str : `${str[0].toUpperCase()}${str.substring(1).toLowerCase()}`;

export const escapeStringForRegExp = (str: string) =>
  str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
