import React from 'react';

import {Query} from 'src/utils/react-apollo';

import {ALL_PARTNERS_FOR_ADMIN_QUERY} from '../graphql';
import {AllPartnersTable} from '../components/AllPartnersTable';
import {PartnerDropdownMenu} from './PartnerDropdownMenu';

interface AllPartnersTableContainerProps {}

export const AllPartnersTableContainer: React.FunctionComponent<
  AllPartnersTableContainerProps
> = () => (
  <Query query={ALL_PARTNERS_FOR_ADMIN_QUERY} fetchPolicy="cache-and-network">
    {({loading, error, data}) => (
      <AllPartnersTable
        loading={loading}
        error={error}
        data={data && data.allPartners}
        MoreCellComponent={PartnerDropdownMenu}
      />
    )}
  </Query>
);
