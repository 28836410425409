import {State} from 'react-powerplug';
import {navigate} from '@reach/router';
import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import Highlighter from 'react-highlight-words';
import InlineDialog from '@atlaskit/inline-dialog';
import MoreIcon from '@atlaskit/icon/glyph/more';
import OpenIcon from '@atlaskit/icon/glyph/open';
import React from 'react';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {compact} from 'shared/utils/array';
import {is, Opt} from 'shared/utils/data-types';

const InlineDialogWrapper = styled.div`
  /* HACK: override padding for InlineDialog's content to match that of DropdownMenu */
  & > div {
    padding: 12px;
  }
`;

interface PaymentPenaltyInfoProps {
  paymentPenalty: G.AllPenaltyPaymentsTable_payment_items_penalty;
  searchBy: G.PenaltyPaymentSearchBy;
  searchText: string;
}

export const PaymentPenaltyInfo: React.FunctionComponent<
  PaymentPenaltyInfoProps
> = props => (
  <div>
    {is.nonEmptyString(props.paymentPenalty.iinNumber) && (
      <>
        <small>ИИН нарушителя: </small>
        <DropdownMenu
          trigger={props.paymentPenalty.iinNumber}
          triggerType="button"
          triggerButtonProps={{
            iconAfter: <OpenIcon label="Действия" size="small" />,
            appearance: 'subtle-link',
            spacing: 'none',
            // HACK: disable the selected style
            isSelected: false,
          }}
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => {
                navigate(
                  `/admin/penalty-check?iinNumber=${
                    props.paymentPenalty.iinNumber
                  }`,
                );
              }}
            >
              Проверить штрафы
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </>
    )}
    {is.nonEmptyString(props.paymentPenalty.carNumber) && (
      <>
        <small>Госномер и техпаспорт нарушителя: </small>
        <DropdownMenu
          trigger={
            props.searchBy === G.PenaltyPaymentSearchBy.CAR_NUMBER ? (
              <span>
                <Highlighter
                  searchWords={[props.searchText]}
                  textToHighlight={props.paymentPenalty.carNumber}
                />
                {' / '}
                {props.paymentPenalty.carTechPassport}
              </span>
            ) : (
              `${props.paymentPenalty.carNumber} / ${
                props.paymentPenalty.carTechPassport
              }`
            )
          }
          triggerType="button"
          triggerButtonProps={{
            iconAfter: <OpenIcon label="Действия" size="small" />,
            appearance: 'subtle-link',
            spacing: 'none',
            // HACK: disable the selected style
            isSelected: false,
          }}
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => {
                navigate(
                  `/admin/penalty-check?carNumber=${
                    props.paymentPenalty.carNumber
                  }&carTechPassport=${props.paymentPenalty.carTechPassport}`,
                );
              }}
            >
              Проверить штрафы
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </>
    )}
    <InlineDialogWrapper>
      <State initial={{dialogOpen: false}}>
        {({state, setState}) => (
          <InlineDialog
            content={
              <span>
                <small>Имя нарушителя: </small>
                {(() => {
                  const violatorName = compact([
                    props.paymentPenalty.violator.firstName,
                    props.paymentPenalty.violator.lastName,
                  ]).join(' ');

                  return Opt.nonEmptyString(violatorName).getOrElse('-');
                })()}
                <br />
                <small>Предписание: </small>
                {Opt.nonEmptyString(
                  props.paymentPenalty.protocol.blankNumber,
                ).getOrElse('-')}
                <br />
                <small>Номер материала: </small>
                {Opt.nonEmptyString(
                  props.paymentPenalty.shekteuAltId,
                ).getOrElse('-')}
                <br />
                <small>Дата нарушения: </small>
                {Opt.nonEmptyString(
                  props.paymentPenalty.violation.date,
                ).getOrElse('-')}
              </span>
            }
            isOpen={state.dialogOpen}
          >
            <Button
              iconAfter={<MoreIcon label="Подробнее" />}
              appearance="subtle"
              spacing="none"
              isSelected={state.dialogOpen}
              onClick={() => {
                setState(prevState => ({
                  dialogOpen: !prevState.dialogOpen,
                }));
              }}
            />
          </InlineDialog>
        )}
      </State>
    </InlineDialogWrapper>
  </div>
);
