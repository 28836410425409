import {ValidatorMessage} from '@atlaskit/form';
import {colors, typography} from '@atlaskit/theme';
import React from 'react';
import styled from 'styled-components';

import {is} from 'shared/utils/data-types';

const FieldWrapper = styled.div`
  padding-top: 8px;
`;
const Label = styled.label`
  ${typography.h200()} display: inline-block;
  margin-bottom: 4px;
  margin-top: 0;
`;
const RequiredIndicator = styled.span`
  color: ${colors.red};
  padding-left: 2px;
`;
const HelperText = styled.span`
  ${typography.h200()} font-weight: normal;
  margin-top: 8px;
`;

interface FormFieldProps {
  label?: string;
  inputId?: string;
  inputRequired?: boolean;
  helperText?: string;
  invalid?: boolean | void;
  validMessage?: string;
  invalidMessage?: string | null;
  children?: React.ReactNode;
}

export const FormField: React.FunctionComponent<FormFieldProps> = props => (
  <FieldWrapper>
    {is.nonEmptyString(props.label) && (
      <Label htmlFor={props.inputId}>
        {props.label}
        {props.inputRequired ? (
          <RequiredIndicator role="presentation">*</RequiredIndicator>
        ) : null}
      </Label>
    )}
    {props.children}
    {is.nonEmptyString(props.helperText) ? (
      <HelperText>{props.helperText}</HelperText>
    ) : null}
    <ValidatorMessage
      isInvalid={props.invalid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
    />
  </FieldWrapper>
);
