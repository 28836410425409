import MediaQuery from 'react-responsive';
import Page from '@atlaskit/page';
import React from 'react';
import styled from 'styled-components';

const AppNavigationPageWrapper = styled.div<{onDesktop: boolean}>`
  /* Sidebar */
  & > div:first-child > div:first-child > div:first-child {
    position: ${props => (props.onDesktop ? 'relative' : 'fixed')};
  }

  /* Content */
  & > div:first-child > div:first-child > div:nth-child(2) {
    padding-top: ${props => (props.onDesktop ? 0 : '50px')};
  }
`;

interface AppPageProps {
  children: React.ReactNode;
  navigation?: React.ReactElement;
  navigationAdaptive?: boolean;
}

export const AppPage: React.FunctionComponent<AppPageProps> = props =>
  props.navigationAdaptive ? (
    <MediaQuery query="(min-width: 1024px)">
      {onDesktop => (
        <AppNavigationPageWrapper onDesktop={onDesktop}>
          <Page {...props} />
        </AppNavigationPageWrapper>
      )}
    </MediaQuery>
  ) : (
    <Page {...props} />
  );
