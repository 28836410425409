import {ApolloError} from 'apollo-client';
import React from 'react';

import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';

import {FAQ_CREATE_OR_UPDATE_MUTATION} from '../graphql';
import {FaqCreateOrUpdateForm} from '../components/FaqCreateOrUpdateForm';

interface FaqCreateOrUpdateFormContainerProps {
  onFaqCreatedOrUpdated: () => void;
  onCancel: () => void;
  loading?: boolean;
  error?: ApolloError;
  data?: G.faqForUpdate;
}

export const FaqCreateOrUpdateFormContainer: React.FunctionComponent<
  FaqCreateOrUpdateFormContainerProps
> = props => (
  <Mutation
    mutation={FAQ_CREATE_OR_UPDATE_MUTATION}
    refetchQueries={['allFaqsForAdmin']}
    onCompleted={props.onFaqCreatedOrUpdated}
    // provide a noop here, otherwise an unhandled error is thrown
    onError={() => {}}
  >
    {(faqCreateOrUpdate, {loading, error}) => (
      <FaqCreateOrUpdateForm
        onFaqCreateOrUpdate={data =>
          faqCreateOrUpdate({
            variables: {
              input: {
                id:
                  props.data && props.data.faq ? props.data.faq.id : undefined,
                title: data.title,
                markdownContent: data.markdownContent,
                category: data.category,
              },
            },
          })
        }
        onCancel={props.onCancel}
        loading={loading}
        error={error}
        faq={props.data && props.data.faq ? props.data.faq : undefined}
      />
    )}
  </Mutation>
);
