import {ApolloError} from 'apollo-client';
import {gridSize} from '@atlaskit/theme';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button';
import React from 'react';
import debounce from 'lodash.debounce';
import qs from 'query-string';
import styled from 'styled-components';

import {Opt} from 'shared/utils/data-types';
import {updateCurrentLocationQuery} from 'src/utils/location';

import {AllCarsSearchForm} from '../components/AllCarsSearchForm';
import {AllCarsTableContainer} from './AllCarsTableContainer';
import {PartnerCarCreateModalDialog} from './PartnerCarCreateModalDialog';

interface AllCarsContainerProps {
  location: Window['location'] | undefined;
}
interface AllCarsContainerState {
  location?: Window['location'];
  isCreateModalOpen: boolean;
  partnerCarCreateError: ApolloError | null;
  searchText: string;
  debouncedSearchText: string;
  rowsPerPage: number;
}

const SEARCH_DEBOUNCE_DELAY = 1000;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${gridSize() * 2}px;
`;

export class AllCarsContainer extends React.Component<
  AllCarsContainerProps,
  AllCarsContainerState
> {
  public state: AllCarsContainerState = {
    isCreateModalOpen: false,
    partnerCarCreateError: null,
    searchText: '',
    debouncedSearchText: '',
    rowsPerPage: 50,
  };

  public static getDerivedStateFromProps(
    props: AllCarsContainerProps,
    state: AllCarsContainerState,
  ) {
    if (props.location && props.location !== state.location) {
      const locationQuery: {
        searchText?: string;
        forRegressingPenaltiesOnly?: string;
      } & {[key: string]: unknown} = qs.parse(props.location.search);

      const searchText = Opt.nonEmptyString(locationQuery.searchText).getOrElse(
        '',
      );

      return {
        location: props.location,
        searchText,
        debouncedSearchText: searchText,
      };
    }

    return null;
  }

  public componentDidUpdate(
    _prevProps: AllCarsContainerProps,
    prevState: AllCarsContainerState,
  ) {
    if (this.state.debouncedSearchText !== prevState.debouncedSearchText) {
      updateCurrentLocationQuery({
        searchText: Opt.nonEmptyString(
          this.state.debouncedSearchText,
        ).toUndefined(),
      });
    }
    if (this.state.searchText !== prevState.searchText) {
      this.handleSetDebouncedSearchText(this.state.searchText);
    }
  }

  private handleSetDebouncedSearchText = debounce(searchText => {
    this.setState({debouncedSearchText: searchText});
  }, SEARCH_DEBOUNCE_DELAY);

  public render() {
    const openCreateModal = () => this.setState({isCreateModalOpen: true});

    return (
      <>
        <PageHeader>
          <AllCarsSearchForm
            searchText={this.state.searchText}
            onSetSearchText={searchText => {
              this.setState({searchText});
            }}
          />
          <Button
            appearance="primary"
            iconBefore={<AddIcon label="Создать" />}
            onClick={openCreateModal}
          >
            Добавить машину
          </Button>
        </PageHeader>
        <PartnerCarCreateModalDialog
          partnerCarCreateError={this.state.partnerCarCreateError}
          setPartnerCarCreateErrorState={partnerCarCreateError =>
            this.setState({partnerCarCreateError})
          }
          isCreateModalOpen={this.state.isCreateModalOpen}
          setCreateModalOpenState={isCreateModalOpen =>
            this.setState({isCreateModalOpen})
          }
        />
        <AllCarsTableContainer
          debouncedSearchText={this.state.debouncedSearchText}
          onSetRowsPerPage={rowsPerPage => {
            this.setState({rowsPerPage});
          }}
          rowsPerPage={this.state.rowsPerPage}
        />
      </>
    );
  }
}
