import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import Button from '@atlaskit/button/dist/es5/components/Button';
import Form, {FormFooter, FormSection} from '@atlaskit/form';
import React from 'react';

import {FormField} from 'src/components/FormField';
import {InlineFormWrapper} from 'src/components/styled';
import {is} from 'shared/utils/data-types';

interface PushFormProps {
  initialValues: {
    userId: string;
    penaltyId: string;
  };
  updateState: (input: {userId: string; penaltyId: string}) => any;
  onPaymentForClientPenaltyCreate: (values: {
    userId: number;
    penaltyId: string;
  }) => any;
}

export class PaymentDoForm extends React.Component<PushFormProps> {
  public render() {
    return is.nonEmptyObject(this.props.initialValues) ? (
      <InlineFormWrapper>
        <Formik
          enableReinitialize
          initialValues={{
            userId: this.props.initialValues.userId,
            penaltyId: this.props.initialValues.penaltyId,
          }}
          validate={values => {
            const mutableErrors: FormikErrors<typeof values> = {};
            if (!is.nonEmptyString(values.userId)) {
              mutableErrors.userId = 'Пожалуйста, заполните ID Пользователя.';
            }
            if (!is.nonEmptyString(values.penaltyId)) {
              mutableErrors.penaltyId = 'Пожалуйста, заполните ID Штрафа.';
            }

            return mutableErrors;
          }}
          onSubmit={values => {
            this.props.onPaymentForClientPenaltyCreate({
              userId: Number(values.userId),
              penaltyId: values.penaltyId,
            });
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <FormSection>
                <FormField
                  label="ID Пользователя"
                  inputId="input-userId"
                  inputRequired
                  invalid={touched.userId && is.nonEmptyString(errors.userId)}
                  invalidMessage={errors.userId}
                >
                  <FieldTextStateless
                    id="input-userId"
                    type="text"
                    name="userId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите ID Пользователя"
                    value={values.userId}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>
                <FormField
                  label="ID Штрафа"
                  inputId="input-penaltyId"
                  inputRequired
                  invalid={touched.penaltyId && is.nonEmptyString(errors.penaltyId)}
                  invalidMessage={errors.penaltyId}
                >
                  <FieldTextStateless
                    id="input-penaltyId"
                    type="text"
                    name="penaltyId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите ID Штрафа"
                    value={values.penaltyId}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>
              </FormSection>
              <FormFooter>
                <Button appearance="primary" type="submit">
                  Погасить
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </InlineFormWrapper>
    ) : null;
  }
}
