import {AkNavigationItemGroup, AkNavigationItem} from '@atlaskit/navigation';
import React from 'react';

import {UnderlinelessLink} from 'src/components/styled';

interface CreateDrawerProps {
  links: [string, string, string, React.ComponentType<any>][];
}

export const CreateDrawer: React.FunctionComponent<
  CreateDrawerProps
> = props => (
  <AkNavigationItemGroup title="Создать">
    {props.links.map(link => {
      const [url, text, label, Icon] = link;

      return (
        <UnderlinelessLink key={url} to={url}>
          <AkNavigationItem
            icon={<Icon label={label} />}
            text={<strong>{text.valueOf()}</strong>}
          />
        </UnderlinelessLink>
      );
    })}
  </AkNavigationItemGroup>
);
