import CopyIcon from '@atlaskit/icon/glyph/copy';
import DropdownMenu, {DropdownItem} from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import React from 'react';
import copy from 'clipboard-copy';

import {G} from 'src/types/graphql';
import {fromGlobalId} from 'src/utils/graphql-relay';
import {is} from 'shared/utils/data-types';

interface PaymentUserInfoProps {
  paymentUser: G.AllPenaltyPaymentsTable_payment_user;
}

export const PaymentUserInfo: React.FunctionComponent<
  PaymentUserInfoProps
> = props => (
  <span>
    <small>ID: </small>
    {fromGlobalId(props.paymentUser.id).id}
    {is.nonEmptyString(props.paymentUser.token) && (
      <>
        <br />
        <small>Токен: </small>
        <DropdownMenu
          triggerType="button"
          triggerButtonProps={{
            iconAfter: <MoreIcon label="Подробнее" />,
            appearance: 'subtle',
            spacing: 'none',
          }}
        >
          <DropdownItem
            shouldAllowMultiline
            elemAfter={<CopyIcon label="Скопировать" />}
            onClick={() => {
              if (is.nonEmptyString(props.paymentUser.token)) {
                copy(props.paymentUser.token);
              }
            }}
          >
            {props.paymentUser.token}
          </DropdownItem>
        </DropdownMenu>
      </>
    )}
  </span>
);
