import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {FaqCreateOrUpdateForm} from './components/FaqCreateOrUpdateForm';

export const FAQ_CREATE_OR_UPDATE_MUTATION: MutationDocument<
  G.faqCreateOrUpdate,
  G.faqCreateOrUpdateVariables
> = gql`
  mutation faqCreateOrUpdate($input: FaqCreateOrUpdateInput!) {
    faqCreateOrUpdate(input: $input) {
      faq {
        ...FaqCreateOrUpdateForm_faq
      }
    }
  }
  ${FaqCreateOrUpdateForm.fragments.faq}
`;

export const FAQ_FOR_UPDATE_QUERY: QueryDocument<
  G.faqForUpdate,
  G.faqForUpdateVariables
> = gql`
  query faqForUpdate($id: String!) {
    faq(id: $id) {
      ...FaqCreateOrUpdateForm_faq
    }
  }
  ${FaqCreateOrUpdateForm.fragments.faq}
`;
