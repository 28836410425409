import {State} from 'react-powerplug';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import React from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {TypedMutationFn} from 'src/utils/react-apollo';
import {UserPasswordChangeModalDialog} from 'src/containers/HubUserPasswordChangeModalDialog';

const HubUserUpdateDropdownMenuWrapper = styled.div`
  width: 36;
  height: 32;
`;

interface HubUserUpdateDropdownMenuProps {
  hubUser: G.AllHubUsersTable_user;
  onHubUserUpdate: TypedMutationFn<G.hubUserUpdate, G.hubUserUpdateVariables>;
  updatingHubUser: boolean;
}

export const HubUserUpdateDropdownMenu: React.FunctionComponent<
  HubUserUpdateDropdownMenuProps
> = props => (
  <HubUserUpdateDropdownMenuWrapper>
    <State initial={{isChangePasswordModalOpen: false}}>
      {({state, setState}) => {
        const openChangePasswordModal = () =>
          setState({isChangePasswordModalOpen: true});

        return (
          <>
            <DropdownMenu
              triggerType="button"
              triggerButtonProps={{
                iconAfter: <MoreVerticalIcon label="Дополнительно" />,
                appearance: 'subtle',
                shouldFitContainer: true,
              }}
              position="bottom right"
            >
              <DropdownItemGroup>
                <DropdownItem
                  onClick={() => {
                    props.onHubUserUpdate({
                      variables: {
                        input: {
                          userId: props.hubUser.id,
                          hubAccessible: !props.hubUser.hubAccessible,
                        },
                      },
                    });
                  }}
                >
                  {(() => {
                    if (props.updatingHubUser) return <Spinner size="small" />;

                    return props.hubUser.hubAccessible
                      ? 'Заблокировать'
                      : 'Вернуть доступ';
                  })()}
                </DropdownItem>
                <DropdownItem onClick={openChangePasswordModal}>
                  Сбросить пароль
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
            <UserPasswordChangeModalDialog
              isChangePasswordModalOpen={state.isChangePasswordModalOpen}
              setChangePasswordModalOpenState={isChangePasswordModalOpen =>
                setState({isChangePasswordModalOpen})
              }
              userName={props.hubUser.hubUsername}
              userId={props.hubUser.id}
            />
          </>
        );
      }}
    </State>
  </HubUserUpdateDropdownMenuWrapper>
);
