import React from 'react';

import {G} from 'src/types/graphql';
import {compact} from 'shared/utils/array';
import {is, Opt} from 'shared/utils/data-types';

interface PenaltyViolatorInfoProps {
  penalty: G.PenaltyCheck_penalty;
}

export const PenaltyViolatorInfo: React.FunctionComponent<
  PenaltyViolatorInfoProps
> = props => (
  <div>
    {is.nonEmptyString(props.penalty.iinNumber) && (
      <>
        <small>ИИН: </small>
        {props.penalty.iinNumber}
        <br />
      </>
    )}
    {is.nonEmptyString(props.penalty.carNumber) && (
      <>
        <small>Госномер авто: </small>
        {props.penalty.carNumber}
        <br />
      </>
    )}
    {is.nonEmptyString(props.penalty.carTechPassport) && (
      <>
        <small>Техпаспорт авто: </small>
        {props.penalty.carTechPassport}
        <br />
      </>
    )}
    <small>Имя: </small>
    {(() => {
      const violatorName = compact([
        props.penalty.violator.firstName,
        props.penalty.violator.lastName,
      ]).join(' ');

      return Opt.nonEmptyString(violatorName).getOrElse('-');
    })()}
  </div>
);
