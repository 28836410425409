import {ApolloError} from 'apollo-client';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import Button from '@atlaskit/button/dist/es5/components/Button';
import Form, {FormFooter, FormSection} from '@atlaskit/form';
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import ym from 'react-yandex-metrika';

import {FormField} from 'src/components/FormField';
import {InlineModalFormWrapper} from 'src/components/styled';
import {Mutation} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {CREATE_PARTNER_CAR_MUTATION} from '../graphql';

interface PartnerCarCreateModalDialogProps {
  isCreateModalOpen: boolean;
  setCreateModalOpenState: (isCreateModalOpen: boolean) => any;
  partnerCarCreateError: ApolloError | null;
  setPartnerCarCreateErrorState: (
    partnerCarCreateError: ApolloError | null,
  ) => any;
}

export const PartnerCarCreateModalDialog: React.FunctionComponent<
  PartnerCarCreateModalDialogProps
> = props => {
  const closeModal = () => {
    props.setCreateModalOpenState(false);
    props.setPartnerCarCreateErrorState(null);
  };

  return (
    <Mutation
      refetchQueries={['allPartnerCars', 'partner']}
      mutation={CREATE_PARTNER_CAR_MUTATION}
      // provide a noop here, otherwise an unhandled error is thrown
      onError={error => props.setPartnerCarCreateErrorState(error)}
      onCompleted={() => {
        closeModal();
        ym('reachGoal', 'HubAddCarEvent');
      }}
    >
      {(partnerCarCreate, {loading}) => {
        const headingMessage = `Окно добавления машины`;
        const modalFooter = () => <ModalFooter />;

        return props.isCreateModalOpen ? (
          <ModalTransition>
            <ModalDialog
              onClose={closeModal}
              footer={modalFooter}
              width="small"
              heading={headingMessage}
            >
              <InlineModalFormWrapper>
                <Formik
                  initialValues={{
                    vin: '',
                    carNumber: '',
                    carTechPassport: '',
                  }}
                  validate={values => {
                    const mutableErrors: FormikErrors<typeof values> = {};
                    if (!is.nonEmptyString(values.carNumber)) {
                      mutableErrors.carNumber = 'Пожалуйста, введите грнз.';
                    }
                    if (!is.nonEmptyString(values.carTechPassport)) {
                      mutableErrors.carTechPassport =
                        'Пожалуйста, введите техпаспорт.';
                    }

                    return mutableErrors;
                  }}
                  onSubmit={values => {
                    partnerCarCreate({
                      variables: {
                        input: {
                          ...(is.nonEmptyString(values.vin)
                            ? {vin: values.vin}
                            : {}),
                          carNumber: values.carNumber,
                          carTechPassport: values.carTechPassport,
                        },
                      },
                    });
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  }) => (
                    <Form onSubmit={handleSubmit} onReset={handleReset}>
                      <FormSection>
                        {props.partnerCarCreateError ? (
                          <SectionMessage appearance="error">
                            {messageForApolloError(props.partnerCarCreateError)}
                          </SectionMessage>
                        ) : (
                          // HACK: `form` prop is injected, so can't be just null here
                          <div />
                        )}
                        <FormField
                          label="грнз"
                          inputId="input-carNumber"
                          inputRequired
                          invalid={
                            touched.carNumber &&
                            is.nonEmptyString(errors.carNumber)
                          }
                          invalidMessage={errors.carNumber}
                        >
                          <FieldTextStateless
                            id="input-carNumber"
                            type="text"
                            name="carNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите грнз"
                            value={values.carNumber}
                            required
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="техпаспорт"
                          inputId="input-carTechPassport"
                          inputRequired
                          invalid={
                            touched.carTechPassport &&
                            is.nonEmptyString(errors.carTechPassport)
                          }
                          invalidMessage={errors.carTechPassport}
                        >
                          <FieldTextStateless
                            id="input-carTechPassport"
                            type="text"
                            name="carTechPassport"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите техпаспорт"
                            value={values.carTechPassport}
                            required
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                        <FormField
                          label="вин-код"
                          inputId="input-create-vin"
                          invalid={touched.vin && is.nonEmptyString(errors.vin)}
                          invalidMessage={errors.vin}
                        >
                          <FieldTextStateless
                            id="input-vin"
                            type="text"
                            name="vin"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите вин-код"
                            value={values.vin}
                            required={false}
                            isLabelHidden
                            shouldFitContainer
                          />
                        </FormField>
                      </FormSection>
                      <FormFooter>
                        <Button
                          appearance="primary"
                          type="submit"
                          isDisabled={loading}
                          isLoading={loading}
                        >
                          Добавить
                        </Button>
                        <Button
                          appearance="link"
                          type="button"
                          isDisabled={loading}
                          onClick={closeModal}
                        >
                          Отмена
                        </Button>
                      </FormFooter>
                    </Form>
                  )}
                </Formik>
              </InlineModalFormWrapper>
            </ModalDialog>
          </ModalTransition>
        ) : null;
      }}
    </Mutation>
  );
};
