import {DateTime} from 'luxon';
import {RouteComponentProps} from '@reach/router';
import {colors, gridSize} from '@atlaskit/theme';
import Button from '@atlaskit/button/dist/es5/components/Button';
import Page from '@atlaskit/page';
import React from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import ym from 'react-yandex-metrika';

import {BigCheckIcon} from 'src/components/BigCheckIcon';
import {BigCrossIcon} from 'src/components/BigCrossIcon';
import {FullscreenErrorMessage} from 'src/components/FullscreenErrorMessage';
import {FullscreenSpinner} from 'src/components/FullscreenSpinner';
import {G} from 'src/types/graphql';
import {Query} from 'src/utils/react-apollo';
import {UnderlinelessLink} from 'src/components/styled';
import {YellowCarIcon} from 'src/components/YellowCarIcon';
import {currencyCentsToUnits} from 'shared/utils/currency';
import {fromGlobalId} from 'src/utils/graphql-relay';
import {is, Opt} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {PAYMENT_QUERY} from './graphql';

interface MessagePageParams {}
interface MessagePageProps extends RouteComponentProps<MessagePageParams> {}

const MessageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.N50A};
`;

const PanelWrapper = styled.div`
  display: vertical;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${colors.N0};
  border-radius: 10px;
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  width: 20%;
  margin: 0 auto;
`;

const HeaderWrapper = styled.div`
  direction: vertical;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BodyWrapper = styled.div`
  direction: vertical;
  align-items: center;
  justify-content: center;
  text-align: start;
  border-color: ${colors.N300};
  background: ${colors.N20};
  width: ${gridSize() * 58}px;
  margin: ${gridSize() * 6}px auto;
  padding: 10px;
  border-radius: 5px;
`;

const CarWrapper = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10%;
`;

export const MessagePage: React.FunctionComponent<MessagePageProps> = () => (
  <Page>
    <MessageWrapper>
      <PanelWrapper>
        {is.nonEmptyString(
          String(qs.parse(window.location.search).paymentId),
        ) ? (
          <Query
            query={PAYMENT_QUERY}
            fetchPolicy="network-only"
            variables={{id: String(qs.parse(window.location.search).paymentId)}}
          >
            {({loading, error, data}) => {
              if (loading) {
                return (
                  <Page>
                    <FullscreenSpinner />
                  </Page>
                );
              }

              if (error) {
                return (
                  <CarWrapper>
                    <YellowCarIcon label="car" primaryColor={colors.Y200} />
                    <FullscreenErrorMessage title="Произошла ошибка">
                      {messageForApolloError(error)}
                    </FullscreenErrorMessage>
                  </CarWrapper>
                );
              }

              const rawData:
                | {
                    description?: string;
                    alfaBankPaymentStatusInfo?: {
                      actionCode: any;
                      actionCodeDescription: string;
                      orderNumber: string;
                      date: number;
                    };
                  }
                | null
                | undefined = data && data.payment && data.payment.rawData;

              if (
                data &&
                data.payment &&
                data.payment.status !== G.PaymentStatus.SUCCEEDED &&
                rawData &&
                is.nonEmptyObject(rawData.alfaBankPaymentStatusInfo)
              ) {
                return (
                  <>
                    <HeaderWrapper>
                      <BigCrossIcon primaryColor={colors.R400} label="check" />
                      <h2>Платеж отклонен</h2>
                    </HeaderWrapper>
                    <BodyWrapper>
                      <p>{`Номер платежа: ${Opt.nonEmptyString(
                        rawData.alfaBankPaymentStatusInfo.orderNumber,
                      ).getOrElse('-')}`}</p>
                      <p>{`Код ошибки: ${Opt.fromNullable(
                        rawData.alfaBankPaymentStatusInfo.actionCode,
                      ).getOrElse('-')}`}</p>
                      <p>{`Дата: ${Opt.positiveInt(
                        rawData.alfaBankPaymentStatusInfo.date,
                      )
                        .map(dateMillis =>
                          DateTime.fromMillis(dateMillis).toFormat(
                            'dd.MM.yyyy HH:mm',
                          ),
                        )
                        .getOrElse('-')}`}</p>
                      <p>{`Причина ошибки: ${Opt.nonEmptyString(
                        rawData.alfaBankPaymentStatusInfo.actionCodeDescription,
                      ).getOrElse('-')}`}</p>
                    </BodyWrapper>
                    <ButtonWrapper>
                      <UnderlinelessLink
                        to={`${String(
                          qs.parse(window.location.search).returnUrl,
                        ).replace('https:', '')}`}
                      >
                        <Button
                          appearance="primary"
                          type="button"
                          onClick={() => {}}
                          shouldFitContainer
                        >
                          OK
                        </Button>
                      </UnderlinelessLink>
                    </ButtonWrapper>
                  </>
                );
              }

              if (data && data.payment && rawData) {
                ym('reachGoal', 'HubSuccessPayment');

                return (
                  <>
                    <HeaderWrapper>
                      <BigCheckIcon primaryColor={colors.G300} label="check" />
                      <h2>Платеж принят!</h2>
                    </HeaderWrapper>
                    <BodyWrapper>
                      <p>{`Номер платежа: ${Opt.nonEmptyString(
                        fromGlobalId(data.payment.id).id,
                      ).getOrElse('-')}`}</p>
                      <p>{`Описание: ${Opt.nonEmptyString(
                        rawData.description,
                      ).getOrElse('-')}`}</p>
                      <p>{`Сумма: ${
                        is.positiveInt(data.payment.amountInCents)
                          ? currencyCentsToUnits(data.payment.amountInCents)
                          : '-'
                      }`}</p>
                      <p>{`Комиссия: ${
                        is.positiveInt(data.payment.feeInCents)
                          ? currencyCentsToUnits(data.payment.feeInCents)
                          : '-'
                      }`}</p>
                      <p>{`Дата: ${DateTime.fromISO(
                        data.payment.createdAt,
                      ).toFormat('dd.MM.yyyy HH:mm')}`}</p>
                    </BodyWrapper>
                    <ButtonWrapper>
                      <UnderlinelessLink
                        to={`${String(
                          qs.parse(window.location.search).returnUrl,
                        ).replace('https:', '')}`}
                      >
                        <Button
                          appearance="primary"
                          type="button"
                          shouldFitContainer
                        >
                          OK
                        </Button>
                      </UnderlinelessLink>
                    </ButtonWrapper>
                  </>
                );
              }

              return <></>;
            }}
          </Query>
        ) : (
          <CarWrapper>
            <YellowCarIcon label="car" primaryColor={colors.Y200} />
          </CarWrapper>
        )}
      </PanelWrapper>
    </MessageWrapper>
  </Page>
);
