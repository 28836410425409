import {RouteComponentProps, navigate} from '@reach/router';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';
import {is} from 'shared/utils/data-types';

import {FaqCreateOrUpdateContainer} from './containers/FaqCreateOrUpdateContainer';

interface FaqCreateOrUpdatePageParams {}
interface FaqCreateOrUpdatePageProps
  extends RouteComponentProps<FaqCreateOrUpdatePageParams> {}

export const FaqCreateOrUpdatePage: React.FunctionComponent<
  FaqCreateOrUpdatePageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    roles={[UserRole.Superadmin, UserRole.SupportOperator]}
  >
    <FaqCreateOrUpdateContainer
      location={props.location}
      onFaqCreatedOrUpdated={() => {
        navigate('/admin/faqs');
      }}
      onCancel={() => {
        navigate(
          is.nonEmptyString(document.referrer) &&
            !document.referrer.endsWith('faqs/create')
            ? document.referrer
            : '/',
        );
      }}
    />
  </ProtectedAppPage>
);
