import {colors} from '@atlaskit/theme';
import {navigate} from '@reach/router';
import Button from '@atlaskit/button/dist/es5/components/Button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';
import ym from 'react-yandex-metrika';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {Mutation} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {PENALTY_PAYMENT_CREATE} from '../graphql';

interface PartnerPenaltiesPayButtonContainerProps {
  penaltyId: string | null;
  returnUrl: string;
  isDisabled: boolean;
}

export const PartnerPenaltiesPayButtonContainer: React.FunctionComponent<
  PartnerPenaltiesPayButtonContainerProps
> = props => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Mutation
        mutation={PENALTY_PAYMENT_CREATE} // provide a noop here, otherwise an unhandled error is thrown
        onCompleted={({paymentForPartnerPenaltyCreate}) => {
          navigate(
            `//${paymentForPartnerPenaltyCreate &&
              paymentForPartnerPenaltyCreate.formUrl &&
              paymentForPartnerPenaltyCreate.formUrl.replace('https://', '')}`,
          );
        }}
        onError={err => {
          onAddFlag({
            autoDismiss: true,
            icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
            title: 'Произошла ошибка',
            description: `${messageForApolloError(err)}`,
          });
        }}
      >
        {(penaltyPaymentCreate, {loading}) => (
          <Button
            isLoading={loading}
            appearance="primary"
            onClick={() => {
              if (is.nonEmptyString(props.penaltyId)) {
                penaltyPaymentCreate({
                  variables: {
                    input: {
                      penaltyIds: [props.penaltyId],
                      returnUrl: props.returnUrl,
                    },
                  },
                });
                ym('reachGoal', 'HubPayFine');
              }
            }}
            isDisabled={props.isDisabled}
          >
            Оплатить
          </Button>
        )}
      </Mutation>
    )}
  </FlagsConsumer>
);
