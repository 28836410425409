import Page from '@atlaskit/page';
import React from 'react';

import {FullscreenErrorMessage} from 'src/components/FullscreenErrorMessage';
import {FullscreenSpinner} from 'src/components/FullscreenSpinner';
import {G} from 'src/types/graphql';
import {Query} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {AppPage} from './components/AppPage';
import {DefaultNavigation} from './components/DefaultNavigation';
import {VIEWER_QUERY} from './graphql';

interface AppPageWithViewerProps {
  children: (result: {
    viewer: G.AppNavigation_viewer | null;
  }) => React.ReactNode;
  NavigationComponent?: React.ComponentType<{viewer: G.AppNavigation_viewer}>;
  hideDefaultNavigation?: boolean;
}

export const AppPageWithViewer: React.FunctionComponent<
  AppPageWithViewerProps
> = props => (
  <Query query={VIEWER_QUERY}>
    {({loading, error, data}) => {
      if (loading) {
        return (
          <Page>
            <FullscreenSpinner />
          </Page>
        );
      }
      if (error) {
        return (
          <Page>
            <FullscreenErrorMessage title="Произошла ошибка">
              {messageForApolloError(error)}
            </FullscreenErrorMessage>
          </Page>
        );
      }

      const navigation =
        props.NavigationComponent && data && data.viewer ? (
          <props.NavigationComponent viewer={data.viewer} />
        ) : null;

      return (
        <AppPage
          navigation={
            navigation ||
            (props.hideDefaultNavigation ? undefined : <DefaultNavigation />)
          }
          navigationAdaptive={
            navigation
              ? // TODO: figure out a way to determine if `navigation` element returns <AppNavigation />
                true
              : undefined
          }
        >
          {props.children({
            viewer: data ? data.viewer : null,
          })}
        </AppPage>
      );
    }}
  </Query>
);

export * from './graphql';
