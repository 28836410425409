import {AkGlobalItem} from '@atlaskit/navigation';
import Avatar from '@atlaskit/avatar';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import React from 'react';
import Spinner from '@atlaskit/spinner';

import {G} from 'src/types/graphql';

interface AccountDropdownMenuProps {
  viewer: G.AppNavigation_viewer;
  onLogOut: () => any;
  loggingOut: boolean;
}

export const AccountDropdownMenu: React.FunctionComponent<
  AccountDropdownMenuProps
> = props => (
  <DropdownMenu
    trigger={
      <AkGlobalItem>
        <Avatar size="small" />
      </AkGlobalItem>
    }
    appearance="tall"
    position="right bottom"
  >
    <DropdownItemGroup title={props.viewer.hubUsername}>
      <DropdownItem onClick={props.onLogOut}>
        {props.loggingOut ? <Spinner size="small" /> : 'Выйти'}
      </DropdownItem>
    </DropdownItemGroup>
  </DropdownMenu>
);
