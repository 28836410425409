import {State} from 'react-powerplug';
import React from 'react';

import {G} from 'src/types/graphql';
import {Query} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';
import {offsetToCursor} from 'src/utils/graphql-relay';

import {ALL_PENALTY_PAYMENTS_FOR_ADMIN_QUERY} from '../graphql';
import {AllPenaltyPaymentsTable} from '../components/AllPenaltyPaymentsTable';
import {PenaltyPaymentDropdownMenuContainer} from './PenaltyPaymentDropdownMenu';

const ROWS_PER_PAGE = 25;

interface AllPenaltyPaymentsTableContainerProps {
  direction: G.PaymentDirection;
  searchBy: G.PenaltyPaymentSearchBy;
  debouncedSearchText: string;
  filters: G.PenaltyPaymentFilters;
  selectedPenaltyIds: string[];
  onChangeCheckbox: (paymentId: string) => any;
}

// use `PureComponent` to avoid rerendering on each search text change
export class AllPenaltyPaymentsTableContainer extends React.PureComponent<
  AllPenaltyPaymentsTableContainerProps
> {
  public render() {
    return (
      <State initial={{pageIdx: 0}}>
        {({state, setState}) => {
          const lastElementOnPageIdx = ROWS_PER_PAGE * state.pageIdx - 1;
          const filters =
            this.props.direction === G.PaymentDirection.INCOMING
              ? {
                  ...this.props.filters,
                  forRegressingPenaltiesOnly: false,
                }
              : this.props.filters;

          return (
            <Query
              query={ALL_PENALTY_PAYMENTS_FOR_ADMIN_QUERY}
              variables={{
                after:
                  lastElementOnPageIdx === -1
                    ? null
                    : offsetToCursor(lastElementOnPageIdx),
                first: ROWS_PER_PAGE,
                direction: this.props.direction,
                // HACK: use fixed value to avoid rerendering with empty search
                // text
                searchBy: is.nonEmptyString(this.props.debouncedSearchText)
                  ? this.props.searchBy
                  : G.PenaltyPaymentSearchBy.CAR_NUMBER,
                searchText: this.props.debouncedSearchText,
                filters,
              }}
            >
              {({loading, error, data}) => (
                <AllPenaltyPaymentsTable
                  direction={this.props.direction}
                  pageIdx={state.pageIdx}
                  onSetPageIdx={pageIdx => {
                    setState({pageIdx});
                  }}
                  searchBy={this.props.searchBy}
                  debouncedSearchText={this.props.debouncedSearchText}
                  MoreCellComponent={PenaltyPaymentDropdownMenuContainer}
                  loading={loading}
                  error={error}
                  data={data}
                  selectedPenaltyIds={this.props.selectedPenaltyIds}
                  onChangeCheckbox={this.props.onChangeCheckbox}
                />
              )}
            </Query>
          );
        }}
      </State>
    );
  }
}
