import React from 'react';

import {G} from 'src/types/graphql';
import {Query} from 'src/utils/react-apollo';

import {ALL_FAQS_FOR_ADMIN_QUERY} from '../graphql';
import {AllFaqsCategoryTable} from '../components/AllFaqsCategoryTable';
import {AllFaqsDragAndDropTable} from '../components/AllFaqsDragAndDropTable';
import {MoreDropdownMenu} from './MoreDropdownMenu';

interface AllFaqsTableContainerProps {
  debouncedSearchText: string;
  category: G.FaqCategory | 'ALL';
}

// use `PureComponent` to avoid rerendering on each search text change
export class AllFaqsTableContainer extends React.PureComponent<
  AllFaqsTableContainerProps
> {
  public render() {
    return (
      <Query
        query={ALL_FAQS_FOR_ADMIN_QUERY}
        variables={{
          searchText: this.props.debouncedSearchText,
          category: this.props.category !== 'ALL' ? this.props.category : null,
        }}
      >
        {({loading, error, data}) =>
          this.props.category === 'ALL' ? (
            <AllFaqsDragAndDropTable
              MoreCellComponent={MoreDropdownMenu}
              debouncedSearchText={this.props.debouncedSearchText}
              loading={loading}
              error={error}
              data={data}
            />
          ) : (
            <AllFaqsCategoryTable
              MoreCellComponent={MoreDropdownMenu}
              debouncedSearchText={this.props.debouncedSearchText}
              loading={loading}
              error={error}
              data={data}
            />
          )
        }
      </Query>
    );
  }
}
