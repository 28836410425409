import {ApolloError} from 'apollo-client';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import Button from '@atlaskit/button';
import Form, {FormHeader, FormSection, FormFooter} from '@atlaskit/form';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import gql from 'graphql-tag';

import {FormField} from 'src/components/FormField';
import {InlineFormWrapper} from 'src/components/styled';
import {InsuranceCompany} from 'src/types/insuranceCompany';
import {Query} from 'src/utils/react-apollo';
import {UserRole} from 'src/types/auth';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

import {ALL_PARTNERS_QUERY} from '../graphql';

interface HubUserData {
  role: UserRole;
  username: string;
  password: string;
  insuranceCompany: string;
  partnerName: string;
}

interface HubUserCreateFormProps {
  onHubUserCreate: (data: HubUserData) => void;
  onCancel: () => void;
  loading: boolean;
  error?: ApolloError;
}

export class HubUserCreateForm extends React.Component<HubUserCreateFormProps> {
  public static fragments = {
    user: gql`
      fragment HubUserCreateForm_user on User {
        id
        createdAt
        role
        hubUsername
        hubAccessible
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <InlineFormWrapper>
        <Formik
          initialValues={{
            role: '' as UserRole,
            username: '',
            password: '',
            insuranceCompany: '',
            partnerName: '',
          }}
          validate={values => {
            const mutableErrors: FormikErrors<typeof values> = {};
            if (!is.nonEmptyString(values.role)) {
              mutableErrors.role = 'Пожалуйста, выберите роль.';
            }
            if (!is.nonEmptyString(values.username)) {
              mutableErrors.username = 'Пожалуйста, введите имя пользователя.';
            }
            if (!is.nonEmptyString(values.password)) {
              mutableErrors.password = 'Пожалуйста, введите пароль.';
            }
            if (
              values.role === UserRole.PartnerInsuranceOperator &&
              !is.nonEmptyString(values.insuranceCompany)
            ) {
              mutableErrors.insuranceCompany =
                'Пожалуйста, выберите страховую компанию.';
            }
            if (
              values.role === UserRole.PartnerCarpark &&
              !is.nonEmptyString(values.partnerName)
            ) {
              mutableErrors.insuranceCompany = 'Пожалуйста, выберите партнера.';
            }

            return mutableErrors;
          }}
          onSubmit={values => {
            this.props.onHubUserCreate(values);
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <FormHeader title="Создать нового админа" />
              <FormSection>
                {this.props.error ? (
                  <SectionMessage appearance="error">
                    {messageForApolloError(this.props.error)}
                  </SectionMessage>
                ) : (
                  // HACK: `form` prop is injected, so can't be just null here
                  <div />
                )}
                <FormField
                  label="Роль"
                  inputId="input-role"
                  inputRequired
                  invalid={touched.role && is.nonEmptyString(errors.role)}
                  invalidMessage={errors.role}
                >
                  <Select
                    id="input-role"
                    name="role"
                    value={
                      is.nonEmptyString(values.role)
                        ? {label: values.role, value: values.role}
                        : null
                    }
                    onChange={({value}: {label: string; value: UserRole}) => {
                      setFieldValue('role', value);
                    }}
                    onBlur={handleBlur}
                    placeholder="Выберите роль"
                    options={Object.values(UserRole).map(role => ({
                      label: role,
                      value: role,
                    }))}
                  />
                </FormField>
                <FormField
                  label="Имя пользователя"
                  inputId="input-username"
                  inputRequired
                  invalid={
                    touched.username && is.nonEmptyString(errors.username)
                  }
                  invalidMessage={errors.username}
                >
                  <FieldTextStateless
                    id="input-username"
                    type="text"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите имя пользователя"
                    value={values.username}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>
                <FormField
                  label="Пароль"
                  inputId="input-password"
                  inputRequired
                  invalid={
                    touched.password && is.nonEmptyString(errors.password)
                  }
                  invalidMessage={errors.password}
                >
                  <FieldTextStateless
                    id="input-password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите пароль"
                    value={values.password}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>

                {values.role === UserRole.PartnerInsuranceOperator && (
                  <FormField
                    label="Страховая компания"
                    inputId="input-insuranceCompany"
                    inputRequired
                    invalid={
                      touched.insuranceCompany &&
                      is.nonEmptyString(errors.insuranceCompany)
                    }
                    invalidMessage={errors.insuranceCompany}
                  >
                    <Select
                      id="input-insuranceCompany"
                      name="role"
                      value={
                        is.nonEmptyString(values.insuranceCompany)
                          ? {
                              label: values.insuranceCompany,
                              value: values.insuranceCompany,
                            }
                          : null
                      }
                      onChange={({value}: {label: string; value: string}) => {
                        setFieldValue('insuranceCompany', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Выберите страховую компанию"
                      options={Object.values(InsuranceCompany).map(
                        insuranceCompany => ({
                          label: insuranceCompany,
                          value: insuranceCompany,
                        }),
                      )}
                    />
                  </FormField>
                )}
                {values.role === UserRole.PartnerCarpark && (
                  <FormField
                    label="Партнер"
                    inputId="input-partnerName"
                    inputRequired
                    invalid={
                      touched.partnerName &&
                      is.nonEmptyString(errors.partnerName)
                    }
                    invalidMessage={errors.partnerName}
                  >
                    <Query query={ALL_PARTNERS_QUERY}>
                      {({data}) => (
                        <Select
                          id="input-partnerName"
                          name="partnerName"
                          value={
                            is.nonEmptyString(values.partnerName)
                              ? {
                                  label: values.partnerName,
                                  value: values.partnerName,
                                }
                              : null
                          }
                          onChange={({
                            value,
                          }: {
                            label: string;
                            value: string;
                          }) => {
                            setFieldValue('partnerName', value);
                          }}
                          onBlur={handleBlur}
                          placeholder="Выберите партнера"
                          options={
                            data && data.allPartners
                              ? nodesFromConnection(data.allPartners).map(
                                  partner => ({
                                    label: partner.name,
                                    value: partner.name,
                                  }),
                                )
                              : []
                          }
                        />
                      )}
                    </Query>
                  </FormField>
                )}
              </FormSection>
              <FormFooter>
                <Button
                  appearance="primary"
                  type="submit"
                  isDisabled={this.props.loading}
                  isLoading={this.props.loading}
                >
                  Создать
                </Button>
                <Button
                  appearance="link"
                  type="button"
                  isDisabled={this.props.loading}
                  onClick={this.props.onCancel}
                >
                  Отмена
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </InlineFormWrapper>
    );
  }
}
