import {State} from 'react-powerplug';
import Button, {ButtonGroup} from '@atlaskit/button';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import ModalDialog, {
  ModalTransition,
  ModalFooter,
} from '@atlaskit/modal-dialog';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import React from 'react';
import qs from 'query-string';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';

import {ARCHIVE_OR_UNARCHIVE_FAQ_MUTATION} from '../graphql';

const MoreDropdownMenuWrapper = styled.div`
  width: 36;
  height: 32;
`;

interface MoreDropdownMenuProps {
  faq: G.AllFaqsTable_faq;
}

export const MoreDropdownMenu: React.FunctionComponent<
  MoreDropdownMenuProps
> = (props: MoreDropdownMenuProps) => (
  <State initial={{isModalOpen: false}}>
    {({state, setState}) => (
      <MoreDropdownMenuWrapper>
        {
          <Mutation
            refetchQueries={['allFaqsForAdmin']}
            mutation={ARCHIVE_OR_UNARCHIVE_FAQ_MUTATION}
            // provide a noop here, otherwise an unhandled error is thrown
            onError={() => {}}
          >
            {(faqArchiveOrUnarchive, {loading: faqArchiving}) => {
              const headingMessage = `Вы действительно хотите ${
                is.nil(props.faq.archivedAt)
                  ? 'заархивировать'
                  : 'разархивировать'
              } FAQ?`;
              const closeModal = () => setState({isModalOpen: false});
              const openModal = () => setState({isModalOpen: true});
              const modalFooter = () => (
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      isLoading={faqArchiving}
                      appearance="primary"
                      onClick={() => {
                        faqArchiveOrUnarchive({
                          variables: {
                            input: {
                              id: props.faq.id,
                            },
                          },
                        });
                        closeModal();
                      }}
                    >
                      Да
                    </Button>
                    <Button appearance="default" onClick={closeModal}>
                      Отмена
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              );

              return (
                <>
                  <DropdownMenu
                    triggerType="button"
                    triggerButtonProps={{
                      iconAfter: <MoreVerticalIcon label="Дополнительно" />,
                      appearance: 'subtle',
                      shouldFitContainer: true,
                    }}
                    position="bottom right"
                  >
                    <DropdownItemGroup>
                      <DropdownItem
                        href={`/admin/faqs/create?${qs.stringify({
                          id: props.faq.id,
                        })}`}
                      >
                        Редактировать
                      </DropdownItem>
                      <DropdownItem onClick={openModal}>
                        {is.nil(props.faq.archivedAt)
                          ? 'Архивировать'
                          : 'Разархивировать'}
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                  {state.isModalOpen ? (
                    <ModalTransition>
                      <ModalDialog
                        onClose={closeModal}
                        footer={modalFooter}
                        width="small"
                        heading={headingMessage}
                      />
                    </ModalTransition>
                  ) : null}
                </>
              );
            }}
          </Mutation>
        }
      </MoreDropdownMenuWrapper>
    )}
  </State>
);
