import '@atlaskit/css-reset/dist/bundle.css';
import {Reset} from '@atlaskit/theme';
import {YMInitializer} from 'react-yandex-metrika';
import {isRedirect, Router} from '@reach/router';
import React from 'react';

import {AllCarsPage} from 'src/pages/partner/carpark/AllCarsPage';
import {AllDriversPage} from 'src/pages/partner/carpark/AllDriversPage';
import {AllFaqsPage} from 'src/pages/admin/AllFaqsPage';
import {AllHubUsersPage} from 'src/pages/admin/AllHubUsersPage';
import {AllInsuranceApplicationsPage} from 'src/pages/partner/insurance/AllInsuranceApplicationsPage';
import {AllPartnersPage} from 'src/pages/admin/AllPartnersPage';
import {AllPenaltyPaymentsPage} from 'src/pages/admin/AllPenaltyPaymentsPage';
import {ApolloProvider} from 'src/utils/react-apollo';
import {FaqCreateOrUpdatePage} from 'src/pages/admin/FaqCreateOrUpdatePage';
import {FlagsProvider} from 'src/containers/FlagsContext';
import {HomePage} from 'src/pages/root/HomePage';
import {HubUserCreatePage} from 'src/pages/admin/HubUserCreatePage';
import {LoginPage} from 'src/pages/root/LoginPage';
import {MessagePage} from 'src/pages/root/MessagePage';
import {NotFoundPage} from 'src/pages/root/NotFoundPage';
import {PartnerPenaltiesForCarPage} from 'src/pages/partner/carpark/PartnerPenaltiesForCarPage';
import {PaymentDoPage} from "src/pages/admin/PaymentDoPage";
import {PenaltyCheckPage} from 'src/pages/admin/PenaltyCheckPage';
import {PushPage} from "src/pages/admin/PushPage";
import {SettingsPage} from 'src/pages/admin/SettingsPage';
import {apolloClient} from 'src/apollo';
import {is} from 'shared/utils/data-types';

interface RootProps {}

export class Root extends React.Component<RootProps> {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (isRedirect(error)) {
      throw error;
    } else {
      console.error('App#componentDidCatch: ', error);

      // this is needed to render errors correctly in development / production
      if (super.componentDidCatch) {
        super.componentDidCatch(error, errorInfo);
      }
    }
  }

  public render() {
    return (
      <>
        {(() => {
          if (is.nonEmptyString(process.env.HUB_YANDEX_METRICA_TRACKER_ID)) {
            return (
              <YMInitializer
                accounts={[Number(process.env.HUB_YANDEX_METRICA_TRACKER_ID)]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                }}
                version="2"
              />
            );
          }

          return <></>;
        })()}
        <ApolloProvider client={apolloClient}>
          <Reset>
            <FlagsProvider>
              <Router>
                {/* root */}
                <NotFoundPage default />
                <HomePage path="/" />
                <LoginPage path="/login" />
                <MessagePage path="/message" />
                {/* admin */}
                <AllPenaltyPaymentsPage path="/admin/payments/penalties" />
                <PenaltyCheckPage path="/admin/penalty-check" />
                <AllHubUsersPage path="/admin/hub-users" />
                <HubUserCreatePage path="/admin/hub-users/create" />
                <SettingsPage path="/admin/settings" />
                <PaymentDoPage path="/admin/payment-do" />
                <PushPage path="/admin/push" />
                <AllFaqsPage path="/admin/faqs" />
                <FaqCreateOrUpdatePage path="/admin/faqs/create" />
                <AllPartnersPage path="/admin/partners" />
                {/* partner */}
                <AllInsuranceApplicationsPage path="/partner/insurance-applications" />
                <AllCarsPage path="/partner/carpark" />
                <AllDriversPage path="/partner/drivers" />
                <PartnerPenaltiesForCarPage path="/partner/carpark/penalties" />
              </Router>
            </FlagsProvider>
          </Reset>
        </ApolloProvider>
      </>
    );
  }
}
