import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import React from 'react';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {TypedMutationFn} from 'src/utils/react-apollo';
import {fromGlobalId} from 'src/utils/graphql-relay';

const MoreDropdownMenuWrapper = styled.div`
  width: 36;
  height: 32;
`;

interface MoreDropdownMenuProps {
  onPenaltyPaymentRefund: TypedMutationFn<
    G.penaltyPaymentRefund,
    G.penaltyPaymentRefundVariables
  >;
  paymentId: string;
  direction: G.PaymentDirection;
  status: G.PaymentStatus;
  loading: boolean;
}

export const MoreDropdownMenu: React.FunctionComponent<
  MoreDropdownMenuProps
> = props => (
  <MoreDropdownMenuWrapper>
    {props.direction === G.PaymentDirection.OUTGOING && (
      <DropdownMenu
        triggerType="button"
        triggerButtonProps={{
          iconAfter: <MoreVerticalIcon label="Дополнительно" />,
          appearance: 'subtle',
          shouldFitContainer: true,
        }}
        position="bottom right"
      >
        <DropdownItemGroup>
          <DropdownItem
            href={`${
              process.env.API_LIVE_URL
            }/hub/graphql/receipts/repayment-receipt-${
              fromGlobalId(props.paymentId).id
            }.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Скачать квитанцию
          </DropdownItem>
          <DropdownItem
            href={`${
              process.env.API_LIVE_URL
            }/hub/graphql/logs/repayment-logs-${
              fromGlobalId(props.paymentId).id
            }.yml`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Загрузить логи
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    )}
    {props.direction === G.PaymentDirection.INCOMING &&
      props.status === G.PaymentStatus.SUCCEEDED && (
        <DropdownMenu
          triggerType="button"
          triggerButtonProps={{
            iconAfter: <MoreVerticalIcon label="Дополнительно" />,
            appearance: 'subtle',
            shouldFitContainer: true,
          }}
          position="bottom right"
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => {
                props.onPenaltyPaymentRefund({
                  variables: {
                    input: {
                      paymentId: props.paymentId,
                    },
                  },
                });
              }}
              isDisabled={props.loading}
            >
              Сделать возврат
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      )}
  </MoreDropdownMenuWrapper>
);
