import React from 'react';
import qs from 'query-string';

import {Query} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';

import {FAQ_FOR_UPDATE_QUERY} from '../graphql';
import {FaqCreateOrUpdateFormContainer} from './FaqCreateOrUpdateFormContainer';

interface FaqCreateOrUpdateContainerProps {
  location: Window['location'] | undefined;
  onFaqCreatedOrUpdated: () => void;
  onCancel: () => void;
}

export const FaqCreateOrUpdateContainer: React.FunctionComponent<
  FaqCreateOrUpdateContainerProps
> = props =>
  is.nonEmptyObject(props.location) &&
  typeof qs.parse(props.location.search).id === 'string' ? (
    <Query
      query={FAQ_FOR_UPDATE_QUERY}
      variables={{
        id: String(qs.parse(props.location.search).id),
      }}
    >
      {({loading, error, data}) => {
        if (loading) {
          return <div />;
        }

        return (
          <FaqCreateOrUpdateFormContainer
            onFaqCreatedOrUpdated={props.onFaqCreatedOrUpdated}
            onCancel={props.onCancel}
            loading={loading}
            error={error}
            data={data}
          />
        );
      }}
    </Query>
  ) : (
    <FaqCreateOrUpdateFormContainer
      onFaqCreatedOrUpdated={props.onFaqCreatedOrUpdated}
      onCancel={props.onCancel}
    />
  );
