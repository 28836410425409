import {RouteComponentProps, Link} from '@reach/router';
import Page from '@atlaskit/page';
import React from 'react';

import {FullscreenEmptyState} from 'src/components/FullscreenEmptyState';

interface NotFoundPageParams {}
interface NotFoundPageProps extends RouteComponentProps<NotFoundPageParams> {}

export const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = () => (
  <Page>
    <FullscreenEmptyState
      header="Страница не найдена"
      description={
        <span>
          Попробуйте вернуться на <Link to="/">главную</Link>.
        </span>
      }
    />
  </Page>
);
