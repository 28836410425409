import {stringToBase64, stringFromBase64} from 'src/utils/string';

const CURSOR_PREFIX = 'arrayconnection:';

interface Edge<T> {
  node: T | null;
  cursor?: string;
}
interface PageInfo {
  startCursor?: string;
  endCursor?: string;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}
interface Connection<T> {
  edges: (Edge<T> | null)[] | null;
  pageInfo?: PageInfo | null;
  totalPageCount?: number | null;
}

export const offsetToCursor = (offset: number) =>
  stringToBase64(`${CURSOR_PREFIX}${offset}`);

export const toGlobalId = (type: string, id: string) =>
  stringToBase64([type, id].join(':'));

export const fromGlobalId = (globalId: string) => {
  const unbasedGlobalId = stringFromBase64(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(':');

  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: unbasedGlobalId.substring(delimiterPos + 1),
  };
};

export const nodesFromConnection = <T>(connection: Connection<T>) =>
  connection.edges
    ? connection.edges
        .filter((edge): edge is Edge<T> => Boolean(edge))
        .map(edge => edge.node)
        .filter((node): node is T => Boolean(node))
    : [];
