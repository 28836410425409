import {ApolloError} from 'apollo-client';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik} from 'formik';
import Button from '@atlaskit/button';
import Form, {FormHeader, FormSection, FormFooter} from '@atlaskit/form';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';

import {FormField} from 'src/components/FormField';
import {InlineFormWrapper} from 'src/components/styled';
import {messageForApolloError} from 'src/apollo';

interface LoginFormProps {
  onLogIn: (input: {username: string; password: string}) => any;
  loading: boolean;
  error?: ApolloError;
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = props => (
  <InlineFormWrapper>
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      onSubmit={values =>
        props.onLogIn({
          username: values.username.trim(),
          password: values.password.trim(),
        })
      }
    >
      {({values, handleChange, handleBlur, handleSubmit, handleReset}) => (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormHeader title="Войти в OKauto Hub" />
          <FormSection>
            {props.error ? (
              <SectionMessage appearance="error">
                {messageForApolloError(props.error)}
              </SectionMessage>
            ) : (
              // HACK: `form` prop is injected, so can't be just null here
              <div />
            )}
            <FormField
              label="Имя пользователя"
              inputId="input-username"
              inputRequired
            >
              <FieldTextStateless
                id="input-username"
                type="username"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Введите имя пользователя"
                value={values.username}
                required
                isLabelHidden
                shouldFitContainer
              />
            </FormField>
            <FormField label="Пароль" inputId="input-password" inputRequired>
              <FieldTextStateless
                id="input-password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Введите пароль"
                value={values.password}
                required
                isLabelHidden
                shouldFitContainer
              />
            </FormField>
          </FormSection>
          <FormFooter>
            <Button
              appearance="primary"
              type="submit"
              isDisabled={props.loading}
              isLoading={props.loading}
            >
              Войти
            </Button>
          </FormFooter>
        </Form>
      )}
    </Formik>
  </InlineFormWrapper>
);
