import React, {useState} from 'react';

import {G} from 'src/types/graphql';
import {Query, Mutation} from 'src/utils/react-apollo';
import {offsetToCursor} from 'src/utils/graphql-relay';

import {
  ALL_INSURANCE_APPLICATIONS_FOR_PARTNER_QUERY,
  INSURANCE_APPLICATION_UPDATE_MUTATION,
} from '../graphql';
import {AllInsuranceApplicationsTable} from '../components/AllInsuranceApplicationsTable';

const ROWS_PER_PAGE = 25;

interface AllInsuranceApplicationsTableContainerProps {}

export const AllInsuranceApplicationsTableContainer: React.FunctionComponent<
  AllInsuranceApplicationsTableContainerProps
> = () => {
  const [pageIdx, setPageIdx] = useState(0);
  const lastElementOnPageIdx = ROWS_PER_PAGE * pageIdx - 1;

  return (
    <Query
      query={ALL_INSURANCE_APPLICATIONS_FOR_PARTNER_QUERY}
      variables={{
        after:
          lastElementOnPageIdx === -1
            ? null
            : offsetToCursor(lastElementOnPageIdx),
        first: ROWS_PER_PAGE,
      }}
    >
      {({loading, error, data}) => (
        <Mutation
          mutation={INSURANCE_APPLICATION_UPDATE_MUTATION}
          update={(cache, {data: insuranceApplicationUpdateData}) => {
            if (
              insuranceApplicationUpdateData &&
              insuranceApplicationUpdateData.insuranceApplicationUpdate
            ) {
              cache.writeFragment<
                G.allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node
              >({
                id:
                  insuranceApplicationUpdateData.insuranceApplicationUpdate
                    .insuranceApplication.id,
                fragment:
                  AllInsuranceApplicationsTable.fragments.insuranceApplication,
                data:
                  insuranceApplicationUpdateData.insuranceApplicationUpdate
                    .insuranceApplication,
              });
            }
          }}
        >
          {insuranceApplicationUpdate => (
            <AllInsuranceApplicationsTable
              pageIdx={pageIdx}
              onSetPageIdx={value => {
                setPageIdx(value);
              }}
              loading={loading}
              error={error}
              data={data}
              onInsuranceApplicationUpdate={input => {
                insuranceApplicationUpdate({
                  variables: {
                    input,
                  },
                });
              }}
            />
          )}
        </Mutation>
      )}
    </Query>
  );
};
