import {ApolloError} from 'apollo-client';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import {State} from 'react-powerplug';
import {colors} from '@atlaskit/theme';
import Button from '@atlaskit/button/dist/es5/components/Button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import Form, {FormFooter, FormSection} from '@atlaskit/form';
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {FormField} from 'src/components/FormField';
import {InlineModalFormWrapper} from 'src/components/styled';
import {Mutation} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {HUB_USER_PASSWORD_CHANGE_MUTATION} from './graphql';

interface UserPasswordChangeModalDialogProps {
  isChangePasswordModalOpen: boolean;
  setChangePasswordModalOpenState: (isChangePasswordModalOpen: boolean) => any;
  userId?: string;
  userName: string | null;
}
interface UserPasswordChangeModalDialogState {
  userPasswordChangeError: ApolloError | null;
}

export const UserPasswordChangeModalDialog: React.FunctionComponent<
  UserPasswordChangeModalDialogProps
> = props => (
  <State<UserPasswordChangeModalDialogState>
    initial={{userPasswordChangeError: null}}
  >
    {({state, setState}) => {
      const closeModal = () => {
        props.setChangePasswordModalOpenState(false);
        setState({userPasswordChangeError: null});
      };

      return (
        <FlagsConsumer>
          {({onAddFlag}) => (
            <Mutation
              mutation={HUB_USER_PASSWORD_CHANGE_MUTATION}
              // provide a noop here, otherwise an unhandled error is thrown
              onError={error => setState({userPasswordChangeError: error})}
              onCompleted={() => {
                closeModal();
                onAddFlag({
                  autoDismiss: true,
                  icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
                  title: `Пароль успешно изменен`,
                });
              }}
            >
              {(userPasswordChange, {loading}) => {
                const headingMessage = `${
                  is.nonEmptyString(props.userId) ? 'Сброс' : 'Изменение'
                } пароля пользователя: ${props.userName}`;
                const modalFooter = () => <ModalFooter />;

                return props.isChangePasswordModalOpen ? (
                  <ModalTransition>
                    <ModalDialog
                      onClose={closeModal}
                      footer={modalFooter}
                      width="small"
                      heading={headingMessage}
                    >
                      <InlineModalFormWrapper>
                        <Formik
                          initialValues={{
                            currentPassword: '',
                            newPassword: '',
                            repeatPassword: '',
                          }}
                          validate={values => {
                            const mutableErrors: FormikErrors<
                              typeof values
                            > = {};
                            if (
                              !is.nonEmptyString(props.userId) &&
                              !is.nonEmptyString(values.currentPassword)
                            ) {
                              mutableErrors.currentPassword =
                                'Пожалуйста, введите пароль.';
                            }
                            if (!is.nonEmptyString(values.newPassword)) {
                              mutableErrors.newPassword =
                                'Пожалуйста, введите новый пароль.';
                            }
                            if (!is.nonEmptyString(values.repeatPassword)) {
                              mutableErrors.repeatPassword =
                                'Пожалуйста, введите пароль повторно.';
                            }
                            if (
                              is.nonEmptyString(values.repeatPassword) &&
                              is.nonEmptyString(values.newPassword) &&
                              values.newPassword !== values.repeatPassword
                            ) {
                              mutableErrors.repeatPassword =
                                'Пароли не совпадают.';
                            }

                            return mutableErrors;
                          }}
                          onSubmit={values => {
                            userPasswordChange({
                              variables: {
                                input: {
                                  userId: props.userId,
                                  currentPassword: values.currentPassword,
                                  newPassword: values.newPassword,
                                },
                              },
                            });
                          }}
                        >
                          {({
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                          }) => (
                            <Form onSubmit={handleSubmit} onReset={handleReset}>
                              <FormSection>
                                {state.userPasswordChangeError ? (
                                  <SectionMessage appearance="error">
                                    {messageForApolloError(
                                      state.userPasswordChangeError,
                                    )}
                                  </SectionMessage>
                                ) : (
                                  // HACK: `form` prop is injected, so can't be just null here
                                  <div />
                                )}
                                {!is.nonEmptyString(props.userId) ? (
                                  <FormField
                                    label="Текущий пароль"
                                    inputId="input-currentPassword"
                                    inputRequired
                                    invalid={
                                      touched.currentPassword &&
                                      is.nonEmptyString(errors.currentPassword)
                                    }
                                    invalidMessage={errors.currentPassword}
                                  >
                                    <FieldTextStateless
                                      id="input-currentPassword"
                                      type="password"
                                      name="currentPassword"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="Введите текущий пароль"
                                      value={values.currentPassword}
                                      required
                                      isLabelHidden
                                      shouldFitContainer
                                    />
                                  </FormField>
                                ) : (
                                  <></>
                                )}
                                <FormField
                                  label="Новый пароль"
                                  inputId="input-newPassword"
                                  inputRequired
                                  invalid={
                                    touched.newPassword &&
                                    is.nonEmptyString(errors.newPassword)
                                  }
                                  invalidMessage={errors.newPassword}
                                >
                                  <FieldTextStateless
                                    id="input-newPassword"
                                    type="password"
                                    name="newPassword"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Введите новый пароль"
                                    value={values.newPassword}
                                    required
                                    isLabelHidden
                                    shouldFitContainer
                                  />
                                </FormField>
                                <FormField
                                  label="Повторите пароль"
                                  inputId="input-repeatPassword"
                                  invalid={
                                    touched.repeatPassword &&
                                    is.nonEmptyString(errors.repeatPassword)
                                  }
                                  invalidMessage={errors.repeatPassword}
                                >
                                  <FieldTextStateless
                                    id="input-repeatPassword"
                                    type="password"
                                    name="repeatPassword"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Введите пароль повторно"
                                    value={values.repeatPassword}
                                    required={false}
                                    isLabelHidden
                                    shouldFitContainer
                                  />
                                </FormField>
                              </FormSection>
                              <FormFooter>
                                <Button
                                  appearance="primary"
                                  type="submit"
                                  isDisabled={loading}
                                  isLoading={loading}
                                >
                                  Сохранить
                                </Button>
                                <Button
                                  appearance="link"
                                  type="button"
                                  isDisabled={loading}
                                  onClick={closeModal}
                                >
                                  Отмена
                                </Button>
                              </FormFooter>
                            </Form>
                          )}
                        </Formik>
                      </InlineModalFormWrapper>
                    </ModalDialog>
                  </ModalTransition>
                ) : null;
              }}
            </Mutation>
          )}
        </FlagsConsumer>
      );
    }}
  </State>
);
