import ModalDialog, {ModalTransition} from '@atlaskit/modal-dialog';
import React from 'react';
import styled from 'styled-components';

import {allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node} from 'src/__generated__/graphql-types.gen';
import {is} from 'shared/utils/data-types';

interface PartnerPenaltiesForCarShowPhotosModalDialogProps {
  partnerPenalty: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node | null;
  isShowPhotosModalOpen: boolean;
  setShowPhotosModalOpenState: (isShowPhotosModalOpen: boolean) => any;
  setPartnerPenaltyState: (
    partnerPenalty: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node | null,
  ) => any;
}

const ImageContainer = styled.img`
  width: 400px;
  height: 400px;
  margin: 20px;
  vertical-align: middle;
  object-fit: contain;
`;
const DivModalBodyContainer = styled.div`
  height: 440px;
  width: 880px;
  margin: auto;
  text-align: center;
  line-height: 440px;
`;

export const PartnerPenaltiesForCarShowPhotosModalDialog: React.FunctionComponent<
  PartnerPenaltiesForCarShowPhotosModalDialogProps
> = props => {
  const closeModal = () => {
    props.setShowPhotosModalOpenState(false);
    props.setPartnerPenaltyState(null);
  };

  return props.isShowPhotosModalOpen ? (
    <ModalTransition>
      <ModalDialog onClose={closeModal} width="x-large" height="450px">
        <DivModalBodyContainer>
          {props.partnerPenalty &&
          is.nonEmptyArray(props.partnerPenalty.imageUrls)
            ? props.partnerPenalty.imageUrls.map((imageUrl, idx) => (
                <ImageContainer
                  src={imageUrl}
                  key={String(idx)}
                  alt={String(idx)}
                />
              ))
            : 'Пусто'}
        </DivModalBodyContainer>
      </ModalDialog>
    </ModalTransition>
  ) : null;
};
