import {RouteComponentProps, Redirect} from '@reach/router';
import React from 'react';

import {AppPageWithViewer} from 'src/containers/AppPageWithViewer';
import {FullscreenErrorMessage} from 'src/components/FullscreenErrorMessage';
import {UserRole} from 'src/types/auth';

interface HomePageParams {}
interface HomePageProps extends RouteComponentProps<HomePageParams> {}

export const HomePage: React.FunctionComponent<HomePageProps> = () => (
  <AppPageWithViewer hideDefaultNavigation>
    {({viewer: currentUser}) => {
      if (!currentUser) {
        return (
          <Redirect to={`/login?continue=${encodeURIComponent('/')}`} noThrow />
        );
      }
      if (
        currentUser.role === UserRole.Superadmin ||
        currentUser.role === UserRole.SupportOperator
      ) {
        return <Redirect to="/admin/payments/penalties" noThrow />;
      }
      if (currentUser.role === UserRole.PartnerInsuranceOperator) {
        return <Redirect to="/partner/insurance-applications" noThrow />;
      }
      if (currentUser.role === UserRole.PartnerCarpark) {
        return <Redirect to="/partner/carpark" noThrow />;
      }

      return (
        <FullscreenErrorMessage title="Произошла ошибка">
          {/* TODO: need a better message */}
          Пожалуйста, обратитесь в службу поддержки OKauto.
        </FullscreenErrorMessage>
      );
    }}
  </AppPageWithViewer>
);
