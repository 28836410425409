import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument, QueryDocument} from 'src/utils/graphql';

import {AllPartnersTable} from './components/AllPartnersTable';

export const ALL_PARTNERS_FOR_ADMIN_QUERY: QueryDocument<{
  allPartners: G.AllPartnersTable_partners | null;
}> = gql`
  query allPartners {
    allPartners {
      ...AllPartnersTable_partners
    }
  }
  ${AllPartnersTable.fragments.partners}
`;

export const EDIT_OR_CREATE_PARTNER_MUTATION: MutationDocument<
  G.partnerEditOrCreate,
  G.partnerEditOrCreateVariables
> = gql`
  mutation partnerEditOrCreate($input: PartnerEditOrCreateInput!) {
    partnerEditOrCreate(input: $input) {
      ...AllPartnersTable_editedOrCreatedPartner
    }
  }
  ${AllPartnersTable.fragments.editOrCreatedPartnerCar}
`;
