import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';

import {AdminNavigation} from '../navigation';
import {PushFormContainer} from './containers/PushFormContainer';

interface PushPageParams {}
interface PushPageProps extends RouteComponentProps<PushPageParams> {}

export const PushPage: React.FunctionComponent<
  PushPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={AdminNavigation}
    roles={[UserRole.Superadmin]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader>Уведомление</PageHeader>
        <PushFormContainer />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
