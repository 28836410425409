import {gridSize} from '@atlaskit/theme';
import MediaQuery from 'react-responsive';
import React from 'react';
import styled from 'styled-components';

const columnWidth = gridSize() * 8;
const gutter = gridSize() * 2;

const wideContainerWidth = columnWidth * 6 + gutter * 5;
const narrowContainerWidth = columnWidth * 4 + gutter * 3;

const Container = styled.div<{size: 'narrow' | 'wide'}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${gridSize() * 12}px auto;
  width: ${props =>
    props.size === 'narrow' ? narrowContainerWidth : wideContainerWidth}px;
`;

interface FullscreenFormProps {
  children: React.ReactNode;
}

export const FullscreenForm: React.FunctionComponent<
  FullscreenFormProps
> = props => (
  <MediaQuery query="(max-width: 704px)">
    {onMobile => (
      <Container size={onMobile ? 'narrow' : 'wide'}>
        {props.children}
      </Container>
    )}
  </MediaQuery>
);
