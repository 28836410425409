import {colors} from '@atlaskit/theme';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {Query, Mutation} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {
  ALL_SETTINGS_FOR_ADMIN_QUERY,
  SETTINGS_UPDATE_MUTATION,
} from '../graphql';
import {SettingsForm} from '../components/SettingsForm';

interface SettingsFormContainerProps {}

export const SettingsFormContainer: React.FunctionComponent<
  SettingsFormContainerProps
> = () => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Query query={ALL_SETTINGS_FOR_ADMIN_QUERY}>
        {({
          loading: allSettingsLoading,
          error: allSettingsError,
          data: allSettingsData,
        }) => (
          <Mutation
            mutation={SETTINGS_UPDATE_MUTATION}
            onCompleted={() => {
              onAddFlag({
                autoDismiss: true,
                icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
                title: 'Настройки обновлены',
              });
            }}
            onError={err => {
              onAddFlag({
                autoDismiss: true,
                icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
                title: 'Произошла ошибка',
                description: `Не удалось обновить настройки: ${messageForApolloError(
                  err,
                )}`,
              });
            }}
          >
            {settingsUpdate => (
              <SettingsForm
                onPatchSetting={(key, value) =>
                  settingsUpdate({
                    variables: {
                      input: {
                        settingValues: [{key, value}],
                      },
                    },
                  })
                }
                allSettingsLoading={allSettingsLoading}
                allSettingsError={allSettingsError}
                allSettingsData={allSettingsData}
              />
            )}
          </Mutation>
        )}
      </Query>
    )}
  </FlagsConsumer>
);
