import {Redirect} from '@reach/router';
import React from 'react';

import {AppPageWithViewer} from 'src/containers/AppPageWithViewer';
import {G} from 'src/types/graphql';
import {UserRole} from 'src/types/auth';

interface ProtectedAppPageProps {
  children: React.ReactNode;
  location?: Window['location'];
  NavigationComponent?: React.ComponentType<{viewer: G.AppNavigation_viewer}>;
  roles: UserRole[];
}

export const ProtectedAppPage: React.FunctionComponent<
  ProtectedAppPageProps
> = props => (
  <AppPageWithViewer NavigationComponent={props.NavigationComponent}>
    {({viewer: currentUser}) => {
      if (!currentUser) {
        return (
          <Redirect
            to={`/login?continue=${encodeURIComponent(
              props.location
                ? `${props.location.pathname}${props.location.search}`
                : '/',
            )}`}
            noThrow
          />
        );
      }
      if (!props.roles.includes(currentUser.role as UserRole)) {
        return <Redirect to="/" noThrow />;
      }

      return props.children;
    }}
  </AppPageWithViewer>
);
