import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument} from 'src/utils/graphql';

export const PUSH_SEND_MUTATION: MutationDocument<
  G.pushNotificationSend,
  G.pushNotificationSendVariables
> = gql`
  mutation pushNotificationSend($input: PushNotificationSendInput!) {
    pushNotificationSend(input: $input) {
      notifications {
        id
      }
    }
  }
`;
