import Flag, {AutoDismissFlag, FlagGroup} from '@atlaskit/flag';
import React from 'react';

import {Omit} from 'shared/ts-utils';

interface FlagData {
  id: number | string;
  appearance?: 'info' | 'normal' | 'success' | 'warning' | 'error';
  autoDismiss?: boolean;
  icon: React.ReactElement;
  title: string;
  description?: React.ReactNode;
  actions?: {content: React.ReactNode; onClick: () => any}[];
}

export const FlagsContext = React.createContext(
  (() => {
    let flags: FlagData[] = [];
    let flagCount = 0;
    const onAddFlag = (flag: Omit<FlagData, 'id'>) => {
      flags = [...flags, {...flag, id: flagCount}];
      flagCount += 1;
    };

    return {
      onAddFlag,
    };
  })(),
);

interface FlagsProviderProps {
  children: React.ReactNode;
}
interface FlagsProviderState {
  flags: FlagData[];
  flagCount: number;
}

export class FlagsProvider extends React.Component<
  FlagsProviderProps,
  FlagsProviderState
> {
  public state: FlagsProviderState = {
    flags: [],
    flagCount: 0,
  };

  // eslint-disable-next-line react/sort-comp
  private handleAddFlag = (flag: Omit<FlagData, 'id'>) => {
    this.setState(prevState => ({
      flags: [{...flag, id: prevState.flagCount}, ...prevState.flags],
      flagCount: prevState.flagCount + 1,
    }));
  };

  private contextValue = {
    onAddFlag: this.handleAddFlag,
  };

  private handleDismissFlag = () => {
    this.setState(prevState => ({
      flags: prevState.flags.slice(1),
    }));
  };

  public render() {
    return (
      <>
        <FlagGroup onDismissed={this.handleDismissFlag}>
          {this.state.flags.map(({autoDismiss, ...flagProps}) => {
            const FlagComponent = autoDismiss ? AutoDismissFlag : Flag;

            return (
              <FlagComponent
                {...flagProps}
                key={flagProps.id}
                actions={
                  !flagProps.appearance || flagProps.appearance === 'normal'
                    ? flagProps.actions
                    : [
                        ...(flagProps.actions || []),
                        {content: 'ОК', onClick: this.handleDismissFlag},
                      ]
                }
              />
            );
          })}
        </FlagGroup>
        <FlagsContext.Provider value={this.contextValue}>
          {this.props.children}
        </FlagsContext.Provider>
      </>
    );
  }
}

export const FlagsConsumer = FlagsContext.Consumer;
