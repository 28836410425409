import React from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface FullscreenSpinnerProps {}

export const FullscreenSpinner: React.FunctionComponent<
  FullscreenSpinnerProps
> = () => (
  <SpinnerWrapper>
    <Spinner size="xlarge" />
  </SpinnerWrapper>
);
