import PageHeader from '@atlaskit/page-header';
import React from 'react';
import qs from 'query-string';

import {G} from 'src/types/graphql';
import {Query} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';

import {PARTNER_QUERY} from '../graphql';
import {PartnerPenaltiesForCarMarkDepositButtonContainer} from './PartnerPenaltiesForCarMarkDepositButtonContainer';
import {PartnerPenaltiesForCarTableContainer} from './PartnerPenaltiesForCarTableContainer';

interface PartnerPenaltiesForCarTableContainerProps {
  location: Window['location'] | undefined;
}
interface PartnerPenaltiesForCarContainerState {
  selectedPenaltyIds: string[];
}

export class PartnerPenaltiesForCarContainer extends React.Component<
  PartnerPenaltiesForCarTableContainerProps,
  PartnerPenaltiesForCarContainerState
> {
  public state: PartnerPenaltiesForCarContainerState = {
    selectedPenaltyIds: [],
  };
  public render() {
    return (
      <Query query={PARTNER_QUERY} fetchPolicy="cache-and-network">
        {({data}) =>
          (data && data.partner && (
            <>
              <PageHeader
                actions={
                  data.partner.payMode === G.PartnerPayMode.DEPOSIT_MANUAL && (
                    <PartnerPenaltiesForCarMarkDepositButtonContainer
                      onMarkDeposit={() =>
                        this.setState({selectedPenaltyIds: []})
                      }
                      selectedPenaltyIds={this.state.selectedPenaltyIds}
                    />
                  )
                }
              >
                {`Штрафы по автомобилю: ${
                  this.props.location &&
                  !is.nullOrUndefined(
                    qs.parse(this.props.location.search).carNumber,
                  )
                    ? qs.parse(this.props.location.search).carNumber
                    : '-'
                }`}
              </PageHeader>
              <PartnerPenaltiesForCarTableContainer
                location={this.props.location}
                selectedPenaltyIds={this.state.selectedPenaltyIds}
                onCheckboxChange={penaltyId => {
                  this.setState(prevState => {
                    const newSelectedPenaltyIds = prevState.selectedPenaltyIds.includes(
                      penaltyId,
                    )
                      ? prevState.selectedPenaltyIds.filter(
                          selectedPenaltyId => selectedPenaltyId !== penaltyId,
                        )
                      : [...prevState.selectedPenaltyIds, penaltyId];

                    return {
                      selectedPenaltyIds: newSelectedPenaltyIds,
                    };
                  });
                }}
                partner={data.partner}
              />
            </>
          )) || <></>
        }
      </Query>
    );
  }
}
