import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {FullscreenErrorMessage} from 'src/components/FullscreenErrorMessage';
import {FullscreenSpinner} from 'src/components/FullscreenSpinner';
import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {Query} from 'src/utils/react-apollo';
import {UserRole} from 'src/types/auth';
import {capitalize} from 'shared/utils/string';
import {messageForApolloError} from 'src/apollo';

import {AllDriversContainer} from './containers/AllDriversContainer';
import {PARTNER_QUERY} from './graphql';
import {PartnerCarparkNavigation} from '../navigation';

interface AllDriversPageParams {}
interface AllDriversPageProps
  extends RouteComponentProps<AllDriversPageParams> {}

export const AllDriversPage: React.FunctionComponent<
  AllDriversPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={PartnerCarparkNavigation}
    roles={[UserRole.PartnerCarpark]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <Query query={PARTNER_QUERY} fetchPolicy="cache-and-network">
          {({loading, error, data}) => {
            if (loading) {
              return <FullscreenSpinner />;
            }
            if (error) {
              return (
                <FullscreenErrorMessage title="Произошла ошибка">
                  {messageForApolloError(error)}
                </FullscreenErrorMessage>
              );
            }

            return (
              <PageHeader>{`Мои водители${
                data && data.partner
                  ? `: ${capitalize(data.partner.name)}, ${
                      data.partner.driversCount
                    }`
                  : ''
              }`}</PageHeader>
            );
          }}
        </Query>

        <AllDriversContainer location={props.location} />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
