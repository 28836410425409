import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument} from 'src/utils/graphql';

export const LOG_OUT_MUTATION: MutationDocument<G.logOut> = gql`
  mutation logOut {
    logOut {
      hubUser {
        id
      }
    }
  }
`;
