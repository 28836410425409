import React from 'react';
import styled from 'styled-components';

const TableHeader = styled.thead`
  margin-bottom: 24px;
`;
const TableHead = styled.th``;
const Span = styled.span`
  color: rgb(94, 108, 132);
  font-size: 12px;
  font-weight: 600;
`;

export const AllFaqsTableHeader = () => (
  <TableHeader>
    <tr id="headerRow">
      <TableHead id="title">
        <Span>Заголовок</Span>
      </TableHead>
      <TableHead id="positiveVoices">
        <Span>Положительные голоса</Span>
      </TableHead>
      <TableHead id="negativeVoices">
        <Span>Отрицательные голоса</Span>
      </TableHead>
      <TableHead id="createdAt">
        <Span>Создано</Span>
      </TableHead>
      <TableHead id="actual">
        <Span>Актуально</Span>
      </TableHead>
      <TableHead id="dropdown" />
    </tr>
  </TableHeader>
);
