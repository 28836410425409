import React from 'react';
import TableIcon from '@atlaskit/icon/glyph/table';

import {AppNavigation} from 'src/containers/AppNavigation';
import {G} from 'src/types/graphql';

interface PartnerInsuranceNavigationProps {
  viewer: G.AppNavigation_viewer;
}

export const PartnerInsuranceNavigation: React.FunctionComponent<
  PartnerInsuranceNavigationProps
> = props => (
  <AppNavigation
    viewer={props.viewer}
    containerTitle="OKauto – Страхование"
    containerLinks={[['/partner/insurance-applications', 'Заявки', TableIcon]]}
  />
);
