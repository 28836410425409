import React from 'react';
import Tabs from '@atlaskit/tabs';
import debounce from 'lodash.debounce';
import qs from 'query-string';

import {FAQ_CATEGORY_LABEL} from 'src/utils/constants';
import {G} from 'src/types/graphql';
import {is, Opt} from 'shared/utils/data-types';
import {updateCurrentLocationQuery} from 'src/utils/location';

import {AllFaqsSearchForm} from '../components/AllFaqsSearchForm';
import {AllFaqsTableContainer} from './AllFaqsTableContainer';

const FAQ_CATEGORY_TO_TAB_LABEL = {
  ALL: 'Все',
  ...FAQ_CATEGORY_LABEL,
};
const FAQ_CATEGORY_FOR_TABS: (G.FaqCategory | 'ALL')[] = [
  'ALL',
  G.FaqCategory.PENALTY,
  G.FaqCategory.PAYMENT,
  G.FaqCategory.INSURANCE,
  G.FaqCategory.TECHSERVICE,
];
const SEARCH_DEBOUNCE_DELAY = 350;

interface AllFaqsContainerProps {
  location: Window['location'] | undefined;
}
interface AllFaqsContainerState {
  location?: Window['location'];
  searchText: string;
  debouncedSearchText: string;
  faqId?: string;
  selectedTabIdx: number;
}

export class AllFaqsContainer extends React.Component<
  AllFaqsContainerProps,
  AllFaqsContainerState
> {
  public state: AllFaqsContainerState = {
    searchText: '',
    debouncedSearchText: '',
    selectedTabIdx: 0,
  };
  public static getDerivedStateFromProps(
    props: AllFaqsContainerProps,
    state: AllFaqsContainerState,
  ) {
    if (props.location && props.location !== state.location) {
      const locationQuery: {
        searchText?: string;
        category?: G.FaqCategory;
      } & {[key: string]: any} = qs.parse(props.location.search);

      const searchText = Opt.nonEmptyString(locationQuery.searchText).getOrElse(
        '',
      );
      const selectedTabIdx =
        is.nonEmptyString(locationQuery.category) &&
        Object.values(G.FaqCategory).includes(locationQuery.category)
          ? FAQ_CATEGORY_FOR_TABS.indexOf(locationQuery.category)
          : 0;

      return {
        location: props.location,
        searchText,
        debouncedSearchText: searchText,
        selectedTabIdx,
      };
    }

    return null;
  }

  public componentDidUpdate(
    _prevProps: AllFaqsContainerProps,
    prevState: AllFaqsContainerState,
  ) {
    if (
      this.state.selectedTabIdx !== prevState.selectedTabIdx ||
      this.state.debouncedSearchText !== prevState.debouncedSearchText
    ) {
      updateCurrentLocationQuery({
        searchText: Opt.nonEmptyString(
          this.state.debouncedSearchText,
        ).toUndefined(),
        category: FAQ_CATEGORY_FOR_TABS[this.state.selectedTabIdx],
      });
    }
    if (this.state.searchText !== prevState.searchText) {
      this.handleSetDebouncedSearchText(this.state.searchText);
    }
  }

  private handleSetDebouncedSearchText = debounce(searchText => {
    this.setState({debouncedSearchText: searchText});
  }, SEARCH_DEBOUNCE_DELAY);

  public render() {
    return (
      <>
        <AllFaqsSearchForm
          searchText={this.state.searchText}
          onSetSearchText={searchText => {
            this.setState({searchText});
          }}
        />
        <Tabs
          selected={this.state.selectedTabIdx}
          tabs={FAQ_CATEGORY_FOR_TABS.map(category => ({
            label: FAQ_CATEGORY_TO_TAB_LABEL[category],
            content: (
              <AllFaqsTableContainer
                category={category}
                debouncedSearchText={this.state.debouncedSearchText}
              />
            ),
          }))}
          onSelect={(
            _data: {label: string; content: React.ReactNode},
            tabIdx: number,
          ) => {
            this.setState({selectedTabIdx: tabIdx});
          }}
        />
      </>
    );
  }
}
