import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // eslint-disable-line import/no-internal-modules
import {ApolloError} from 'apollo-client';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {FieldTextStateless} from '@atlaskit/field-text';
import {Formik, FormikErrors} from 'formik';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import Button from '@atlaskit/button';
import Form, {FormHeader, FormSection, FormFooter} from '@atlaskit/form';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import gql from 'graphql-tag';

import {FAQ_CATEGORY_LABEL} from 'src/utils/constants';
import {FormField} from 'src/components/FormField';
import {G} from 'src/types/graphql';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {
  FormContainer,
  FaqTitleFieldContainer,
} from '../../../../components/styled';

interface FaqData {
  title: string;
  markdownContent: string;
  category: G.FaqCategory;
}

interface FaqCreateOrUpdateFormProps {
  onFaqCreateOrUpdate: (data: FaqData) => void;
  onCancel: () => void;
  loading: boolean;
  error?: ApolloError;
  faq?: G.faqForUpdate_faq;
}

interface FaqCreateOrUpdateFormState {
  title: string;
  editorState: EditorState;
}

export class FaqCreateOrUpdateForm extends React.Component<
  FaqCreateOrUpdateFormProps,
  FaqCreateOrUpdateFormState
> {
  public static fragments = {
    faq: gql`
      fragment FaqCreateOrUpdateForm_faq on Faq {
        id
        createdAt
        title
        markdownContent
        category
      }
    `,
  };

  public state = {
    category: this.props.faq ? this.props.faq.category : G.FaqCategory.PENALTY,
    title: this.props.faq ? this.props.faq.title : '',
    editorState: this.props.faq
      ? EditorState.createWithContent(
          convertFromRaw(markdownToDraft(this.props.faq.markdownContent)),
        )
      : EditorState.createEmpty(),
  };

  public render() {
    return (
      <Formik
        initialValues={{
          title: this.state.title,
          editorState: this.state.editorState,
          category: this.state.category,
        }}
        validate={values => {
          const mutableErrors: FormikErrors<typeof values> = {};
          if (!is.nonEmptyString(values.title)) {
            mutableErrors.title = 'Пожалуйста, введите заголовок';
          }
          if (
            !is.nonEmptyString(
              draftToMarkdown(
                convertToRaw(values.editorState.getCurrentContent()),
              ),
            )
          ) {
            mutableErrors.editorState = 'Пожалуйста, введите ответ';
          }

          return mutableErrors;
        }}
        onSubmit={values => {
          this.props.onFaqCreateOrUpdate({
            title: values.title,
            markdownContent: draftToMarkdown(
              convertToRaw(values.editorState.getCurrentContent()),
            ),
            category: values.category,
          });
        }}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => (
          <FormContainer>
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <FormHeader title="Редактировать FAQ" />
              <FormSection>
                {this.props.error ? (
                  <SectionMessage appearance="error">
                    {messageForApolloError(this.props.error)}
                  </SectionMessage>
                ) : (
                  // HACK: `form` prop is injected, so can't be just null here
                  <div />
                )}
                <FormField
                  label="Категория"
                  inputId="input-category"
                  inputRequired
                  invalid={
                    touched.category && is.nonEmptyString(errors.category)
                  }
                  invalidMessage={errors.category}
                >
                  <Select
                    id="input-category"
                    name="category"
                    value={
                      is.nonEmptyString(values.category)
                        ? {
                            label: FAQ_CATEGORY_LABEL[values.category],
                            value: values.category,
                          }
                        : null
                    }
                    onChange={({
                      value,
                    }: {
                      label: string;
                      value: G.FaqCategory;
                    }) => {
                      setFieldValue('category', value);
                    }}
                    onBlur={handleBlur}
                    placeholder="Выберите категорию"
                    options={Object.values(G.FaqCategory).map(
                      (category: G.FaqCategory) => ({
                        label: FAQ_CATEGORY_LABEL[category],
                        value: category,
                      }),
                    )}
                  />
                </FormField>
                <FormField
                  label="Вопрос"
                  inputId="input-title"
                  inputRequired
                  invalid={touched.title && Boolean(errors.title)}
                  invalidMessage={errors.title}
                >
                  <FaqTitleFieldContainer>
                    <FieldTextStateless
                      id="input-title"
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Введите заголовок"
                      value={values.title}
                      required
                      isLabelHidden
                      shouldFitContainer
                    />
                  </FaqTitleFieldContainer>
                </FormField>

                <Editor
                  editorState={values.editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  placeholder="Введите ответ"
                  onEditorStateChange={editorState =>
                    setFieldValue('editorState', editorState)
                  }
                  editorStyle={{
                    border: '2px solid #DFE1E6',
                    borderRadius: '3px',
                    padding: '6px 5px 0',
                    fontFamily: 'inherit',
                  }}
                />
              </FormSection>
              <FormFooter>
                <Button
                  appearance="primary"
                  type="submit"
                  isDisabled={this.props.loading}
                  isLoading={this.props.loading}
                >
                  Сохранить
                </Button>
                <Button
                  appearance="link"
                  type="button"
                  isDisabled={this.props.loading}
                  onClick={this.props.onCancel}
                >
                  Отмена
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        )}
      </Formik>
    );
  }
}
