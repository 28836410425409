import React from 'react';

import {Mutation} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';

import {HUB_USER_CREATE_MUTATION} from '../graphql';
import {HubUserCreateForm} from '../components/HubUserCreateForm';

interface HubUserCreateFormContainerProps {
  onHubUserCreated: () => void;
  onCancel: () => void;
}

export const HubUserCreateFormContainer: React.FunctionComponent<
  HubUserCreateFormContainerProps
> = props => (
  <Mutation
    mutation={HUB_USER_CREATE_MUTATION}
    refetchQueries={['allHubUsersForAdmin']}
    onCompleted={props.onHubUserCreated}
    // provide a noop here, otherwise an unhandled error is thrown
    onError={() => {}}
  >
    {(
      hubUserCreate,
      {loading: hubUserCreateLoading, error: hubUserCreateLoadingEror},
    ) => (
      <HubUserCreateForm
        onHubUserCreate={data =>
          hubUserCreate({
            variables: {
              input: {
                role: data.role,
                username: data.username,
                password: data.password,
                insuranceCompany: is.nonEmptyString(data.insuranceCompany)
                  ? data.insuranceCompany
                  : null,
                partnerName: is.nonEmptyString(data.partnerName)
                  ? data.partnerName
                  : null,
              },
            },
          })
        }
        onCancel={props.onCancel}
        loading={hubUserCreateLoading}
        error={hubUserCreateLoadingEror}
      />
    )}
  </Mutation>
);
