import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {SettingsForm} from './components/SettingsForm';

export const ALL_SETTINGS_FOR_ADMIN_QUERY: QueryDocument<
  G.allSettingsForAdmin
> = gql`
  query allSettingsForAdmin {
    allSettings {
      ...SettingsForm_setting
    }
  }
  ${SettingsForm.fragments.setting}
`;

export const SETTINGS_UPDATE_MUTATION: MutationDocument<
  G.settingsUpdate,
  G.settingsUpdateVariables
> = gql`
  mutation settingsUpdate($input: SettingsUpdateInput!) {
    settingsUpdate(input: $input) {
      settings {
        ...SettingsForm_setting
      }
    }
  }
  ${SettingsForm.fragments.setting}
`;
