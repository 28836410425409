import {ApolloError} from 'apollo-client';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {TableContainer} from 'src/components/styled';
import {messageForApolloError} from 'src/apollo';

import {AllFaqsDragAndDropTableBody} from './AllFaqsDragAndDropTableBody';
import {AllFaqsTableHeader} from './AllFaqsTableHeader';
import {UPDATE_FAQ_WEIGHT_MUTATION} from '../graphql';

interface AllFaqsDragAndDropTableProps {
  debouncedSearchText: string;
  loading: boolean;
  error?: ApolloError;
  data?: G.allFaqsForAdmin;
  MoreCellComponent: React.ComponentType<{faq: G.AllFaqsTable_faq}>;
}

const SectionMessageContainer = styled.div`
  padding: 10px;
  width: 50%;
  margin: auto;
  text-align: center;
  border-radius: 3px;
  margin-top: 24px;
`;

export class AllFaqsDragAndDropTable extends React.Component<
  AllFaqsDragAndDropTableProps
> {
  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <Mutation
          refetchQueries={['allFaqsForAdmin']}
          mutation={UPDATE_FAQ_WEIGHT_MUTATION}
          // provide a noop here, otherwise an unhandled error is thrown
          onError={() => {}}
        >
          {faqUpdate => (
            <>
              <table>
                <AllFaqsTableHeader />
                <AllFaqsDragAndDropTableBody
                  faqUpdate={faqUpdate}
                  data={this.props.data}
                />
              </table>
              {(this.props.data &&
                this.props.data.allFaqs &&
                this.props.data.allFaqs.edges &&
                this.props.data.allFaqs.edges.length !== 0) ||
              this.props.loading ? (
                <></>
              ) : (
                <SectionMessageContainer>
                  <SectionMessage
                    appearance={this.props.error ? 'error' : 'warning'}
                  >
                    {this.props.error
                      ? messageForApolloError(this.props.error)
                      : 'Ничего не найдено'}
                  </SectionMessage>
                </SectionMessageContainer>
              )}
            </>
          )}
        </Mutation>
      </TableContainer>
    );
  }
}
