import {DateTime} from 'luxon';

import {G} from 'src/types/graphql';

export const formatIsoDate = (isoDateString: string) =>
  DateTime.fromFormat(isoDateString, 'yyyy-MM-dd')
    .setLocale('ru')
    .toLocaleString(DateTime.DATE_MED);

export const formatIsoDateTime = (isoDateString: string) =>
  DateTime.fromISO(isoDateString)
    .setZone('Asia/Almaty')
    .setLocale('ru')
    .toLocaleString(DateTime.DATETIME_SHORT);

export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.length === 11
    ? // prettier-ignore
      `+${phoneNumber[0]} (${phoneNumber.substring(1, 4)}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7, 9)}-${phoneNumber.substring(9, 11)}`
    : phoneNumber;

export const formatCurrencyAmount = (
  amount: number,
  {currency}: {currency: G.Currency} = {currency: G.Currency.KZT},
) => {
  const numberFormat = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: currency.toUpperCase(),
  });

  return numberFormat.format(amount).replace('KZT', '₸');
};
