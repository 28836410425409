import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {AllHubUsersTable} from './components/AllHubUsersTable';

export const ALL_HUB_USERS_FOR_ADMIN_QUERY: QueryDocument<
  G.allHubUsersForAdmin,
  G.allHubUsersForAdminVariables
> = gql`
  query allHubUsersForAdmin($after: String, $first: Int) {
    allHubUsers(after: $after, first: $first) {
      edges {
        node {
          ...AllHubUsersTable_user
        }
      }
      totalPageCount
    }
  }
  ${AllHubUsersTable.fragments.user}
`;

export const HUB_USER_UPDATE_MUTATION: MutationDocument<
  G.hubUserUpdate,
  G.hubUserUpdateVariables
> = gql`
  mutation hubUserUpdate($input: HubUserUpdateInput!) {
    hubUserUpdate(input: $input) {
      hubUser {
        ...AllHubUsersTable_user
      }
    }
  }
  ${AllHubUsersTable.fragments.user}
`;
