import {colors} from '@atlaskit/theme';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {fromGlobalId} from 'src/utils/graphql-relay';
import {messageForApolloError} from 'src/apollo';

import {AllPenaltyPaymentsTable} from '../components/AllPenaltyPaymentsTable';
import {MoreDropdownMenu} from '../components/MoreDropdownMenu';
import {PENALTY_PAYMENT_REFUND_MUTATION} from '../graphql';

interface PenaltyPaymentDropdownMenuContainerProps {
  direction: G.PaymentDirection;
  payment: G.allPenaltyPaymentsForAdmin_allPayments_edges_node;
}

export const PenaltyPaymentDropdownMenuContainer: React.FunctionComponent<
  PenaltyPaymentDropdownMenuContainerProps
> = props => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Mutation
        mutation={PENALTY_PAYMENT_REFUND_MUTATION}
        update={(cache, {data}) => {
          if (data && data.paymentRefund) {
            cache.writeFragment<
              G.allPenaltyPaymentsForAdmin_allPayments_edges_node
            >({
              id: props.payment.id,
              fragment: AllPenaltyPaymentsTable.fragments.payment,
              data: {
                ...props.payment,
                status: G.PaymentStatus.REFUNDED,
              },
            });
          }
        }}
        onCompleted={() => {
          onAddFlag({
            autoDismiss: true,
            icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
            title: `Возврат платежа №${
              fromGlobalId(props.payment.id).id
            } успешно выполнен`,
          });
        }}
        onError={err => {
          onAddFlag({
            autoDismiss: true,
            icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
            title: 'Произошла ошибка',
            description: `Не удалось вернуть платеж №${
              fromGlobalId(props.payment.id).id
            }: ${messageForApolloError(err)}`,
          });
        }}
      >
        {(penaltyPaymentRefund, {loading: updatingPayment}) => (
          <MoreDropdownMenu
            paymentId={props.payment.id}
            status={props.payment.status}
            direction={props.direction}
            onPenaltyPaymentRefund={penaltyPaymentRefund}
            loading={updatingPayment}
          />
        )}
      </Mutation>
    )}
  </FlagsConsumer>
);
