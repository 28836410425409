import qs from 'query-string';

export const updateCurrentLocationQuery = (updateObj: {
  [key: string]: unknown;
}) => {
  window.history.pushState(
    null,
    '',
    `${window.location.pathname}?${qs.stringify({
      ...qs.parse(window.location.search),
      ...updateObj,
    })}`,
  );
};
