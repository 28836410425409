import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';

import {AllInsuranceApplicationsTableContainer} from './containers/AllInsuranceApplicationsTableContainer';
import {PartnerInsuranceNavigation} from '../navigation';

interface AllInsuranceApplicationsPageParams {}
interface AllInsuranceApplicationsPageProps
  extends RouteComponentProps<AllInsuranceApplicationsPageParams> {}

export const AllInsuranceApplicationsPage: React.FunctionComponent<
  AllInsuranceApplicationsPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={PartnerInsuranceNavigation}
    roles={[UserRole.Superadmin, UserRole.PartnerInsuranceOperator]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader>Полученные заявки</PageHeader>
        <AllInsuranceApplicationsTableContainer />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
