import React from 'react';
import qs from 'query-string';

import {Subscription} from 'src/utils/react-apollo';
import {is} from 'shared/utils/data-types';
import {updateCurrentLocationQuery} from 'src/utils/location';

import {PenaltyCheck} from '../components/PenaltyCheck';
import {PenaltyCheckQuery} from '../types';
import {RUN_PENALTY_CHECK_SUBSCRIPTION} from '../graphql';

interface PenaltyCheckContainerProps {
  location: Window['location'] | undefined;
}
interface PenaltyCheckContainerState {
  location?: Window['location'];
  checkQuery: PenaltyCheckQuery;
}

export class PenaltyCheckContainer extends React.Component<
  PenaltyCheckContainerProps,
  PenaltyCheckContainerState
> {
  public state: PenaltyCheckContainerState = {
    checkQuery: {},
  };

  public static getDerivedStateFromProps(
    props: PenaltyCheckContainerProps,
    state: PenaltyCheckContainerState,
  ) {
    if (props.location && props.location !== state.location) {
      const locationQuery: PenaltyCheckQuery & {
        [key: string]: unknown;
      } = qs.parse(props.location.search);

      return {
        location: props.location,
        checkQuery: {
          ...(is.nonEmptyString(locationQuery.iinNumber) && {
            iinNumber: locationQuery.iinNumber,
          }),
          ...(is.nonEmptyString(locationQuery.carNumber) && {
            carNumber: locationQuery.carNumber,
          }),
          ...(is.nonEmptyString(locationQuery.carTechPassport) && {
            carTechPassport: locationQuery.carTechPassport,
          }),
        },
      };
    }

    return null;
  }

  public componentDidUpdate() {
    updateCurrentLocationQuery(this.state.checkQuery);
  }

  public render() {
    return is.nonEmptyString(this.state.checkQuery.iinNumber) ||
      (is.nonEmptyString(this.state.checkQuery.carNumber) &&
        is.nonEmptyString(this.state.checkQuery.carTechPassport)) ? (
      <Subscription
        subscription={RUN_PENALTY_CHECK_SUBSCRIPTION}
        variables={{
          input: {
            query: this.state.checkQuery,
          },
        }}
      >
        {({loading, error, data}) => (
          <PenaltyCheck
            checkQuery={this.state.checkQuery}
            onSetCheckQuery={checkQuery => {
              this.setState({checkQuery});
            }}
            loading={loading}
            error={error}
            data={data}
          />
        )}
      </Subscription>
    ) : (
      <PenaltyCheck
        checkQuery={this.state.checkQuery}
        onSetCheckQuery={checkQuery => {
          this.setState({checkQuery});
        }}
        loading={false}
        error={undefined}
        data={undefined}
      />
    );
  }
}
