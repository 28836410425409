import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {formatIsoDateTime} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

import {PAY_MODES_TO_SELECT_MAP} from '../containers/PartnerEditOrCreateModalDialog';

interface AllPartnersTableProps {
  loading: boolean;
  error?: ApolloError;
  MoreCellComponent: React.ComponentType<{
    partner: G.AllPartnersTable_partners_edges_node;
  }>;
  data?: G.AllPartnersTable_partners | null;
}

const PAY_MODES_TO_APPEARANCE_MAP = {
  [G.PartnerPayMode.DISABLED]: 'removed',
  [G.PartnerPayMode.DEPOSIT_MANUAL]: 'inprogress',
  [G.PartnerPayMode.DEPOSIT_AUTO]: 'inprogress',
  [G.PartnerPayMode.CARD]: 'success',
};

export class AllPartnersTable extends React.Component<AllPartnersTableProps> {
  public static fragments = {
    partners: gql`
      fragment AllPartnersTable_partners on PartnerConnection {
        edges {
          node {
            id
            name
            carsCount
            carLimit
            email
            createdAt
            binNumber
            registrationDate
            penaltyRelevanceDateThreshold
            ccEmails
            active
            aisbddParsingEnabled
            slackToken
            slackAisbddPenaltiesConversationId
            payMode
            blackParsingEnabled
            blueParsingEnabled
            individualEntrepreneur
            identityCard {
              number
              iinNumber
            }
          }
        }
      }
    `,
    editOrCreatedPartnerCar: gql`
      fragment AllPartnersTable_editedOrCreatedPartner on PartnerEditOrCreatePayload {
        partner {
          id
        }
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: [
              {
                key: 'name',
                content: 'Партнер',
              },
              {
                key: 'carsCount',
                content: 'Кол.во авто',
              },
              {
                key: 'carLimit',
                content: 'Лимит машин',
              },
              {
                key: 'penaltyRelevanceDateThreshold',
                content: 'Штрафы актуальны с',
              },
              {
                key: 'createdAt',
                content: 'Создано',
              },
              {
                key: 'email',
                content: 'Email',
              },
              {
                key: 'binNumber',
                content: 'БИН номер',
              },
              {
                key: 'registrationDate',
                content: 'Дата регистрации',
              },
              {
                key: 'ccEmails',
                content: 'Вторичные получатели писем',
              },
              {
                key: 'payMode',
                content: 'Способ оплаты штрафов',
              },
              {
                key: 'aisbddParsingEnabled',
                content: 'Активирован парсинг с AИСБДД',
              },
              {
                key: 'blackParsingEnabled',
                content: 'Активирован черный парсинг',
              },
              {
                key: 'blueParsingEnabled',
                content: 'Активирован синий парсинг',
              },
              {
                key: 'active',
                content: 'Активирован',
              },
              {
                key: 'more',
              },
            ],
          }}
          rows={
            this.props.data
              ? nodesFromConnection(this.props.data).map(partner => ({
                  key: partner.id,
                  cells: [
                    {
                      key: partner.name,
                      content: partner.name,
                    },
                    {
                      key: partner.carsCount,
                      content: partner.carsCount,
                    },
                    {
                      key: partner.carLimit,
                      content: is.positiveInt(partner.carLimit)
                        ? partner.carLimit
                        : '-',
                    },
                    {
                      key: partner.penaltyRelevanceDateThreshold,
                      content: is.nonEmptyString(
                        partner.penaltyRelevanceDateThreshold,
                      )
                        ? formatIsoDateTime(
                            partner.penaltyRelevanceDateThreshold,
                          )
                        : '-',
                    },
                    {
                      key: partner.createdAt,
                      content: formatIsoDateTime(partner.createdAt),
                    },
                    {
                      key: partner.email,
                      content: is.nonEmptyString(partner.email)
                        ? partner.email
                        : '-',
                    },
                    {
                      key: partner.binNumber,
                      content: is.nonEmptyString(partner.binNumber)
                        ? partner.binNumber
                        : '-',
                    },
                    {
                      key: partner.registrationDate,
                      content: is.nonEmptyString(partner.registrationDate)
                        ? partner.registrationDate
                        : '-',
                    },
                    {
                      key: partner.ccEmails,
                      content: is.nonEmptyArray(partner.ccEmails)
                        ? partner.ccEmails
                        : '-',
                    },
                    {
                      key: partner.payMode,
                      content: (
                        <Lozenge
                          appearance={
                            PAY_MODES_TO_APPEARANCE_MAP[partner.payMode]
                          }
                        >
                          {PAY_MODES_TO_SELECT_MAP[partner.payMode].label}
                        </Lozenge>
                      ),
                    },
                    {
                      key: partner.aisbddParsingEnabled,
                      content: (
                        <Lozenge
                          appearance={
                            partner.aisbddParsingEnabled ? 'success' : 'removed'
                          }
                        >
                          {partner.aisbddParsingEnabled ? 'да' : 'нет'}
                        </Lozenge>
                      ),
                    },
                    {
                      key: partner.blackParsingEnabled,
                      content: (
                        <Lozenge
                          appearance={
                            partner.blackParsingEnabled ? 'success' : 'removed'
                          }
                        >
                          {partner.blackParsingEnabled ? 'да' : 'нет'}
                        </Lozenge>
                      ),
                    },
                    {
                      key: partner.blueParsingEnabled,
                      content: (
                        <Lozenge
                          appearance={
                            partner.blueParsingEnabled ? 'success' : 'removed'
                          }
                        >
                          {partner.blueParsingEnabled ? 'да' : 'нет'}
                        </Lozenge>
                      ),
                    },
                    {
                      key: partner.active,
                      content: (
                        <Lozenge
                          appearance={partner.active ? 'success' : 'removed'}
                        >
                          {partner.active ? 'да' : 'нет'}
                        </Lozenge>
                      ),
                    },
                    {
                      content: (
                        <this.props.MoreCellComponent partner={partner} />
                      ),
                    },
                  ],
                }))
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
      </TableContainer>
    );
  }
}
