import {colors, fontSize, themed} from '@atlaskit/theme';
import FieldBase from '@atlaskit/field-base';
import React from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import styled from 'styled-components';

const getPlaceholderColor = ({disabled}: {disabled?: boolean}) => {
  if (disabled) {
    return themed({light: colors.N70, dark: colors.DN90});
  }

  return themed({light: colors.N100, dark: colors.DN90});
};

const Input = styled.input<{disabled?: boolean}>`
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: ${fontSize}px;
  outline: none;
  width: 100%;

  [disabled] {
    /* Safari puts on some difficult to remove styles, mainly for disabled
       inputs but we want full control so need to override them in all cases */
    -webkit-text-fill-color: unset;
    -webkit-opacity: 1;
  }

  &::-ms-clear {
    display: none;
  }

  &:invalid {
    box-shadow: none;
  }

  /* can't group these placeholder styles into one block because browsers drop
     entire style blocks when any single selector fails to parse */
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${getPlaceholderColor};
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${getPlaceholderColor};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${getPlaceholderColor};
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${getPlaceholderColor};
  }
`;
const FieldBaseWrapper = styled.div`
  flex: 1;
`;
const SearchIconWrapper = styled.div`
  align-items: center;
  color: ${colors.B100};
  display: flex;
  flex-shrink: 0;
  margin-right: 4px;
`;

interface SearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean;
  invalidMessage?: React.ReactNode;
  shouldFitContainer?: boolean;
  isValidationHidden?: boolean;
  innerRef?: (node: HTMLInputElement | null) => void;
}

export class SearchBar extends React.Component<SearchBarProps> {
  private input: HTMLInputElement | null = null;

  private captureInputRef = (input: HTMLInputElement | null) => {
    this.input = input;
    if (this.props.innerRef) {
      this.props.innerRef(input);
    }
  };

  public focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  public render() {
    return (
      <FieldBaseWrapper>
        <FieldBase
          invalidMessage={this.props.invalidMessage}
          isCompact={false}
          isDisabled={this.props.disabled}
          isFitContainerWidthEnabled={this.props.shouldFitContainer}
          isInvalid={this.props.isInvalid}
          isReadOnly={false}
          isRequired={this.props.required}
          isValidationHidden={this.props.isValidationHidden}
        >
          <SearchIconWrapper>
            <SearchIcon label="Поиск" />
          </SearchIconWrapper>
          <Input type="text" innerRef={this.captureInputRef} {...this.props} />
        </FieldBase>
      </FieldBaseWrapper>
    );
  }
}
