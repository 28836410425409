import {gridSize} from '@atlaskit/theme';
import MediaQuery from 'react-responsive';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import styled from 'styled-components';

const columnWidth = gridSize() * 8;
const gutter = gridSize() * 2;

const wideContainerWidth = columnWidth * 6 + gutter * 5;
const narrowContainerWidth = columnWidth * 4 + gutter * 3;

const Container = styled.div<{size: 'narrow' | 'wide'}>`
  margin: ${gridSize() * 12}px auto;
  text-align: center;
  width: ${props =>
    props.size === 'narrow' ? narrowContainerWidth : wideContainerWidth}px;
`;

interface FullscreenErrorMessageProps {
  title?: string;
  children: React.ReactNode;
}

export const FullscreenErrorMessage: React.FunctionComponent<
  FullscreenErrorMessageProps
> = props => (
  <MediaQuery query="(max-width: 704px)">
    {onMobile => (
      <Container size={onMobile ? 'narrow' : 'wide'}>
        <SectionMessage appearance="error" title={props.title}>
          {props.children}
        </SectionMessage>
      </Container>
    )}
  </MediaQuery>
);
