/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logOut
// ====================================================

export interface logOut_logOut_hubUser {
  __typename: "User";
  id: string;
}

export interface logOut_logOut {
  __typename: "LogOutPayload";
  hubUser: logOut_logOut_hubUser | null;
}

export interface logOut {
  logOut: logOut_logOut;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: viewer
// ====================================================

export interface viewer_viewer {
  __typename: "User";
  id: string;
  role: string;
  hubUsername: string | null;
}

export interface viewer {
  viewer: viewer_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: hubUserPasswordChange
// ====================================================

export interface hubUserPasswordChange_hubUserPasswordChange_hubUser {
  __typename: "User";
  id: string;
  hubUsername: string | null;
  role: string;
}

export interface hubUserPasswordChange_hubUserPasswordChange {
  __typename: "HubUserPasswordChangePayload";
  hubUser: hubUserPasswordChange_hubUserPasswordChange_hubUser;
}

export interface hubUserPasswordChange {
  hubUserPasswordChange: hubUserPasswordChange_hubUserPasswordChange;
}

export interface hubUserPasswordChangeVariables {
  input: HubUserPasswordChangeInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allFaqsForAdmin
// ====================================================

export interface allFaqsForAdmin_allFaqs_edges_node {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  voicesNegative: number;
  voicesPositive: number;
  archivedAt: GraphQL_DateTime | null;
}

export interface allFaqsForAdmin_allFaqs_edges {
  __typename: "FaqEdge";
  /**
   * The item at the end of the edge
   */
  node: allFaqsForAdmin_allFaqs_edges_node | null;
}

export interface allFaqsForAdmin_allFaqs {
  __typename: "FaqConnection";
  /**
   * A list of edges.
   */
  edges: (allFaqsForAdmin_allFaqs_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allFaqsForAdmin {
  allFaqs: allFaqsForAdmin_allFaqs | null;
}

export interface allFaqsForAdminVariables {
  after?: string | null;
  first?: number | null;
  searchText?: string | null;
  category?: FaqCategory | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: faqArchiveOrUnarchive
// ====================================================

export interface faqArchiveOrUnarchive_faqArchiveOrUnarchive_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  voicesNegative: number;
  voicesPositive: number;
  archivedAt: GraphQL_DateTime | null;
}

export interface faqArchiveOrUnarchive_faqArchiveOrUnarchive {
  __typename: "FaqArchiveOrUnarchivePayload";
  faq: faqArchiveOrUnarchive_faqArchiveOrUnarchive_faq;
}

export interface faqArchiveOrUnarchive {
  faqArchiveOrUnarchive: faqArchiveOrUnarchive_faqArchiveOrUnarchive | null;
}

export interface faqArchiveOrUnarchiveVariables {
  input: FaqArchiveOrUnarchiveInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: faqUpdateWeight
// ====================================================

export interface faqUpdateWeight_faqUpdateWeight_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  voicesNegative: number;
  voicesPositive: number;
  archivedAt: GraphQL_DateTime | null;
}

export interface faqUpdateWeight_faqUpdateWeight {
  __typename: "FaqUpdateWeightPayload";
  faq: faqUpdateWeight_faqUpdateWeight_faq;
}

export interface faqUpdateWeight {
  faqUpdateWeight: faqUpdateWeight_faqUpdateWeight | null;
}

export interface faqUpdateWeightVariables {
  input: FaqUpdateWeightInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allHubUsersForAdmin
// ====================================================

export interface allHubUsersForAdmin_allHubUsers_edges_node {
  __typename: "User";
  id: string;
  createdAt: GraphQL_DateTime;
  role: string;
  hubUsername: string | null;
  hubAccessible: boolean;
  partnerName: string | null;
}

export interface allHubUsersForAdmin_allHubUsers_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge
   */
  node: allHubUsersForAdmin_allHubUsers_edges_node | null;
}

export interface allHubUsersForAdmin_allHubUsers {
  __typename: "UserConnection";
  /**
   * A list of edges.
   */
  edges: (allHubUsersForAdmin_allHubUsers_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allHubUsersForAdmin {
  allHubUsers: allHubUsersForAdmin_allHubUsers | null;
}

export interface allHubUsersForAdminVariables {
  after?: string | null;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: hubUserUpdate
// ====================================================

export interface hubUserUpdate_hubUserUpdate_hubUser {
  __typename: "User";
  id: string;
  createdAt: GraphQL_DateTime;
  role: string;
  hubUsername: string | null;
  hubAccessible: boolean;
  partnerName: string | null;
}

export interface hubUserUpdate_hubUserUpdate {
  __typename: "HubUserUpdatePayload";
  hubUser: hubUserUpdate_hubUserUpdate_hubUser;
}

export interface hubUserUpdate {
  hubUserUpdate: hubUserUpdate_hubUserUpdate | null;
}

export interface hubUserUpdateVariables {
  input: HubUserUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allPartners
// ====================================================

export interface allPartners_allPartners_edges_node {
  __typename: "Partner";
  id: string;
  name: string;
}

export interface allPartners_allPartners_edges {
  __typename: "PartnerEdge";
  /**
   * The item at the end of the edge
   */
  node: allPartners_allPartners_edges_node | null;
}

export interface allPartners_allPartners {
  __typename: "PartnerConnection";
  /**
   * A list of edges.
   */
  edges: (allPartners_allPartners_edges | null)[] | null;
}

export interface allPartners {
  allPartners: allPartners_allPartners | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerEditOrCreate
// ====================================================

export interface partnerEditOrCreate_partnerEditOrCreate_partner {
  __typename: "Partner";
  id: string;
}

export interface partnerEditOrCreate_partnerEditOrCreate {
  __typename: "PartnerEditOrCreatePayload";
  partner: partnerEditOrCreate_partnerEditOrCreate_partner;
}

export interface partnerEditOrCreate {
  partnerEditOrCreate: partnerEditOrCreate_partnerEditOrCreate | null;
}

export interface partnerEditOrCreateVariables {
  input: PartnerEditOrCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allPenaltyPaymentsForAdmin
// ====================================================

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_user {
  __typename: "User";
  id: string;
  token: string | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_violator {
  __typename: "PenaltyViolator";
  firstName: string | null;
  lastName: string | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_violation {
  __typename: "PenaltyViolation";
  date: GraphQL_Date | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_protocol {
  __typename: "PenaltyProtocol";
  blankNumber: string | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty {
  __typename: "Penalty";
  shekteuAltId: string | null;
  iinNumber: string | null;
  carNumber: string | null;
  carTechPassport: string | null;
  regressing: boolean;
  violator: allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_violator;
  violation: allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_violation;
  protocol: allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty_protocol;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node_items {
  __typename: "PaymentItem";
  penalty: allPenaltyPaymentsForAdmin_allPayments_edges_node_items_penalty | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges_node {
  __typename: "PenaltyPayment" | "AmanatCertificatePayment" | "PenaltyChecksByCarSubscriptionPayment";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  user: allPenaltyPaymentsForAdmin_allPayments_edges_node_user;
  items: allPenaltyPaymentsForAdmin_allPayments_edges_node_items[];
  amountInCents: number;
  feeInCents: number;
  currency: Currency;
  type: PaymentType;
  provider: PaymentProvider;
  status: PaymentStatus;
  paidAmount: number | null;
  paidAt: GraphQL_DateTime | null;
  refundedAt: GraphQL_DateTime | null;
  rawData: GraphQL_JSON;
}

export interface allPenaltyPaymentsForAdmin_allPayments_edges {
  __typename: "PaymentEdge";
  /**
   * The item at the end of the edge
   */
  node: allPenaltyPaymentsForAdmin_allPayments_edges_node | null;
}

export interface allPenaltyPaymentsForAdmin_allPayments {
  __typename: "PaymentConnection";
  /**
   * A list of edges.
   */
  edges: (allPenaltyPaymentsForAdmin_allPayments_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allPenaltyPaymentsForAdmin {
  allPayments: allPenaltyPaymentsForAdmin_allPayments | null;
}

export interface allPenaltyPaymentsForAdminVariables {
  after?: string | null;
  first?: number | null;
  direction: PaymentDirection;
  searchBy: PenaltyPaymentSearchBy;
  searchText?: string | null;
  filters?: PenaltyPaymentFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: restartPenaltyPaymentsForAdmin
// ====================================================

export interface restartPenaltyPaymentsForAdmin_penaltyRepaymentRestart {
  __typename: "PenaltyRepaymentRestartPayload";
  restartedPaymentIds: string[];
}

export interface restartPenaltyPaymentsForAdmin {
  penaltyRepaymentRestart: restartPenaltyPaymentsForAdmin_penaltyRepaymentRestart | null;
}

export interface restartPenaltyPaymentsForAdminVariables {
  input: PenaltyRepaymentRestartInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: penaltyPaymentRefund
// ====================================================

export interface penaltyPaymentRefund_paymentRefund {
  __typename: "PaymentRefundPayload";
  refund: boolean;
}

export interface penaltyPaymentRefund {
  paymentRefund: penaltyPaymentRefund_paymentRefund | null;
}

export interface penaltyPaymentRefundVariables {
  input: PaymentRefundInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: faqCreateOrUpdate
// ====================================================

export interface faqCreateOrUpdate_faqCreateOrUpdate_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  category: FaqCategory;
}

export interface faqCreateOrUpdate_faqCreateOrUpdate {
  __typename: "FaqCreateOrUpdatePayload";
  faq: faqCreateOrUpdate_faqCreateOrUpdate_faq;
}

export interface faqCreateOrUpdate {
  faqCreateOrUpdate: faqCreateOrUpdate_faqCreateOrUpdate | null;
}

export interface faqCreateOrUpdateVariables {
  input: FaqCreateOrUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: faqForUpdate
// ====================================================

export interface faqForUpdate_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  category: FaqCategory;
}

export interface faqForUpdate {
  faq: faqForUpdate_faq | null;
}

export interface faqForUpdateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: hubUserCreate
// ====================================================

export interface hubUserCreate_hubUserCreate_hubUser {
  __typename: "User";
  id: string;
  createdAt: GraphQL_DateTime;
  role: string;
  hubUsername: string | null;
  hubAccessible: boolean;
}

export interface hubUserCreate_hubUserCreate {
  __typename: "HubUserCreatePayload";
  hubUser: hubUserCreate_hubUserCreate_hubUser;
}

export interface hubUserCreate {
  hubUserCreate: hubUserCreate_hubUserCreate | null;
}

export interface hubUserCreateVariables {
  input: HubUserCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: paymentForClientPenaltyCreate
// ====================================================

export interface paymentForClientPenaltyCreate_paymentForClientPenaltyCreate {
  __typename: "PaymentForClientPenaltyCreatePayload";
  formUrl: string | null;
  callbackSuccessUrl: string | null;
  callbackFailUrl: string | null;
}

export interface paymentForClientPenaltyCreate {
  paymentForClientPenaltyCreate: paymentForClientPenaltyCreate_paymentForClientPenaltyCreate | null;
}

export interface paymentForClientPenaltyCreateVariables {
  input: PaymentForClientPenaltyCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: runPenaltyCheck
// ====================================================

export interface runPenaltyCheck_runPenaltyCheck_penalties_violator {
  __typename: "PenaltyViolator";
  firstName: string | null;
  lastName: string | null;
}

export interface runPenaltyCheck_runPenaltyCheck_penalties_violation {
  __typename: "PenaltyViolation";
  date: GraphQL_Date | null;
}

export interface runPenaltyCheck_runPenaltyCheck_penalties_protocol {
  __typename: "PenaltyProtocol";
  blankNumber: string | null;
}

export interface runPenaltyCheck_runPenaltyCheck_penalties_beneficiary {
  __typename: "PenaltyBeneficiary";
  taxOfficeName: string | null;
}

export interface runPenaltyCheck_runPenaltyCheck_penalties {
  __typename: "Penalty";
  id: string;
  mvdId: string | null;
  shekteuAltId: string | null;
  iinNumber: string | null;
  carNumber: string | null;
  carTechPassport: string | null;
  amountInCents: number;
  paidAt: GraphQL_DateTime | null;
  violator: runPenaltyCheck_runPenaltyCheck_penalties_violator;
  violation: runPenaltyCheck_runPenaltyCheck_penalties_violation;
  protocol: runPenaltyCheck_runPenaltyCheck_penalties_protocol;
  beneficiary: runPenaltyCheck_runPenaltyCheck_penalties_beneficiary;
}

export interface runPenaltyCheck_runPenaltyCheck {
  __typename: "RunPenaltyCheckPayload";
  penalties: runPenaltyCheck_runPenaltyCheck_penalties[];
  errorMessage: string | null;
}

export interface runPenaltyCheck {
  runPenaltyCheck: runPenaltyCheck_runPenaltyCheck | null;
}

export interface runPenaltyCheckVariables {
  input: RunPenaltyCheckInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pushNotificationSend
// ====================================================

export interface pushNotificationSend_pushNotificationSend_notifications {
  __typename: "Notification";
  id: string;
}

export interface pushNotificationSend_pushNotificationSend {
  __typename: "PushNotificationSendPayload";
  notifications: pushNotificationSend_pushNotificationSend_notifications[] | null;
}

export interface pushNotificationSend {
  pushNotificationSend: pushNotificationSend_pushNotificationSend | null;
}

export interface pushNotificationSendVariables {
  input: PushNotificationSendInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allSettingsForAdmin
// ====================================================

export interface allSettingsForAdmin_allSettings_value {
  __typename: "SettingValue";
  default: GraphQL_JSON | null;
  ios: GraphQL_JSON | null;
  android: GraphQL_JSON | null;
}

export interface allSettingsForAdmin_allSettings {
  __typename: "Setting";
  id: string;
  key: string;
  value: allSettingsForAdmin_allSettings_value;
}

export interface allSettingsForAdmin {
  allSettings: allSettingsForAdmin_allSettings[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: settingsUpdate
// ====================================================

export interface settingsUpdate_settingsUpdate_settings_value {
  __typename: "SettingValue";
  default: GraphQL_JSON | null;
  ios: GraphQL_JSON | null;
  android: GraphQL_JSON | null;
}

export interface settingsUpdate_settingsUpdate_settings {
  __typename: "Setting";
  id: string;
  key: string;
  value: settingsUpdate_settingsUpdate_settings_value;
}

export interface settingsUpdate_settingsUpdate {
  __typename: "SettingsUpdatePayload";
  settings: settingsUpdate_settingsUpdate_settings[];
}

export interface settingsUpdate {
  settingsUpdate: settingsUpdate_settingsUpdate | null;
}

export interface settingsUpdateVariables {
  input: SettingsUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allPartnerCars
// ====================================================

export interface allPartnerCars_allPartnerCars_edges_node {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
  penaltiesSeenAt: GraphQL_DateTime | null;
  unpaidPenaltiesCount: number;
}

export interface allPartnerCars_allPartnerCars_edges {
  __typename: "PartnerCarEdge";
  /**
   * The item at the end of the edge
   */
  node: allPartnerCars_allPartnerCars_edges_node | null;
}

export interface allPartnerCars_allPartnerCars {
  __typename: "PartnerCarConnection";
  /**
   * A list of edges.
   */
  edges: (allPartnerCars_allPartnerCars_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allPartnerCars {
  allPartnerCars: allPartnerCars_allPartnerCars | null;
}

export interface allPartnerCarsVariables {
  after?: string | null;
  first?: number | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerCarDelete
// ====================================================

export interface partnerCarDelete_partnerCarDelete_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface partnerCarDelete_partnerCarDelete {
  __typename: "PartnerCarDeletePayload";
  partnerCar: partnerCarDelete_partnerCarDelete_partnerCar;
}

export interface partnerCarDelete {
  partnerCarDelete: partnerCarDelete_partnerCarDelete | null;
}

export interface partnerCarDeleteVariables {
  input: PartnerCarDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerCarCreate
// ====================================================

export interface partnerCarCreate_partnerCarCreate_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface partnerCarCreate_partnerCarCreate {
  __typename: "PartnerCarCreatePayload";
  partnerCar: partnerCarCreate_partnerCarCreate_partnerCar;
}

export interface partnerCarCreate {
  partnerCarCreate: partnerCarCreate_partnerCarCreate | null;
}

export interface partnerCarCreateVariables {
  input: PartnerCarCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: partner
// ====================================================

export interface partner_partner {
  __typename: "Partner";
  id: string;
  name: string;
  carsCount: string;
  driversCount: string;
  payMode: PartnerPayMode;
}

export interface partner {
  partner: partner_partner | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: iinNumberInfo
// ====================================================

export interface iinNumberInfo_iinNumberInfo {
  __typename: "IinNumberInfoPayload";
  name: string;
}

export interface iinNumberInfo {
  iinNumberInfo: iinNumberInfo_iinNumberInfo | null;
}

export interface iinNumberInfoVariables {
  iinNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerDriverCreate
// ====================================================

export interface partnerDriverCreate_partnerDriverCreate_partnerDriver {
  __typename: "PartnerDriver";
  id: string;
  name: string;
  iinNumber: string;
}

export interface partnerDriverCreate_partnerDriverCreate {
  __typename: "PartnerDriverCreatePayload";
  partnerDriver: partnerDriverCreate_partnerDriverCreate_partnerDriver;
}

export interface partnerDriverCreate {
  partnerDriverCreate: partnerDriverCreate_partnerDriverCreate | null;
}

export interface partnerDriverCreateVariables {
  input: PartnerDriverCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerDriverDelete
// ====================================================

export interface partnerDriverDelete_partnerDriverDelete_partnerDriver {
  __typename: "PartnerDriver";
  id: string;
}

export interface partnerDriverDelete_partnerDriverDelete {
  __typename: "PartnerDriverDeletePayload";
  partnerDriver: partnerDriverDelete_partnerDriverDelete_partnerDriver;
}

export interface partnerDriverDelete {
  partnerDriverDelete: partnerDriverDelete_partnerDriverDelete | null;
}

export interface partnerDriverDeleteVariables {
  input: PartnerDriverDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allPartnerDrivers
// ====================================================

export interface allPartnerDrivers_allPartnerDrivers_edges_node {
  __typename: "PartnerDriver";
  id: string;
  name: string;
  iinNumber: string;
}

export interface allPartnerDrivers_allPartnerDrivers_edges {
  __typename: "PartnerDriverEdge";
  /**
   * The item at the end of the edge
   */
  node: allPartnerDrivers_allPartnerDrivers_edges_node | null;
}

export interface allPartnerDrivers_allPartnerDrivers {
  __typename: "PartnerDriverConnection";
  /**
   * A list of edges.
   */
  edges: (allPartnerDrivers_allPartnerDrivers_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allPartnerDrivers {
  allPartnerDrivers: allPartnerDrivers_allPartnerDrivers | null;
}

export interface allPartnerDriversVariables {
  after?: string | null;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allPartnerPenaltiesForPartnerCar
// ====================================================

export interface allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node_payment {
  __typename: "PenaltyPayment" | "AmanatCertificatePayment" | "PenaltyChecksByCarSubscriptionPayment";
  id: string;
}

export interface allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node {
  __typename: "PartnerPenalty";
  id: string;
  blankNumber: string | null;
  dateTime: GraphQL_DateTime | null;
  address: string | null;
  description: string | null;
  priceInCents: number;
  penaltyId: string | null;
  status: PartnerPenaltyStatus;
  penaltyShekteuAltId: string | null;
  awaitingPaymentFromDeposit: boolean;
  imageUrls: string[] | null;
  protocolUrl: string | null;
  payment: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node_payment | null;
}

export interface allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges {
  __typename: "PartnerPenaltyEdge";
  /**
   * The item at the end of the edge
   */
  node: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node | null;
}

export interface allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar {
  __typename: "PartnerPenaltyConnection";
  /**
   * A list of edges.
   */
  edges: (allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allPartnerPenaltiesForPartnerCar {
  allPartnerPenaltiesForPartnerCar: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar | null;
}

export interface allPartnerPenaltiesForPartnerCarVariables {
  after?: string | null;
  first?: number | null;
  input: AllPartnerPenaltiesForPartnerCarInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerCarPenaltiesMarkSeen
// ====================================================

export interface partnerCarPenaltiesMarkSeen_partnerCarPenaltiesMarkSeen_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface partnerCarPenaltiesMarkSeen_partnerCarPenaltiesMarkSeen {
  __typename: "PartnerCarPenaltiesMarkSeenPayload";
  partnerCar: partnerCarPenaltiesMarkSeen_partnerCarPenaltiesMarkSeen_partnerCar;
}

export interface partnerCarPenaltiesMarkSeen {
  partnerCarPenaltiesMarkSeen: partnerCarPenaltiesMarkSeen_partnerCarPenaltiesMarkSeen | null;
}

export interface partnerCarPenaltiesMarkSeenVariables {
  input: PartnerCarPenaltiesMarkSeenInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: paymentForPartnerPenaltyCreate
// ====================================================

export interface paymentForPartnerPenaltyCreate_paymentForPartnerPenaltyCreate {
  __typename: "PaymentForPartnerPenaltyCreatePayload";
  formUrl: string | null;
  callbackSuccessUrl: string | null;
  callbackFailUrl: string | null;
}

export interface paymentForPartnerPenaltyCreate {
  paymentForPartnerPenaltyCreate: paymentForPartnerPenaltyCreate_paymentForPartnerPenaltyCreate | null;
}

export interface paymentForPartnerPenaltyCreateVariables {
  input: PaymentForPartnerPenaltyCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: partnerPenaltiesMarkDeposit
// ====================================================

export interface partnerPenaltiesMarkDeposit_partnerPenaltiesMarkDeposit {
  __typename: "PartnerPenaltiesMarkDepositPayload";
  patchedPartnerPenaltyIds: string[];
}

export interface partnerPenaltiesMarkDeposit {
  partnerPenaltiesMarkDeposit: partnerPenaltiesMarkDeposit_partnerPenaltiesMarkDeposit | null;
}

export interface partnerPenaltiesMarkDepositVariables {
  input: PartnerPenaltiesMarkDepositInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allInsuranceApplicationsForPartner
// ====================================================

export interface allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node_insuranceApplicationCars {
  __typename: "InsuranceApplicationCar";
  id: string;
  carNumber: string;
  data: GraphQL_JSON;
}

export interface allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node_insuranceApplicationIins {
  __typename: "InsuranceApplicationIin";
  id: string;
  iinNumber: string;
  data: GraphQL_JSON;
}

export interface allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node {
  __typename: "InsuranceApplication";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  fullAmount: number;
  amount: number;
  discountPercentage: number;
  status: InsuranceApplicationStatus;
  insuranceApplicationCars: allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node_insuranceApplicationCars[];
  insuranceApplicationIins: allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node_insuranceApplicationIins[];
}

export interface allInsuranceApplicationsForPartner_allInsuranceApplications_edges {
  __typename: "InsuranceApplicationEdge";
  /**
   * The item at the end of the edge
   */
  node: allInsuranceApplicationsForPartner_allInsuranceApplications_edges_node | null;
}

export interface allInsuranceApplicationsForPartner_allInsuranceApplications {
  __typename: "InsuranceApplicationConnection";
  /**
   * A list of edges.
   */
  edges: (allInsuranceApplicationsForPartner_allInsuranceApplications_edges | null)[] | null;
  totalPageCount: number | null;
}

export interface allInsuranceApplicationsForPartner {
  allInsuranceApplications: allInsuranceApplicationsForPartner_allInsuranceApplications | null;
}

export interface allInsuranceApplicationsForPartnerVariables {
  after?: string | null;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: insuranceApplicationUpdate
// ====================================================

export interface insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication_insuranceApplicationCars {
  __typename: "InsuranceApplicationCar";
  id: string;
  carNumber: string;
  data: GraphQL_JSON;
}

export interface insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication_insuranceApplicationIins {
  __typename: "InsuranceApplicationIin";
  id: string;
  iinNumber: string;
  data: GraphQL_JSON;
}

export interface insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication {
  __typename: "InsuranceApplication";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  fullAmount: number;
  amount: number;
  discountPercentage: number;
  status: InsuranceApplicationStatus;
  insuranceApplicationCars: insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication_insuranceApplicationCars[];
  insuranceApplicationIins: insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication_insuranceApplicationIins[];
}

export interface insuranceApplicationUpdate_insuranceApplicationUpdate {
  __typename: "InsuranceApplicationUpdatePayload";
  insuranceApplication: insuranceApplicationUpdate_insuranceApplicationUpdate_insuranceApplication;
}

export interface insuranceApplicationUpdate {
  insuranceApplicationUpdate: insuranceApplicationUpdate_insuranceApplicationUpdate | null;
}

export interface insuranceApplicationUpdateVariables {
  input: InsuranceApplicationUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logIn
// ====================================================

export interface logIn_logIn_hubUser {
  __typename: "User";
  id: string;
  role: string;
  hubUsername: string | null;
}

export interface logIn_logIn {
  __typename: "LogInPayload";
  hubUser: logIn_logIn_hubUser;
}

export interface logIn {
  logIn: logIn_logIn;
}

export interface logInVariables {
  input: LogInInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: payment
// ====================================================

export interface payment_payment {
  __typename: "PenaltyPayment" | "AmanatCertificatePayment" | "PenaltyChecksByCarSubscriptionPayment";
  id: string;
  status: PaymentStatus;
  feeInCents: number;
  amountInCents: number;
  rawData: GraphQL_JSON;
  createdAt: GraphQL_DateTime;
}

export interface payment {
  payment: payment_payment | null;
}

export interface paymentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppNavigation_viewer
// ====================================================

export interface AppNavigation_viewer {
  __typename: "User";
  id: string;
  role: string;
  hubUsername: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllFaqsTable_faq
// ====================================================

export interface AllFaqsTable_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  voicesNegative: number;
  voicesPositive: number;
  archivedAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllHubUsersTable_user
// ====================================================

export interface AllHubUsersTable_user {
  __typename: "User";
  id: string;
  createdAt: GraphQL_DateTime;
  role: string;
  hubUsername: string | null;
  hubAccessible: boolean;
  partnerName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllPartnersTable_partners
// ====================================================

export interface AllPartnersTable_partners_edges_node_identityCard {
  __typename: "IdentityCard";
  number: string;
  iinNumber: string;
}

export interface AllPartnersTable_partners_edges_node {
  __typename: "Partner";
  id: string;
  name: string;
  carsCount: string;
  carLimit: number | null;
  email: string | null;
  createdAt: GraphQL_DateTime;
  binNumber: string | null;
  registrationDate: GraphQL_Date | null;
  penaltyRelevanceDateThreshold: GraphQL_DateTime;
  ccEmails: string[];
  active: boolean;
  aisbddParsingEnabled: boolean;
  slackToken: string | null;
  slackAisbddPenaltiesConversationId: string | null;
  payMode: PartnerPayMode;
  blackParsingEnabled: boolean;
  blueParsingEnabled: boolean;
  individualEntrepreneur: boolean;
  identityCard: AllPartnersTable_partners_edges_node_identityCard | null;
}

export interface AllPartnersTable_partners_edges {
  __typename: "PartnerEdge";
  /**
   * The item at the end of the edge
   */
  node: AllPartnersTable_partners_edges_node | null;
}

export interface AllPartnersTable_partners {
  __typename: "PartnerConnection";
  /**
   * A list of edges.
   */
  edges: (AllPartnersTable_partners_edges | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllPartnersTable_editedOrCreatedPartner
// ====================================================

export interface AllPartnersTable_editedOrCreatedPartner_partner {
  __typename: "Partner";
  id: string;
}

export interface AllPartnersTable_editedOrCreatedPartner {
  __typename: "PartnerEditOrCreatePayload";
  partner: AllPartnersTable_editedOrCreatedPartner_partner;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllPenaltyPaymentsTable_payment
// ====================================================

export interface AllPenaltyPaymentsTable_payment_user {
  __typename: "User";
  id: string;
  token: string | null;
}

export interface AllPenaltyPaymentsTable_payment_items_penalty_violator {
  __typename: "PenaltyViolator";
  firstName: string | null;
  lastName: string | null;
}

export interface AllPenaltyPaymentsTable_payment_items_penalty_violation {
  __typename: "PenaltyViolation";
  date: GraphQL_Date | null;
}

export interface AllPenaltyPaymentsTable_payment_items_penalty_protocol {
  __typename: "PenaltyProtocol";
  blankNumber: string | null;
}

export interface AllPenaltyPaymentsTable_payment_items_penalty {
  __typename: "Penalty";
  shekteuAltId: string | null;
  iinNumber: string | null;
  carNumber: string | null;
  carTechPassport: string | null;
  regressing: boolean;
  violator: AllPenaltyPaymentsTable_payment_items_penalty_violator;
  violation: AllPenaltyPaymentsTable_payment_items_penalty_violation;
  protocol: AllPenaltyPaymentsTable_payment_items_penalty_protocol;
}

export interface AllPenaltyPaymentsTable_payment_items {
  __typename: "PaymentItem";
  penalty: AllPenaltyPaymentsTable_payment_items_penalty | null;
}

export interface AllPenaltyPaymentsTable_payment {
  __typename: "PenaltyPayment" | "AmanatCertificatePayment" | "PenaltyChecksByCarSubscriptionPayment";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  user: AllPenaltyPaymentsTable_payment_user;
  items: AllPenaltyPaymentsTable_payment_items[];
  amountInCents: number;
  feeInCents: number;
  currency: Currency;
  type: PaymentType;
  provider: PaymentProvider;
  status: PaymentStatus;
  paidAmount: number | null;
  paidAt: GraphQL_DateTime | null;
  refundedAt: GraphQL_DateTime | null;
  rawData: GraphQL_JSON;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FaqCreateOrUpdateForm_faq
// ====================================================

export interface FaqCreateOrUpdateForm_faq {
  __typename: "Faq";
  id: string;
  createdAt: GraphQL_DateTime;
  title: string;
  markdownContent: string | null;
  category: FaqCategory;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HubUserCreateForm_user
// ====================================================

export interface HubUserCreateForm_user {
  __typename: "User";
  id: string;
  createdAt: GraphQL_DateTime;
  role: string;
  hubUsername: string | null;
  hubAccessible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PenaltyCheck_penalty
// ====================================================

export interface PenaltyCheck_penalty_violator {
  __typename: "PenaltyViolator";
  firstName: string | null;
  lastName: string | null;
}

export interface PenaltyCheck_penalty_violation {
  __typename: "PenaltyViolation";
  date: GraphQL_Date | null;
}

export interface PenaltyCheck_penalty_protocol {
  __typename: "PenaltyProtocol";
  blankNumber: string | null;
}

export interface PenaltyCheck_penalty_beneficiary {
  __typename: "PenaltyBeneficiary";
  taxOfficeName: string | null;
}

export interface PenaltyCheck_penalty {
  __typename: "Penalty";
  id: string;
  mvdId: string | null;
  shekteuAltId: string | null;
  iinNumber: string | null;
  carNumber: string | null;
  carTechPassport: string | null;
  amountInCents: number;
  paidAt: GraphQL_DateTime | null;
  violator: PenaltyCheck_penalty_violator;
  violation: PenaltyCheck_penalty_violation;
  protocol: PenaltyCheck_penalty_protocol;
  beneficiary: PenaltyCheck_penalty_beneficiary;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SettingsForm_setting
// ====================================================

export interface SettingsForm_setting_value {
  __typename: "SettingValue";
  default: GraphQL_JSON | null;
  ios: GraphQL_JSON | null;
  android: GraphQL_JSON | null;
}

export interface SettingsForm_setting {
  __typename: "Setting";
  id: string;
  key: string;
  value: SettingsForm_setting_value;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllCarsTable_partner
// ====================================================

export interface AllCarsTable_partner {
  __typename: "Partner";
  id: string;
  name: string;
  carsCount: string;
  payMode: PartnerPayMode;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllCarsTable_iinNumber
// ====================================================

export interface AllCarsTable_iinNumber {
  __typename: "IinNumberInfoPayload";
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllCarsTable_partnerCars
// ====================================================

export interface AllCarsTable_partnerCars_edges_node {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
  penaltiesSeenAt: GraphQL_DateTime | null;
  unpaidPenaltiesCount: number;
}

export interface AllCarsTable_partnerCars_edges {
  __typename: "PartnerCarEdge";
  /**
   * The item at the end of the edge
   */
  node: AllCarsTable_partnerCars_edges_node | null;
}

export interface AllCarsTable_partnerCars {
  __typename: "PartnerCarConnection";
  /**
   * A list of edges.
   */
  edges: (AllCarsTable_partnerCars_edges | null)[] | null;
  totalPageCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllCarsTable_deletedPartnerCar
// ====================================================

export interface AllCarsTable_deletedPartnerCar_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface AllCarsTable_deletedPartnerCar {
  __typename: "PartnerCarDeletePayload";
  partnerCar: AllCarsTable_deletedPartnerCar_partnerCar;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllCarsTable_createdPartnerCar
// ====================================================

export interface AllCarsTable_createdPartnerCar_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface AllCarsTable_createdPartnerCar {
  __typename: "PartnerCarCreatePayload";
  partnerCar: AllCarsTable_createdPartnerCar_partnerCar;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllDriversTable_partner
// ====================================================

export interface AllDriversTable_partner {
  __typename: "Partner";
  id: string;
  name: string;
  driversCount: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllDriversTable_partnerDrivers
// ====================================================

export interface AllDriversTable_partnerDrivers_edges_node {
  __typename: "PartnerDriver";
  id: string;
  name: string;
  iinNumber: string;
}

export interface AllDriversTable_partnerDrivers_edges {
  __typename: "PartnerDriverEdge";
  /**
   * The item at the end of the edge
   */
  node: AllDriversTable_partnerDrivers_edges_node | null;
}

export interface AllDriversTable_partnerDrivers {
  __typename: "PartnerDriverConnection";
  /**
   * A list of edges.
   */
  edges: (AllDriversTable_partnerDrivers_edges | null)[] | null;
  totalPageCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllDriversTable_deletedPartnerDriver
// ====================================================

export interface AllDriversTable_deletedPartnerDriver_partnerDriver {
  __typename: "PartnerDriver";
  id: string;
}

export interface AllDriversTable_deletedPartnerDriver {
  __typename: "PartnerDriverDeletePayload";
  partnerDriver: AllDriversTable_deletedPartnerDriver_partnerDriver;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllDriversTable_createdPartnerDriver
// ====================================================

export interface AllDriversTable_createdPartnerDriver_partnerDriver {
  __typename: "PartnerDriver";
  id: string;
  name: string;
  iinNumber: string;
}

export interface AllDriversTable_createdPartnerDriver {
  __typename: "PartnerDriverCreatePayload";
  partnerDriver: AllDriversTable_createdPartnerDriver_partnerDriver;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PartnerPenaltiesForCarTable_partnerPenalties
// ====================================================

export interface PartnerPenaltiesForCarTable_partnerPenalties_edges_node_payment {
  __typename: "PenaltyPayment" | "AmanatCertificatePayment" | "PenaltyChecksByCarSubscriptionPayment";
  id: string;
}

export interface PartnerPenaltiesForCarTable_partnerPenalties_edges_node {
  __typename: "PartnerPenalty";
  id: string;
  blankNumber: string | null;
  dateTime: GraphQL_DateTime | null;
  address: string | null;
  description: string | null;
  priceInCents: number;
  penaltyId: string | null;
  status: PartnerPenaltyStatus;
  penaltyShekteuAltId: string | null;
  awaitingPaymentFromDeposit: boolean;
  imageUrls: string[] | null;
  protocolUrl: string | null;
  payment: PartnerPenaltiesForCarTable_partnerPenalties_edges_node_payment | null;
}

export interface PartnerPenaltiesForCarTable_partnerPenalties_edges {
  __typename: "PartnerPenaltyEdge";
  /**
   * The item at the end of the edge
   */
  node: PartnerPenaltiesForCarTable_partnerPenalties_edges_node | null;
}

export interface PartnerPenaltiesForCarTable_partnerPenalties {
  __typename: "PartnerPenaltyConnection";
  /**
   * A list of edges.
   */
  edges: (PartnerPenaltiesForCarTable_partnerPenalties_edges | null)[] | null;
  totalPageCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PartnerPenaltiesForCarTable_patchedPartnerCar
// ====================================================

export interface PartnerPenaltiesForCarTable_patchedPartnerCar_partnerCar {
  __typename: "PartnerCar";
  id: string;
  vin: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface PartnerPenaltiesForCarTable_patchedPartnerCar {
  __typename: "PartnerCarPenaltiesMarkSeenPayload";
  partnerCar: PartnerPenaltiesForCarTable_patchedPartnerCar_partnerCar;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllInsuranceApplicationsTable_insuranceApplication
// ====================================================

export interface AllInsuranceApplicationsTable_insuranceApplication_insuranceApplicationCars {
  __typename: "InsuranceApplicationCar";
  id: string;
  carNumber: string;
  data: GraphQL_JSON;
}

export interface AllInsuranceApplicationsTable_insuranceApplication_insuranceApplicationIins {
  __typename: "InsuranceApplicationIin";
  id: string;
  iinNumber: string;
  data: GraphQL_JSON;
}

export interface AllInsuranceApplicationsTable_insuranceApplication {
  __typename: "InsuranceApplication";
  id: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  fullAmount: number;
  amount: number;
  discountPercentage: number;
  status: InsuranceApplicationStatus;
  insuranceApplicationCars: AllInsuranceApplicationsTable_insuranceApplication_insuranceApplicationCars[];
  insuranceApplicationIins: AllInsuranceApplicationsTable_insuranceApplication_insuranceApplicationIins[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Currency {
  KZT = "KZT",
}

export enum FaqCategory {
  INSURANCE = "INSURANCE",
  PAYMENT = "PAYMENT",
  PENALTY = "PENALTY",
  TECHSERVICE = "TECHSERVICE",
}

export enum InsuranceApplicationStatus {
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
}

export enum PartnerPayMode {
  CARD = "CARD",
  DEPOSIT_AUTO = "DEPOSIT_AUTO",
  DEPOSIT_MANUAL = "DEPOSIT_MANUAL",
  DISABLED = "DISABLED",
}

export enum PartnerPenaltyStatus {
  AWAITING = "AWAITING",
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
}

export enum PaymentDirection {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export enum PaymentProvider {
  ALFA = "ALFA",
  ALFA_APPLEPAY = "ALFA_APPLEPAY",
  ASIACREDIT = "ASIACREDIT",
  CLOUDPAYMENTS = "CLOUDPAYMENTS",
  PROCESSING_MASTERPASS = "PROCESSING_MASTERPASS",
  WOOPPAY = "WOOPPAY",
}

export enum PaymentStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  FROZEN = "FROZEN",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
  REFUNDED = "REFUNDED",
  SUCCEEDED = "SUCCEEDED",
}

export enum PaymentType {
  AMANAT_CERTIFICATE = "AMANAT_CERTIFICATE",
  INSURANCE = "INSURANCE",
  NOTIFICATIONS = "NOTIFICATIONS",
  PENALTY = "PENALTY",
  PENALTY_NOTIFICATIONS_BY_CAR = "PENALTY_NOTIFICATIONS_BY_CAR",
}

export enum PenaltyPaymentSearchBy {
  CAR_NUMBER = "CAR_NUMBER",
  ID = "ID",
  IIN_NUMBER = "IIN_NUMBER",
  REVERSE_ID = "REVERSE_ID",
}

export interface AllPartnerPenaltiesForPartnerCarInput {
  partnerCarId: string;
}

export interface FaqArchiveOrUnarchiveInput {
  id: string;
}

export interface FaqCreateOrUpdateInput {
  id?: string | null;
  title: string;
  markdownContent: string;
  category: FaqCategory;
}

export interface FaqUpdateWeightInput {
  id: string;
  newWeight: number;
}

export interface HubUserCreateInput {
  role: string;
  username: string;
  password: string;
  insuranceCompany?: string | null;
  partnerName?: string | null;
}

export interface HubUserPasswordChangeInput {
  currentPassword?: string | null;
  newPassword: string;
  userId?: string | null;
}

export interface HubUserUpdateInput {
  userId: string;
  hubAccessible?: boolean | null;
}

export interface InsuranceApplicationUpdateInput {
  id: string;
  status: InsuranceApplicationStatus;
}

export interface LogInInput {
  username: string;
  password: string;
}

export interface PartnerCarCreateInput {
  vin?: string | null;
  carNumber: string;
  carTechPassport: string;
}

export interface PartnerCarDeleteInput {
  partnerCarId: string;
}

export interface PartnerCarPenaltiesMarkSeenInput {
  partnerCarId: string;
}

export interface PartnerDriverCreateInput {
  iinNumber: string;
  identityCardNumber: string;
}

export interface PartnerDriverDeleteInput {
  partnerDriverId: string;
}

export interface PartnerEditOrCreateInput {
  id?: string | null;
  name: string;
  email?: string | null;
  active: boolean;
  aisbddParsingEnabled: boolean;
  blackParsingEnabled: boolean;
  blueParsingEnabled: boolean;
  slackToken?: string | null;
  slackAisbddPenaltiesConversationId?: string | null;
  penaltyRelevanceDateThresholdIso?: string | null;
  binNumber?: string | null;
  registrationDate: GraphQL_Date;
  ccEmails: string[];
  carLimit?: number | null;
  payMode: PartnerPayMode;
  individualEntrepreneur: boolean;
  iinNumber?: string | null;
  identityCardNumber?: string | null;
}

export interface PartnerPenaltiesMarkDepositInput {
  penaltyIds: string[];
}

export interface PaymentForClientPenaltyCreateInput {
  penaltyId: string;
  userId: number;
}

export interface PaymentForPartnerPenaltyCreateInput {
  penaltyIds: string[];
  returnUrl: string;
}

export interface PaymentRefundInput {
  paymentId: string;
}

export interface PenaltyCheckQueryInput {
  iinNumber?: string | null;
  carNumber?: string | null;
  carTechPassport?: string | null;
  binNumber?: string | null;
  identityCardNumber?: string | null;
  registrationNumber?: string | null;
}

export interface PenaltyPaymentFilters {
  forRegressingPenaltiesOnly?: boolean | null;
  forStuckPaymentsOnly?: boolean | null;
}

export interface PenaltyRepaymentRestartInput {
  paymentIds: string[];
}

export interface PushNotificationSendInput {
  title: string;
  body: string;
  tokens: string[];
  data: GraphQL_JSON;
  disableLogging: boolean;
}

export interface RunPenaltyCheckInput {
  query: PenaltyCheckQueryInput;
}

export interface SettingValueInput {
  default?: GraphQL_JSON | null;
  ios?: GraphQL_JSON | null;
  android?: GraphQL_JSON | null;
}

export interface SettingValueItemInput {
  key: string;
  value: SettingValueInput;
}

export interface SettingsUpdateInput {
  settingValues: SettingValueItemInput[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
