import {colors} from '@atlaskit/theme';
import AkNavigation from '@atlaskit/navigation';
import MediaQuery from 'react-responsive';
import React from 'react';

import {OkautoIcon} from 'src/components/OkautoIcon';

interface DefaultNavigationProps {}

export const DefaultNavigation: React.FunctionComponent<
  DefaultNavigationProps
> = () => (
  <MediaQuery query="(min-width: 1024px)">
    {onDesktop =>
      onDesktop && (
        <AkNavigation
          globalPrimaryIcon={
            <OkautoIcon
              label="OKauto"
              size="large"
              primaryColor={colors.B500}
            />
          }
          globalPrimaryItemHref="/"
          isOpen={false}
          isResizeable={false}
        />
      )
    }
  </MediaQuery>
);
