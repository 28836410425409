import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument} from 'src/utils/graphql';

export const PENALTY_PAYMENT_DO_CREATE: MutationDocument<
  G.paymentForClientPenaltyCreate,
  G.paymentForClientPenaltyCreateVariables
> = gql`
  mutation paymentForClientPenaltyCreate(
    $input: PaymentForClientPenaltyCreateInput!
  ) {
    paymentForClientPenaltyCreate(input: $input) {
      formUrl
      callbackSuccessUrl
      callbackFailUrl
    }
  }
`;
