import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {AllFaqsCategoryTable} from './components/AllFaqsCategoryTable';

export const ALL_FAQS_FOR_ADMIN_QUERY: QueryDocument<
  G.allFaqsForAdmin,
  G.allFaqsForAdminVariables
> = gql`
  query allFaqsForAdmin(
    $after: String
    $first: Int
    $searchText: String
    $category: FaqCategory
  ) {
    allFaqs(
      after: $after
      first: $first
      searchText: $searchText
      category: $category
    ) {
      edges {
        node {
          ...AllFaqsTable_faq
        }
      }
      totalPageCount
    }
  }
  ${AllFaqsCategoryTable.fragments.faq}
`;

export const ARCHIVE_OR_UNARCHIVE_FAQ_MUTATION: MutationDocument<
  G.faqArchiveOrUnarchive,
  G.faqArchiveOrUnarchiveVariables
> = gql`
  mutation faqArchiveOrUnarchive($input: FaqArchiveOrUnarchiveInput!) {
    faqArchiveOrUnarchive(input: $input) {
      faq {
        ...AllFaqsTable_faq
      }
    }
  }
  ${AllFaqsCategoryTable.fragments.faq}
`;

export const UPDATE_FAQ_WEIGHT_MUTATION: MutationDocument<
  G.faqUpdateWeight,
  G.faqUpdateWeightVariables
> = gql`
  mutation faqUpdateWeight($input: FaqUpdateWeightInput!) {
    faqUpdateWeight(input: $input) {
      faq {
        ...AllFaqsTable_faq
      }
    }
  }
  ${AllFaqsCategoryTable.fragments.faq}
`;
