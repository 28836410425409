import {RouteComponentProps, Redirect} from '@reach/router';
import React from 'react';
import qs from 'query-string';

import {
  AppPageWithViewer,
  VIEWER_QUERY,
} from 'src/containers/AppPageWithViewer';
import {FullscreenForm} from 'src/components/FullscreenForm';
import {Mutation} from 'src/utils/react-apollo';
import {Opt} from 'shared/utils/data-types';

import {LOG_IN_MUTATION} from './graphql';
import {LoginForm} from './components/LoginForm';

interface LoginPageParams {}
interface LoginPageProps extends RouteComponentProps<LoginPageParams> {}

export const LoginPage: React.FunctionComponent<LoginPageProps> = () => (
  <AppPageWithViewer hideDefaultNavigation>
    {({viewer: currentUser}) => {
      const query: {
        continue?: string;
      } = qs.parse(window.location.search);
      if (currentUser) {
        return (
          <Redirect
            to={Opt.nonEmptyString(query.continue).getOrElse('/')}
            noThrow
          />
        );
      }

      return (
        <Mutation
          mutation={LOG_IN_MUTATION}
          update={(cache, {data}) => {
            if (data && data.logIn) {
              cache.writeQuery({
                query: VIEWER_QUERY,
                data: {viewer: data.logIn.hubUser},
              });
            }
          }}
          // provide a noop here, otherwise an unhandled error is thrown
          onError={() => {}}
        >
          {(logIn, {loading: loggingIn, error: logInError, data: logInData}) =>
            logInData && logInData.logIn && logInData.logIn.hubUser ? (
              <Redirect
                to={Opt.nonEmptyString(query.continue).getOrElse('/')}
                noThrow
              />
            ) : (
              <FullscreenForm>
                <LoginForm
                  onLogIn={({username, password}) =>
                    logIn({variables: {input: {username, password}}})
                  }
                  loading={loggingIn}
                  error={logInError}
                />
              </FullscreenForm>
            )
          }
        </Mutation>
      );
    }}
  </AppPageWithViewer>
);
