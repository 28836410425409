import {State} from 'react-powerplug';
import {colors} from '@atlaskit/theme';
import CheckIcon from '@atlaskit/icon/glyph/check';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Page, {Grid, GridColumn} from '@atlaskit/page';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {Mutation} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {PUSH_SEND_MUTATION} from '../graphql';
import {PushForm} from '../components/PushForm';

interface PushFormContainerProps {}

export const PushFormContainer: React.FunctionComponent<
  PushFormContainerProps
> = () => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Page>
        <State
          initial={{
            title: '',
            body: '',
            data: {},
            tokens: [''],
            disableLogging: false,
          }}
        >
          {({state, setState}) => (
            <Grid>
              <GridColumn medium={6}>
                <Mutation
                  mutation={PUSH_SEND_MUTATION}
                  onCompleted={() => {
                    onAddFlag({
                      autoDismiss: true,
                      icon: (
                        <CheckIcon label="Успех" primaryColor={colors.G400} />
                      ),
                      title: 'Пуш(ы) отправлены',
                    });
                  }}
                  onError={err => {
                    onAddFlag({
                      autoDismiss: true,
                      icon: (
                        <ErrorIcon label="Ошибка" primaryColor={colors.R300} />
                      ),
                      title: 'Произошла ошибка',
                      description: `Не удалось отправить пуш(ы): ${messageForApolloError(
                        err,
                      )}`,
                    });
                  }}
                >
                  {pushNotificationSend => (
                    <PushForm
                      updateState={input =>
                        setState({
                          title: input.title,
                          body: input.body,
                          data: input.data,
                          tokens: input.tokens,
                          disableLogging: input.disableLogging,
                        })
                      }
                      initialValues={state}
                      onPushNotificationSend={input =>
                        pushNotificationSend({
                          variables: {input},
                        })
                      }
                    />
                  )}
                </Mutation>
              </GridColumn>
              <GridColumn medium={6}>
                <DropdownMenu
                  trigger="Системные пуши"
                  triggerType="button"
                  position="bottom right"
                >
                  <DropdownItemGroup title="проверки штрафов">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: '%{DriverName}',
                          body:
                            '✅ Нажмите, чтобы увидеть результаты проверки штрафов',
                          data: {
                            click_action: 'makePenaltyRequest',
                            type: '0',
                            status: 'success',
                            penaltyId: '',
                            driverId: '',
                            iinNumber: '',
                          },
                        })
                      }
                    >
                      penaltyCheckSucceeded
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: '%{DriverName}',
                          body: '⚠️ Не удалось выполнить проверку штрафов',
                          data: {
                            click_action: 'cancelPenaltyRequest',
                            type: '0',
                            status: 'error',
                            errorCode: '',
                            errorMessage: '',
                            referenceParams: '',
                            driverId: '',
                          },
                        })
                      }
                    >
                      penaltyCheckFailed
                    </DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup title="платежи">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Платеж успешно принят!',
                          body:
                            'Ваш платеж на сумму %{amount} тг. успешно принят. ' +
                            'Данные отправлены на обработку. Дождитесь уведомления. ' +
                            'Это может занять пару минут.',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '4',
                            channelId: 'payment',
                            channelName: 'Платежи',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации о ' +
                              'платежах, совершенных через приложение OKauto',
                          },
                        })
                      }
                    >
                      incomingPaymentSucceeded
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Платеж отклонен',
                          body:
                            'Средства с вашей карты не были списаны. Чтобы произвести ' +
                            'оплату необходимо разблокировать вашу карту для интернет транзакций. ' +
                            'Для более подробной информации, попробуйте обратиться в ваш банк.',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '4',
                            channelId: 'payment',
                            channelName: 'Платежи',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации о ' +
                              'платежах, совершенных через приложение OKauto',
                          },
                        })
                      }
                    >
                      incomingPaymentFailed
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Платеж успешно принят!',
                          body:
                            'Ваш платеж на сумму %{amount} тг. успешно принят. ' +
                            'Вы %{оформили/продлили} подписку на уведомления по' +
                            ' тарифу: %{tariffName} на авто %{carNumber} до ' +
                            '%{expiredAt(dd.mm.yyyy)}. %{Согласно условиям акции ' +
                            'с Mastercard, мы списали только 50% стоимости подписки.' +
                            ' Спасибо за участие!}',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '4',
                            channelId: 'payment',
                            channelName: 'Платежи',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации о ' +
                              'платежах, совершенных через приложение OKauto',
                          },
                        })
                      }
                    >
                      notificationsPaymentSucceeded
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Штраф успешно погашен!',
                          body:
                            'Ваш штраф на сумму %{amount} тг. успешно удален из ' +
                            'базы. Впредь, водите аккуратнее!',
                          data: {
                            click_action: 'showBills',
                            type: '4',
                            channelId: 'payment',
                            channelName: 'Платежи',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации о ' +
                              'платежах, совершенных через приложение OKauto',
                          },
                        })
                      }
                    >
                      penaltyRepaymentSucceeded
                    </DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup title="штрафы">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Новый штраф',
                          body: '%{driverName}, обнаружен штраф',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '23',
                            channelId: 'penalty',
                            channelName: 'Штрафы',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации ' +
                              'по штрафам',
                            status: 'success',
                            penaltyId: '',
                            iinNumber: '',
                          },
                        })
                      }
                    >
                      newPenaltyDetected
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Новый штраф',
                          body:
                            'По авто %{carNumber} найден штраф. Узнайте подробности.',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '18',
                            channelId: 'penaltySubscription',
                            channelName: 'Штрафы по подписке',
                            channelDescription:
                              'Канал уведомлений по предоставлению информации по' +
                              ' штрафам по подписке',
                            status: 'success',
                            penaltyId: '',
                            penaltyCarMark: '',
                            penaltyCarNumber: '',
                            penaltyDateTimeIsoString: '',
                            penaltyPriceInCents: '',
                            penaltyAddress: '',
                            penaltyDescription: '',
                            penaltyBlankNumber: '',
                          },
                        })
                      }
                    >
                      newPenaltyDetectedForClient
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Штрафов не обнаружено',
                          body: 'По авто %{carNumber} не найдено штрафов.',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '3',
                            channelId: 'general',
                            channelName: 'Уведомления',
                            channelDescription:
                              'Основной канал уведомлений приложения OKauto',
                            status: 'success',
                          },
                        })
                      }
                    >
                      noPenaltiesDetectedForClient
                    </DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup title="страховка">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Страховка',
                          body:
                            'По авто %{carNumber} срок действия страховки ' +
                            'истекает через %{numberOfDays} день/дня/дней',
                          data: {
                            click_action: 'notification',
                            type: '3',
                            channelId: 'general',
                            channelName: 'Уведомления',
                            channelDescription:
                              'Основной канал уведомлений приложения OKauto',
                            status: 'success',
                            daysLeft: '',
                          },
                        })
                      }
                    >
                      soonExpiringInsuranceDetected(version less 63)
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Страховка',
                          body:
                            'По авто %{carNumber} срок действия страховки ' +
                            'истекает через %{numberOfDays} день/дня/дней',
                          data: {
                            click_action: 'showNotificationLog',
                            type: '7',
                            channelId: 'insurance',
                            channelName: 'Страхование',
                            channelDescription:
                              'Канал для уведомлений сервиса по страховкам',
                            status: 'success',
                          },
                        })
                      }
                    >
                      soonExpiringInsuranceDetectedPromo
                    </DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup title="остальное">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Ошибка',
                          body:
                            'Номер удостоверения на имя %{name} введен неверно',
                          data: {
                            click_action: 'personIdNumberVerification',
                            type: '17',
                            channelId: 'general',
                            channelName: 'Уведомления',
                            channelDescription:
                              'Основной канал уведомлений приложения OKauto',
                            status: 'success',
                            iinNumber: '',
                            driverId: '',
                          },
                        })
                      }
                    >
                      identityCardVerificationFailed
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
                <DropdownMenu
                  trigger="Промо пуши"
                  triggerType="button"
                  position="bottom right"
                >
                  <DropdownItemGroup title="страховки">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Пора обновить страховку!',
                          body:
                            'Для вашего автомобиля %{carNumber} страховка заканчивается ' +
                            '%{endDate}. Успейте обновить ее по выгодным ценам!',
                          data: {
                            click_action: 'openInsuranceActivity',
                            type: '7',
                            channelId: 'insurance',
                            channelName: 'Страхование',
                            channelDescription:
                              'Канал для уведомлений сервиса по страховкам',
                            status: 'success',
                          },
                        })
                      }
                    >
                      insuranceOffer
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Пора обновить страховку!',
                          body:
                            'Для вашего автомобиля %{carNumber} страховка заканчивается %{endDate}.',
                          data: {
                            click_action: 'insuranceAutocomplete',
                            type: '25',
                            channelId: 'promotionNotifications',
                            channelName: 'Промо уведомления',
                            channelDescription: 'Канал промо уведомлений',
                            status: 'success',
                            iinNumbers: '',
                            carNumbers: '',
                          },
                        })
                      }
                    >
                      insuranceOfferAutocomplete
                    </DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup title="запчасти">
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Нужны запчасти?',
                          body:
                            'Теперь через ОКавто можно заказать запчасти для своего ' +
                            'автомобиля. Привезем даже во время карантина!',
                          data: {
                            click_action: 'openPromoUrl',
                            type: '24',
                            channelId: 'promotionNotifications',
                            channelName: 'Промо уведомления',
                            channelDescription: 'Канал промо уведомлений',
                            pageTitle: 'Заказ запчастей',
                            browser: 'external',
                            url: 'https://bit.ly/2Rs6dGJ',
                          },
                        })
                      }
                    >
                      carPartsOffer
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        setState({
                          title: 'Пора за шинами!',
                          body:
                            'Специально для вашего автомобиля мы подобрали шины и ' +
                            'предлагаем их вам по лучшей цене. Посмотрите!',
                          data: {
                            click_action: 'openPromoUrl',
                            type: '24',
                            channelId: 'promotionNotifications',
                            channelName: 'Промо уведомления',
                            channelDescription: 'Канал промо уведомлений',
                            pageTitle: 'Шины для вас',
                            browser: 'external',
                            url:
                              'https://landing-order-page.okauto.kz/order?extraPayload=%encryptedText%&%ecarProductIds%',
                          },
                        })
                      }
                    >
                      tyresOffer
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              </GridColumn>
            </Grid>
          )}
        </State>
      </Page>
    )}
  </FlagsConsumer>
);
