import {Omit} from '../ts-utils';
import {is, Opt} from './data-types';

// based on babel's _objectWithoutProperties
export const omit = <
  TObj extends {[key: string]: unknown},
  TKey extends Extract<keyof TObj, string>
>(
  keys: [TKey, ...TKey[]],
  originalObj: TObj,
): Omit<TObj, TKey> => {
  const newObj: Partial<TObj> = {};

  /* eslint-disable no-restricted-syntax */
  for (const key in originalObj) {
    if (
      !(keys as string[]).includes(key) &&
      Object.prototype.hasOwnProperty.call(originalObj, key)
    ) {
      newObj[key] = originalObj[key]; // tslint:disable-line:no-object-mutation
    }
  }
  /* eslint-enable no-restricted-syntax */

  return newObj as any;
};

export const complement = <TObj extends {[key: string]: unknown}>(
  source: TObj,
  targets: TObj[],
) =>
  Object.entries(source)
    .map(([key, sourceVal]): [string, unknown] => {
      if (!is.nil(sourceVal)) return [key, sourceVal];

      const targetVal = targets
        .map(target => target[key])
        .find(val => !is.nil(val));

      return [key, Opt.fromNullable(targetVal).toNullable()];
    })
    .reduce<TObj>((acc, [key, val]) => ({...acc, [key]: val}), {} as any);

export const mapToRecord = <TKey extends string, TVal>(
  keys: TKey[],
  getValue: (key: TKey) => TVal,
) =>
  keys.reduce<object>(
    (acc, key) => ({
      ...acc,
      [key]: getValue(key),
    }),
    {},
  ) as Record<TKey, TVal>;
