import {ButtonGroup} from '@atlaskit/button';
import Button from '@atlaskit/button/dist/es5/components/Button';
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import React from 'react';
import ym from 'react-yandex-metrika';

import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';

import {DELETE_PARTNER_DRIVER_MUTATION} from '../graphql';

interface PartnerCarDeleteModalDialogProps {
  isDeleteModalOpen: boolean;
  setDeleteModalOpenState: (isDeleteModalOpen: boolean) => any;
  partnerDriver: G.allPartnerDrivers_allPartnerDrivers_edges_node;
}

export const PartnerDriverDeleteModalDialog: React.FunctionComponent<
  PartnerCarDeleteModalDialogProps
> = props => (
  <Mutation
    refetchQueries={['allPartnerDrivers', 'partner']}
    mutation={DELETE_PARTNER_DRIVER_MUTATION}
    // provide a noop here, otherwise an unhandled error is thrown
    onError={() => {}}
  >
    {(partnerDriverDelete, {loading}) => {
      const headingMessage = `Вы действительно хотите удалить "${props.partnerDriver.name}"?`;
      const closeModal = () => props.setDeleteModalOpenState(false);
      const modalFooter = () => (
        <ModalFooter>
          <ButtonGroup>
            <Button
              isLoading={loading}
              appearance="primary"
              onClick={() => {
                partnerDriverDelete({
                  variables: {
                    input: {
                      partnerDriverId: props.partnerDriver.id,
                    },
                  },
                });
                closeModal();
                ym('reachGoal', 'HubRemoveDriver');
              }}
            >
              Да
            </Button>
            <Button appearance="default" onClick={closeModal}>
              Отмена
            </Button>
          </ButtonGroup>
        </ModalFooter>
      );

      return props.isDeleteModalOpen ? (
        <ModalTransition>
          <ModalDialog
            onClose={closeModal}
            footer={modalFooter}
            width="small"
            heading={headingMessage}
          />
        </ModalTransition>
      ) : null;
    }}
  </Mutation>
);
