import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument} from 'src/utils/graphql';

export const PAYMENT_QUERY: QueryDocument<G.payment, G.paymentVariables> = gql`
  query payment($id: String!) {
    payment(id: $id) {
      id
      status
      feeInCents
      amountInCents
      rawData
      createdAt
    }
  }
`;
