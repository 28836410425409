import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';

import {AdminNavigation} from '../navigation';
import {AllPenaltyPaymentsContainer} from './containers/AllPenaltyPaymentsContainer';

interface AllPenaltyPaymentsPageParams {}
interface AllPenaltyPaymentsPageProps
  extends RouteComponentProps<AllPenaltyPaymentsPageParams> {}

export const AllPenaltyPaymentsPage: React.FunctionComponent<
  AllPenaltyPaymentsPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={AdminNavigation}
    roles={[UserRole.Superadmin, UserRole.SupportOperator]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader>Платежи за штрафы</PageHeader>
        <AllPenaltyPaymentsContainer location={props.location} />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
