import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {PartnerPenaltiesForCarTable} from './components/PartnerPenaltiesForCarTable';

export const PARTNER_PENALTIES_FOR_CAR_QUERY: QueryDocument<
  G.allPartnerPenaltiesForPartnerCar,
  G.allPartnerPenaltiesForPartnerCarVariables
> = gql`
  query allPartnerPenaltiesForPartnerCar(
    $after: String
    $first: Int
    $input: AllPartnerPenaltiesForPartnerCarInput!
  ) {
    allPartnerPenaltiesForPartnerCar(
      after: $after
      first: $first
      input: $input
    ) {
      ...PartnerPenaltiesForCarTable_partnerPenalties
    }
  }
  ${PartnerPenaltiesForCarTable.fragments.partnerPenaltiesForCar}
`;

export const PARTNER_CAR_PENALTIES_MARK_SEEN: MutationDocument<
  G.partnerCarDelete,
  G.partnerCarDeleteVariables
> = gql`
  mutation partnerCarPenaltiesMarkSeen(
    $input: PartnerCarPenaltiesMarkSeenInput!
  ) {
    partnerCarPenaltiesMarkSeen(input: $input) {
      ...PartnerPenaltiesForCarTable_patchedPartnerCar
    }
  }
  ${PartnerPenaltiesForCarTable.fragments.patchedPartnerCar}
`;

export const PENALTY_PAYMENT_CREATE: MutationDocument<
  G.paymentForPartnerPenaltyCreate,
  G.paymentForPartnerPenaltyCreateVariables
> = gql`
  mutation paymentForPartnerPenaltyCreate(
    $input: PaymentForPartnerPenaltyCreateInput!
  ) {
    paymentForPartnerPenaltyCreate(input: $input) {
      formUrl
      callbackSuccessUrl
      callbackFailUrl
    }
  }
`;

export const PARTNER_QUERY: QueryDocument<G.partner> = gql`
  query partner {
    partner {
      id
      name
      carsCount
      driversCount
      payMode
    }
  }
`;

export const PARTNER_PENALTIES_MARK_DEPOSIT: MutationDocument<
  G.partnerPenaltiesMarkDeposit,
  G.partnerPenaltiesMarkDepositVariables
> = gql`
  mutation partnerPenaltiesMarkDeposit(
    $input: PartnerPenaltiesMarkDepositInput!
  ) {
    partnerPenaltiesMarkDeposit(input: $input) {
      patchedPartnerPenaltyIds
    }
  }
`;
