import {State} from 'react-powerplug';
import {colors} from '@atlaskit/theme';
import {navigate} from '@reach/router';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Page, {Grid, GridColumn} from '@atlaskit/page';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {Mutation} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {PENALTY_PAYMENT_DO_CREATE} from '../graphql';
import {PaymentDoForm} from '../components/PaymentDoForm';

interface PaymentDoFormContainerProps {}

export const PaymentDoFormContainer: React.FunctionComponent<
  PaymentDoFormContainerProps
> = () => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Page>
        <State
          initial={{
            userId: '',
            penaltyId: '',
          }}
        >
          {({state, setState}) => (
            <Grid>
              <GridColumn medium={6}>
                <Mutation
                  mutation={PENALTY_PAYMENT_DO_CREATE}
                  onCompleted={({paymentForClientPenaltyCreate}) => {
                    navigate(
                      `//${paymentForClientPenaltyCreate &&
                        paymentForClientPenaltyCreate.formUrl &&
                        paymentForClientPenaltyCreate.formUrl.replace(
                          'https://',
                          '',
                        )}`,
                    );
                  }}
                  onError={err => {
                    onAddFlag({
                      autoDismiss: true,
                      icon: (
                        <ErrorIcon label="Ошибка" primaryColor={colors.R300} />
                      ),
                      title: 'Произошла ошибка',
                      description: `${messageForApolloError(err)}`,
                    });
                  }}
                >
                  {paymentForClientPenaltyCreate => (
                    <PaymentDoForm
                      updateState={input =>
                        setState({
                          userId: input.userId,
                          penaltyId: input.penaltyId,
                        })
                      }
                      initialValues={state}
                      onPaymentForClientPenaltyCreate={input =>
                        paymentForClientPenaltyCreate({
                          variables: {
                            input,
                          },
                        })
                      }
                    />
                  )}
                </Mutation>
              </GridColumn>
            </Grid>
          )}
        </State>
      </Page>
    )}
  </FlagsConsumer>
);
