import {ApolloError} from 'apollo-client';
import {Checkbox} from '@atlaskit/checkbox';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Button from '@atlaskit/button/dist/es5/components/Button';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';
import ym from 'react-yandex-metrika';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node} from 'src/__generated__/graphql-types.gen';
import {currencyCentsToUnits} from 'shared/utils/currency';
import {formatIsoDateTime} from 'src/utils/formatting';
import {fromGlobalId, nodesFromConnection} from 'src/utils/graphql-relay';
import {is, Opt} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';

import {PartnerPenaltiesForCarShowPhotosModalDialog} from '../containers/PartnerPenaltiesForCarShowPhotosModalDialog';
import {PartnerPenaltiesPayButtonContainer} from '../containers/PartnerPenaltiesPayButtonContainer';

interface PartnerPenaltiesForCarContainerState {
  isShowPhotosModalOpen: boolean;
  partnerPenalty: allPartnerPenaltiesForPartnerCar_allPartnerPenaltiesForPartnerCar_edges_node | null;
}
interface PartnerPenaltiesForCarTableProps {
  loading: boolean;
  error?: ApolloError;
  data?: G.allPartnerPenaltiesForPartnerCar;
  returnUrl: string;
  partner: G.partner_partner;
  selectedPenaltyIds: string[];
  onCheckboxChange: (penaltyId: string) => void;
}

const STATUS_LOZENGE_APPEARANCE_MAP = {
  PAID: 'success',
  NOT_PAID: 'removed',
  PROCESSING: 'inprogress',
  AWAITING: 'inprogress',
};
const STATUS_LOZENGE_LABEL_MAP = {
  PAID: 'Оплачено',
  NOT_PAID: 'Не оплачено',
  PROCESSING: 'В процессе',
  AWAITING: 'Ожидает оплаты',
};

export class PartnerPenaltiesForCarTable extends React.Component<
  PartnerPenaltiesForCarTableProps
> {
  public static fragments = {
    partnerPenaltiesForCar: gql`
      fragment PartnerPenaltiesForCarTable_partnerPenalties on PartnerPenaltyConnection {
        edges {
          node {
            id
            blankNumber
            dateTime
            address
            description
            priceInCents
            penaltyId
            status
            penaltyShekteuAltId
            awaitingPaymentFromDeposit
            imageUrls
            protocolUrl
            payment {
              id
            }
          }
        }
        totalPageCount
      }
    `,
    patchedPartnerCar: gql`
      fragment PartnerPenaltiesForCarTable_patchedPartnerCar on PartnerCarPenaltiesMarkSeenPayload {
        partnerCar {
          id
          vin
          carNumber
          carTechPassport
        }
      }
    `,
  };
  public state: PartnerPenaltiesForCarContainerState = {
    partnerPenalty: null,
    isShowPhotosModalOpen: false,
  };
  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <>
        <PartnerPenaltiesForCarShowPhotosModalDialog
          partnerPenalty={this.state.partnerPenalty}
          isShowPhotosModalOpen={this.state.isShowPhotosModalOpen}
          setShowPhotosModalOpenState={isShowPhotosModalOpen =>
            this.setState({isShowPhotosModalOpen})
          }
          setPartnerPenaltyState={partnerPenalty =>
            this.setState({partnerPenalty})
          }
        />
        <TableContainer>
          <DynamicTableStateless
            head={{
              cells: [
                {
                  key: 'blankNumber',
                  content: 'Номер постановления',
                },
                {
                  key: 'penaltyShekteuAltId',
                  content: 'Номер материала',
                },
                {
                  key: 'address',
                  content: 'Место нарушения',
                },
                {
                  key: 'description',
                  content: 'Нарушение',
                },
                {
                  key: 'dateTime',
                  content: 'Дата и время нарушения',
                },
                {
                  key: 'protocol',
                  content: 'Протокол',
                },
                {
                  key: 'photos',
                  content: 'Фото',
                },
                {
                  key: 'priceInCents',
                  content: 'Сумма штрафа kzt',
                },
                {
                  key: 'status',
                  content: 'Статус',
                },
                {
                  key: 'payOption',
                },
              ],
            }}
            rows={
              this.props.data &&
              this.props.data.allPartnerPenaltiesForPartnerCar
                ? nodesFromConnection(
                    this.props.data.allPartnerPenaltiesForPartnerCar,
                  ).map(partnerPenalty => ({
                    key: partnerPenalty.id,
                    cells: [
                      {
                        key: partnerPenalty.blankNumber,
                        content: partnerPenalty.blankNumber,
                      },
                      {
                        key: partnerPenalty.blankNumber,
                        content: Opt.fromNullable(
                          partnerPenalty.penaltyShekteuAltId,
                        ).getOrElse('-'),
                      },
                      {
                        key: partnerPenalty.address,
                        content: Opt.fromNullable(
                          partnerPenalty.address,
                        ).getOrElse('-'),
                      },
                      {
                        key: partnerPenalty.description,
                        content: Opt.fromNullable(
                          partnerPenalty.description,
                        ).getOrElse('-'),
                      },
                      {
                        key: partnerPenalty.dateTime,
                        content: is.nonEmptyString(partnerPenalty.dateTime)
                          ? formatIsoDateTime(partnerPenalty.dateTime)
                          : '-',
                      },
                      {
                        key: 'protocol',
                        content: (
                          <>
                            {(() => {
                              if (
                                is.nonEmptyString(partnerPenalty.protocolUrl)
                              ) {
                                return (
                                  <Button
                                    appearance="link"
                                    href={partnerPenalty.protocolUrl}
                                    target="_blank"
                                    spacing="compact"
                                  >
                                    Открыть
                                  </Button>
                                );
                              }

                              return <></>;
                            })()}
                          </>
                        ),
                      },
                      {
                        key: 'photos',
                        content: (
                          <>
                            {(() => {
                              if (is.nonEmptyArray(partnerPenalty.imageUrls)) {
                                return (
                                  <Button
                                    appearance="subtle"
                                    onClick={() => {
                                      this.setState({partnerPenalty});
                                      this.setState({
                                        isShowPhotosModalOpen: true,
                                      });
                                      ym('reachGoal', 'hubGetPhoto');
                                    }}
                                    iconBefore={
                                      <CameraFilledIcon
                                        label="Просмотр"
                                        size="small"
                                      />
                                    }
                                    spacing="compact"
                                  />
                                );
                              }

                              return <></>;
                            })()}
                          </>
                        ),
                      },
                      {
                        key: partnerPenalty.priceInCents,
                        content: currencyCentsToUnits(
                          partnerPenalty.priceInCents,
                        ),
                      },
                      {
                        key: partnerPenalty.status,
                        content: (
                          <Lozenge
                            appearance={
                              STATUS_LOZENGE_APPEARANCE_MAP[
                                partnerPenalty.status
                              ]
                            }
                          >
                            {STATUS_LOZENGE_LABEL_MAP[partnerPenalty.status]}
                          </Lozenge>
                        ),
                      },
                      {
                        key: partnerPenalty,
                        content: (
                          <>
                            {(() => {
                              if (
                                !partnerPenalty.awaitingPaymentFromDeposit &&
                                partnerPenalty.status ===
                                  G.PartnerPenaltyStatus.NOT_PAID
                              ) {
                                if (
                                  this.props.partner.payMode ===
                                  G.PartnerPayMode.CARD
                                ) {
                                  return (
                                    <PartnerPenaltiesPayButtonContainer
                                      penaltyId={partnerPenalty.penaltyId}
                                      returnUrl={this.props.returnUrl}
                                      isDisabled={
                                        !is.nonEmptyString(
                                          partnerPenalty.penaltyId,
                                        )
                                      }
                                    />
                                  );
                                }
                                if (
                                  this.props.partner.payMode ===
                                    G.PartnerPayMode.DEPOSIT_MANUAL &&
                                  !is.nullOrUndefined(
                                    this.props.onCheckboxChange,
                                  ) &&
                                  this.props.selectedPenaltyIds
                                ) {
                                  return (
                                    <Checkbox
                                      id="selectPenalty"
                                      onChange={() => {
                                        if (
                                          is.nonEmptyString(
                                            partnerPenalty.penaltyId,
                                          )
                                        ) {
                                          this.props.onCheckboxChange(
                                            partnerPenalty.penaltyId,
                                          );
                                        }
                                      }}
                                      isChecked={
                                        is.nonEmptyString(
                                          partnerPenalty.penaltyId,
                                        ) &&
                                        this.props.selectedPenaltyIds.includes(
                                          partnerPenalty.penaltyId,
                                        )
                                      }
                                      isDisabled={
                                        !is.nonEmptyString(
                                          partnerPenalty.penaltyId,
                                        )
                                      }
                                    />
                                  );
                                }
                              }
                              if (is.nonEmptyObject(partnerPenalty.payment)) {
                                return (
                                  <a
                                    href={`${
                                      process.env.API_LIVE_URL
                                    }/hub/graphql/receipts/repayment-receipt-${
                                      fromGlobalId(partnerPenalty.payment.id).id
                                    }.pdf`}
                                    onClick={() => {
                                      ym('reachGoal', 'HubDownloadRecipe');
                                    }}
                                  >
                                    Скачать квитанцию
                                  </a>
                                );
                              }

                              return <></>;
                            })()}
                          </>
                        ),
                      },
                    ],
                  }))
                : []
            }
            emptyView={
              <SectionMessage
                appearance={this.props.error ? 'error' : 'warning'}
              >
                {this.props.error
                  ? messageForApolloError(this.props.error)
                  : 'Ничего не найдено'}
              </SectionMessage>
            }
            loadingSpinnerSize="large"
            isLoading={this.props.loading}
          />
        </TableContainer>
      </>
    );
  }
}
