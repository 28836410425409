import {Link} from '@reach/router';
import {gridSize} from '@atlaskit/theme';
import styled from 'styled-components';

export const UnderlinelessLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const TableContainer = styled.div`
  margin: ${gridSize() * 3}px 0;
`;

export const InlineFormWrapper = styled.div`
  width: 100%;
  max-width: 400px;

  /* HACK: fix for https://bitbucket.org/atlassian/atlaskit-mk-2/issues/111/form-213-215-has-added-min-height-100vh-to#comment-46929399 */
  & > div:first-child {
    min-height: inherit;
  }
`;

export const InlineModalFormWrapper = styled.div`
  width: 100%;
  margin: auto;
  height: 0%;

  /* HACK: fix for https://bitbucket.org/atlassian/atlaskit-mk-2/issues/111/form-213-215-has-added-min-height-100vh-to#comment-46929399 */
  & > div:first-child {
    min-height: inherit;
  }
`;

export const FormContainer = styled.div`
  padding: 2vw;
`;

export const FaqTitleFieldContainer = styled.div`
  margin-bottom: 2rem;
`;
