import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {QueryDocument, MutationDocument} from 'src/utils/graphql';

import {AllPenaltyPaymentsTable} from './components/AllPenaltyPaymentsTable';

export const ALL_PENALTY_PAYMENTS_FOR_ADMIN_QUERY: QueryDocument<
  G.allPenaltyPaymentsForAdmin,
  G.allPenaltyPaymentsForAdminVariables
> = gql`
  query allPenaltyPaymentsForAdmin(
    $after: String
    $first: Int
    $direction: PaymentDirection!
    $searchBy: PenaltyPaymentSearchBy!
    $searchText: String
    $filters: PenaltyPaymentFilters
  ) {
    allPayments(
      after: $after
      first: $first
      direction: $direction
      searchBy: $searchBy
      searchText: $searchText
      filters: $filters
      type: PENALTY
    ) {
      edges {
        node {
          ...AllPenaltyPaymentsTable_payment
        }
      }
      totalPageCount
    }
  }
  ${AllPenaltyPaymentsTable.fragments.payment}
`;

export const RESTART_PENALTY_PAYMENTS_FOR_ADMIN_MUTATION: MutationDocument<
  G.restartPenaltyPaymentsForAdmin,
  G.restartPenaltyPaymentsForAdminVariables
> = gql`
  mutation restartPenaltyPaymentsForAdmin(
    $input: PenaltyRepaymentRestartInput!
  ) {
    penaltyRepaymentRestart(input: $input) {
      restartedPaymentIds
    }
  }
`;

export const PENALTY_PAYMENT_REFUND_MUTATION: MutationDocument<
  G.penaltyPaymentRefund,
  G.penaltyPaymentRefundVariables
> = gql`
  mutation penaltyPaymentRefund($input: PaymentRefundInput!) {
    paymentRefund(input: $input) {
      refund
    }
  }
`;
