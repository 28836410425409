import {ApolloError} from 'apollo-client';
import {State} from 'react-powerplug';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import React from 'react';
import styled from 'styled-components';

import {G} from 'src/types/graphql';

import {PartnerEditOrCreateModalDialog} from './PartnerEditOrCreateModalDialog';

const MoreDropdownMenuWrapper = styled.div`
  width: 36;
  height: 32;
`;

interface MoreDropdownMenuProps {
  partner: G.AllPartnersTable_partners_edges_node;
}

export const PartnerDropdownMenu: React.FunctionComponent<
  MoreDropdownMenuProps
> = (props: MoreDropdownMenuProps) => (
  <State<{
    isEditOrCreateModalOpen: boolean;
    partnerEditOrCreateError: ApolloError | null;
  }>
    initial={{isEditOrCreateModalOpen: false, partnerEditOrCreateError: null}}
  >
    {({state, setState}) => {
      return (
        <MoreDropdownMenuWrapper>
          {
            <>
              <DropdownMenu
                triggerType="button"
                triggerButtonProps={{
                  iconAfter: <MoreVerticalIcon label="Дополнительно" />,
                  appearance: 'subtle',
                  shouldFitContainer: true,
                }}
                position="bottom right"
              >
                <DropdownItemGroup>
                  <DropdownItem
                    onClick={() => setState({isEditOrCreateModalOpen: true})}
                  >
                    Редактировать
                  </DropdownItem>
                </DropdownItemGroup>
              </DropdownMenu>
              <PartnerEditOrCreateModalDialog
                partnerEditOrCreateError={state.partnerEditOrCreateError}
                setPartnerEditOrCreateErrorState={partnerEditOrCreateError =>
                  setState({partnerEditOrCreateError})
                }
                isEditOrCreateModalOpen={state.isEditOrCreateModalOpen}
                setEditOrCreateModalOpenState={isEditOrCreateModalOpen =>
                  setState({isEditOrCreateModalOpen})
                }
                partner={props.partner}
              />
            </>
          }
        </MoreDropdownMenuWrapper>
      );
    }}
  </State>
);
