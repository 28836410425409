import {colors} from '@atlaskit/theme';
import {navigate} from '@reach/router';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {VIEWER_QUERY} from 'src/containers/AppPageWithViewer';
import {apolloClient, messageForApolloError} from 'src/apollo';

import {AccountDropdownMenu} from '../components/AccountDropdownMenu';
import {LOG_OUT_MUTATION} from '../graphql';

interface AccountDropdownMenuContainerProps {
  viewer: G.AppNavigation_viewer;
}

export const AccountDropdownMenuContainer: React.FunctionComponent<
  AccountDropdownMenuContainerProps
> = props => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Mutation
        mutation={LOG_OUT_MUTATION} // provide a noop here, otherwise an unhandled error is thrown
        onCompleted={() => {
          apolloClient.resetStore();
          apolloClient.writeQuery({
            query: VIEWER_QUERY,
            data: {viewer: null},
          });

          navigate('/login');
        }}
        onError={err => {
          onAddFlag({
            autoDismiss: true,
            icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
            title: 'Произошла ошибка',
            description: `Не удалось выйти из системы: ${messageForApolloError(
              err,
            )}`,
          });
        }}
      >
        {(logOut, {loading: loggingOut}) => (
          <AccountDropdownMenu
            viewer={props.viewer}
            onLogOut={logOut}
            loggingOut={loggingOut}
          />
        )}
      </Mutation>
    )}
  </FlagsConsumer>
);
