import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {compact} from 'shared/utils/array';
import {formatIsoDateTime} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

interface AllFaqsCategoryTableProps {
  debouncedSearchText: string;
  loading: boolean;
  error?: ApolloError;
  data?: G.allFaqsForAdmin;
  MoreCellComponent: React.ComponentType<{faq: G.AllFaqsTable_faq}>;
}

export class AllFaqsCategoryTable extends React.Component<
  AllFaqsCategoryTableProps
> {
  public static fragments = {
    faq: gql`
      fragment AllFaqsTable_faq on Faq {
        id
        createdAt
        updatedAt
        title
        markdownContent
        voicesNegative
        voicesPositive
        archivedAt
      }
    `,
  };
  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: compact([
              {
                key: 'title',
                content: 'Заголовок',
              },
              {
                key: 'voicesPositive',
                content: 'Положительные голоса',
              },
              {
                key: 'voicesNegative',
                content: 'Отрицательные голоса',
              },
              {
                key: 'createdAt',
                content: 'Создано',
              },
              {
                key: 'actual',
                content: 'Актуально',
              },
              {
                key: 'more',
              },
            ]),
          }}
          rows={
            this.props.data && this.props.data.allFaqs
              ? nodesFromConnection(this.props.data.allFaqs).map(faq => {
                  return {
                    key: faq.id,
                    cells: compact([
                      {
                        key: faq.title,
                        content: faq.title,
                      },
                      {
                        key: faq.voicesPositive,
                        content: faq.voicesPositive,
                      },
                      {
                        key: faq.voicesNegative,
                        content: faq.voicesNegative,
                      },
                      {
                        key: faq.createdAt,
                        content: formatIsoDateTime(faq.createdAt),
                      },
                      {
                        key: faq.archivedAt,
                        content: (
                          <Lozenge
                            appearance={
                              is.nil(faq.archivedAt) ? 'success' : 'removed'
                            }
                          >
                            {is.nil(faq.archivedAt) ? 'да' : 'нет'}
                          </Lozenge>
                        ),
                      },
                      {
                        content: <this.props.MoreCellComponent faq={faq} />,
                      },
                    ]),
                  };
                })
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
      </TableContainer>
    );
  }
}
