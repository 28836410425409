import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button, {ButtonGroup} from '@atlaskit/button';
import PageHeader from '@atlaskit/page-header';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UnderlinelessLink} from 'src/components/styled';
import {UserRole} from 'src/types/auth';

import {AdminNavigation} from '../navigation';
import {AllHubUsersTableContainer} from './containers/AllHubUsersTableContainer';

interface AllHubUsersPageParams {}
interface AllHubUsersPageProps
  extends RouteComponentProps<AllHubUsersPageParams> {}

export const AllHubUsersPage: React.FunctionComponent<
  AllHubUsersPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={AdminNavigation}
    roles={[UserRole.Superadmin]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PageHeader
          actions={
            <ButtonGroup>
              <UnderlinelessLink to="/admin/hub-users/create">
                <Button
                  appearance="primary"
                  iconBefore={<AddIcon label="Создать" />}
                >
                  Новый админ
                </Button>
              </UnderlinelessLink>
            </ButtonGroup>
          }
        >
          Админы
        </PageHeader>
        <AllHubUsersTableContainer />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
