import {RouteComponentProps, navigate} from '@reach/router';
import React from 'react';

import {FullscreenForm} from 'src/components/FullscreenForm';
import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';
import {is} from 'shared/utils/data-types';

import {HubUserCreateFormContainer} from './containers/HubUserCreateFormContainer';

interface HubUserCreatePageParams {}
interface HubUserCreatePageProps
  extends RouteComponentProps<HubUserCreatePageParams> {}

export const HubUserCreatePage: React.FunctionComponent<
  HubUserCreatePageProps
> = props => (
  <ProtectedAppPage location={props.location} roles={[UserRole.Superadmin]}>
    <FullscreenForm>
      <HubUserCreateFormContainer
        onHubUserCreated={() => {
          navigate('/admin/hub-users');
        }}
        onCancel={() => {
          navigate(
            is.nonEmptyString(document.referrer) &&
              !document.referrer.endsWith('hub-users/create')
              ? document.referrer
              : '/',
          );
        }}
      />
    </FullscreenForm>
  </ProtectedAppPage>
);
