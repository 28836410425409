import {ApolloError} from 'apollo-client';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button/dist/es5/components/Button';
import React from 'react';

import {AllPartnersTableContainer} from './AllPartnersTableContainer';
import {PartnerEditOrCreateModalDialog} from './PartnerEditOrCreateModalDialog';

interface AllPartnersContainerProps {
  location: Window['location'] | undefined;
}
interface AllPartnersContainerState {
  location?: Window['location'];
  isEditOrCreateModalOpen: boolean;
  partnerEditOrCreateError: ApolloError | null;
}

export class AllPartnersContainer extends React.Component<
  AllPartnersContainerProps,
  AllPartnersContainerState
> {
  public state: AllPartnersContainerState = {
    isEditOrCreateModalOpen: false,
    partnerEditOrCreateError: null,
  };

  public render() {
    const openEditOrCreateModal = () =>
      this.setState({isEditOrCreateModalOpen: true});

    return (
      <>
        <Button
          appearance="primary"
          type="button"
          onClick={openEditOrCreateModal}
          iconBefore={<AddIcon label="Создать" />}
        >
          Добавить партнера
        </Button>
        <PartnerEditOrCreateModalDialog
          partnerEditOrCreateError={this.state.partnerEditOrCreateError}
          setPartnerEditOrCreateErrorState={partnerEditOrCreateError =>
            this.setState({partnerEditOrCreateError})
          }
          isEditOrCreateModalOpen={this.state.isEditOrCreateModalOpen}
          setEditOrCreateModalOpenState={isEditOrCreateModalOpen =>
            this.setState({isEditOrCreateModalOpen})
          }
        />
        <AllPartnersTableContainer />
      </>
    );
  }
}
