import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Pagination from '@atlaskit/pagination';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {formatIsoDateTime} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

interface AllHubUsersTableProps {
  pageIdx: number;
  onSetPageIdx: (pageIdx: number) => any;
  MoreCellComponent: React.ComponentType<{hubUser: G.AllHubUsersTable_user}>;
  loading: boolean;
  error?: ApolloError;
  data?: G.allHubUsersForAdmin;
}

export class AllHubUsersTable extends React.Component<AllHubUsersTableProps> {
  public static fragments = {
    user: gql`
      fragment AllHubUsersTable_user on User {
        id
        createdAt
        role
        hubUsername
        hubAccessible
        partnerName
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: [
              {
                key: 'role',
                content: 'Роль',
              },
              {
                key: 'hubUsername',
                content: 'Имя пользователя',
              },
              {
                key: 'hubAccessible',
                content: 'Доступ',
              },
              {
                key: 'createdAt',
                content: 'Создано',
              },
              {
                key: 'partnerName',
                content: 'Партнер',
              },
              {
                key: 'more',
              },
            ],
          }}
          rows={
            this.props.data && this.props.data.allHubUsers
              ? nodesFromConnection(this.props.data.allHubUsers).map(
                  hubUser => ({
                    key: hubUser.id,
                    cells: [
                      {
                        key: hubUser.role,
                        content: (
                          <Lozenge appearance="default">{hubUser.role}</Lozenge>
                        ),
                      },
                      {
                        key: hubUser.hubUsername,
                        content: hubUser.hubUsername,
                      },
                      {
                        key: hubUser.hubAccessible,
                        content: (
                          <Lozenge
                            appearance={
                              hubUser.hubAccessible ? 'success' : 'removed'
                            }
                          >
                            {hubUser.hubAccessible ? 'есть' : 'нет'}
                          </Lozenge>
                        ),
                      },
                      {
                        key: hubUser.createdAt,
                        content: formatIsoDateTime(hubUser.createdAt),
                      },
                      {
                        key: hubUser.partnerName,
                        content: hubUser.partnerName,
                      },
                      {
                        content: (
                          <this.props.MoreCellComponent hubUser={hubUser} />
                        ),
                      },
                    ],
                  }),
                )
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
        {this.props.data &&
          this.props.data.allHubUsers &&
          is.positiveInt(this.props.data.allHubUsers.totalPageCount) && (
            <Pagination
              selectedIndex={this.props.pageIdx}
              pages={Array.from(
                {length: this.props.data.allHubUsers.totalPageCount},
                (_, idx) => idx + 1,
              )}
              onChange={(_event: Event, pageLabel: number) =>
                this.props.onSetPageIdx(pageLabel - 1)
              }
              i18n={{
                prev: 'Предыдущая',
                next: 'Следующая',
              }}
            />
          )}
      </TableContainer>
    );
  }
}
