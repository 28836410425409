import {colors} from '@atlaskit/theme';
import Button from '@atlaskit/button/dist/es5/components/Button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';
import ym from 'react-yandex-metrika';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {Mutation} from 'src/utils/react-apollo';
import {messageForApolloError} from 'src/apollo';

import {PARTNER_PENALTIES_MARK_DEPOSIT} from '../graphql';

interface PartnerPenaltiesForCarMarkDepositButtonContainerProps {
  onMarkDeposit: () => void;
  selectedPenaltyIds: string[];
}

export const PartnerPenaltiesForCarMarkDepositButtonContainer: React.FunctionComponent<
  PartnerPenaltiesForCarMarkDepositButtonContainerProps
> = props => (
  <FlagsConsumer>
    {({onAddFlag}) => (
      <Mutation
        mutation={PARTNER_PENALTIES_MARK_DEPOSIT}
        refetchQueries={['allPartnerPenaltiesForPartnerCar']}
        update={(_, result) => {
          if (!result.data || !result.data.partnerPenaltiesMarkDeposit) return;

          props.onMarkDeposit();
        }}
        onCompleted={() => {
          onAddFlag({
            autoDismiss: true,
            icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
            title: `Спасибо, поручение на платеж принято`,
          });
        }}
        onError={err => {
          onAddFlag({
            autoDismiss: true,
            icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
            title: 'Произошла ошибка',
            description: `Не удалось: ${messageForApolloError(err)}`,
          });
        }}
      >
        {partnerPenaltiesMarkDeposit => (
          <Button
            appearance="primary"
            isDisabled={props.selectedPenaltyIds.length === 0}
            onClick={() => {
              partnerPenaltiesMarkDeposit({
                variables: {
                  input: {
                    penaltyIds: props.selectedPenaltyIds,
                  },
                },
              });
              ym('reachGoal', 'HubSendForPayment');
            }}
          >
            Отправить на оплату
          </Button>
        )}
      </Mutation>
    )}
  </FlagsConsumer>
);
