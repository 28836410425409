import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {MutationDocument, QueryDocument} from 'src/utils/graphql';

import {AllDriversTable} from './components/AllDriversTable';

export const CREATE_PARTNER_DRIVER_MUTATION: MutationDocument<
  G.partnerDriverCreate,
  G.partnerDriverCreateVariables
> = gql`
  mutation partnerDriverCreate($input: PartnerDriverCreateInput!) {
    partnerDriverCreate(input: $input) {
      ...AllDriversTable_createdPartnerDriver
    }
  }
  ${AllDriversTable.fragments.createdPartnerDriver}
`;

export const DELETE_PARTNER_DRIVER_MUTATION: MutationDocument<
  G.partnerDriverDelete,
  G.partnerDriverDeleteVariables
> = gql`
  mutation partnerDriverDelete($input: PartnerDriverDeleteInput!) {
    partnerDriverDelete(input: $input) {
      ...AllDriversTable_deletedPartnerDriver
    }
  }
  ${AllDriversTable.fragments.deletedPartnerDriver}
`;

export const PARTNER_QUERY: QueryDocument<G.partner> = gql`
  query partner {
    partner {
      ...AllDriversTable_partner
    }
  }
  ${AllDriversTable.fragments.partner}
`;

export const ALL_DRIVERS_FOR_PARTNER_QUERY: QueryDocument<
  G.allPartnerDrivers,
  G.allPartnerDriversVariables
> = gql`
  query allPartnerDrivers($after: String, $first: Int) {
    allPartnerDrivers(after: $after, first: $first) {
      ...AllDriversTable_partnerDrivers
    }
  }
  ${AllDriversTable.fragments.partnerDrivers}
`;
