import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Pagination from '@atlaskit/pagination';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {currencyCentsToUnits} from 'shared/utils/currency';
import {formatIsoDateTime, formatCurrencyAmount} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

import {InsuranceApplicationStatus} from './InsuranceApplicationStatus';

interface AllInsuranceApplicationsTableProps {
  pageIdx: number;
  onSetPageIdx: (pageIdx: number) => any;
  loading: boolean;
  error?: ApolloError;
  data?: G.allInsuranceApplicationsForPartner;
  onInsuranceApplicationUpdate: (input: {
    id: string;
    status: G.InsuranceApplicationStatus;
  }) => void;
}

export class AllInsuranceApplicationsTable extends React.Component<
  AllInsuranceApplicationsTableProps
> {
  public static fragments = {
    insuranceApplication: gql`
      fragment AllInsuranceApplicationsTable_insuranceApplication on InsuranceApplication {
        id
        createdAt
        updatedAt
        fullAmount
        amount
        discountPercentage
        status

        insuranceApplicationCars {
          id
          carNumber
          data
        }
        insuranceApplicationIins {
          id
          iinNumber
          data
        }
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: [
              {
                key: 'createdAt',
                content: 'Заявка подана',
              },
              {
                key: 'drivers',
                content: 'Водители',
              },
              {
                key: 'cars',
                content: 'Машины',
              },
              {
                key: 'amount',
                content: 'Цена',
              },
              {
                key: 'discount',
                content: 'Скидка',
              },
              {
                key: 'discountedAmount',
                content: 'Цена со скидкой',
              },
              {
                key: 'status',
                content: 'Статус',
                width: 8,
              },
            ],
          }}
          rows={
            this.props.data && this.props.data.allInsuranceApplications
              ? nodesFromConnection(
                  this.props.data.allInsuranceApplications,
                ).map(insuranceApplication => ({
                  key: insuranceApplication.id,
                  cells: [
                    {
                      key: insuranceApplication.createdAt,
                      content: formatIsoDateTime(
                        insuranceApplication.createdAt,
                      ),
                    },
                    {
                      key: insuranceApplication.insuranceApplicationIins,
                      content: insuranceApplication.insuranceApplicationIins.map(
                        insuranceApplicationIin => (
                          <span key={insuranceApplicationIin.id}>
                            {insuranceApplicationIin.iinNumber} -{' '}
                            {insuranceApplicationIin.data.fullName}
                            <br />
                          </span>
                        ),
                      ),
                    },
                    {
                      key: insuranceApplication.insuranceApplicationCars,
                      content: insuranceApplication.insuranceApplicationCars.map(
                        insuranceApplicationCar => (
                          <span key={insuranceApplicationCar.id}>
                            {insuranceApplicationCar.carNumber} -{' '}
                            {insuranceApplicationCar.data.regionName} -{' '}
                            {insuranceApplicationCar.data.year}
                            <br />
                          </span>
                        ),
                      ),
                    },
                    {
                      key: insuranceApplication.fullAmount,
                      content: formatCurrencyAmount(
                        currencyCentsToUnits(insuranceApplication.fullAmount),
                      ),
                    },
                    {
                      key: insuranceApplication.discountPercentage,
                      content: insuranceApplication.discountPercentage,
                    },
                    {
                      content: formatCurrencyAmount(
                        currencyCentsToUnits(insuranceApplication.amount),
                      ),
                    },
                    {
                      key: insuranceApplication.status,
                      content: (
                        <InsuranceApplicationStatus
                          insuranceApplication={insuranceApplication}
                          onInsuranceApplicationStatusUpdate={status => {
                            this.props.onInsuranceApplicationUpdate({
                              id: insuranceApplication.id,
                              status,
                            });
                          }}
                        />
                      ),
                    },
                  ],
                }))
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
        {this.props.data &&
          this.props.data.allInsuranceApplications &&
          is.positiveInt(
            this.props.data.allInsuranceApplications.totalPageCount,
          ) && (
            <Pagination
              selectedIndex={this.props.pageIdx}
              pages={Array.from(
                {
                  length: this.props.data.allInsuranceApplications
                    .totalPageCount,
                },
                (_, idx) => idx + 1,
              )}
              onChange={(_event: Event, pageLabel: number) =>
                this.props.onSetPageIdx(pageLabel - 1)
              }
              i18n={{
                prev: 'Предыдущая',
                next: 'Следующая',
              }}
            />
          )}
      </TableContainer>
    );
  }
}
