import {ApolloError} from 'apollo-client';
import {Checkbox} from '@atlaskit/checkbox';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Pagination from '@atlaskit/pagination';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {compact} from 'shared/utils/array';
import {currencyCentsToUnits} from 'shared/utils/currency';
import {formatIsoDateTime, formatCurrencyAmount} from 'src/utils/formatting';
import {is} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

import {PaymentId} from './PaymentId';
import {PaymentMetaDetails} from './PaymentMetaDetails';
import {PaymentPenaltyInfo} from './PaymentPenaltyInfo';
import {PaymentUserInfo} from './PaymentUserInfo';

interface AllPenaltyPaymentsTableProps {
  direction: G.PaymentDirection;
  pageIdx: number;
  onSetPageIdx: (pageIdx: number) => any;
  searchBy: G.PenaltyPaymentSearchBy;
  debouncedSearchText: string;
  loading: boolean;
  error?: ApolloError;
  data?: G.allPenaltyPaymentsForAdmin;
  MoreCellComponent: React.ComponentType<{
    payment: G.allPenaltyPaymentsForAdmin_allPayments_edges_node;
    direction: G.PaymentDirection;
  }>;
  selectedPenaltyIds: string[];
  onChangeCheckbox: (paymentId: string) => any;
}

export class AllPenaltyPaymentsTable extends React.Component<
  AllPenaltyPaymentsTableProps
> {
  public static fragments = {
    payment: gql`
      fragment AllPenaltyPaymentsTable_payment on Payment {
        id
        createdAt
        updatedAt
        user {
          id
          token
        }
        items {
          penalty {
            shekteuAltId
            iinNumber
            carNumber
            carTechPassport
            regressing
            violator {
              firstName
              lastName
            }
            violation {
              date
            }
            protocol {
              blankNumber
            }
          }
        }
        amountInCents
        feeInCents
        currency
        type
        provider
        status
        paidAmount
        paidAt
        refundedAt
        rawData
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: compact([
              {
                key: 'id',
                content: 'ID',
              },
              {
                key: 'data.description',
                content: 'Описание',
              },
              {
                key: 'penaltyInfo',
                content: 'Штраф',
              },
              {
                key: 'metaDetails',
                content: 'Детали платежа',
              },
              {
                key: 'userInfo',
                content: 'Пользователь',
              },
              {
                key: 'status',
                content: 'Статус',
              },
              {
                key: 'amount',
                content: 'Сумма без комиссии',
              },
              {
                key: 'provider',
                content: 'Провайдер',
              },
              {
                key: 'createdAt',
                content: 'Создано',
              },
              {
                key: 'paidAt',
                content:
                  this.props.direction === G.PaymentDirection.OUTGOING
                    ? 'Погашено'
                    : 'Оплачено',
              },
              {
                key: 'more',
              },
              {
                key: 'batch',
              },
            ]),
          }}
          rows={
            this.props.data && this.props.data.allPayments
              ? nodesFromConnection(this.props.data.allPayments).map(
                  payment => {
                    const penaltyInfo =
                      payment.items.length !== 0 && payment.items[0].penalty
                        ? payment.items[0].penalty
                        : null;

                    return {
                      key: payment.id,
                      cells: compact([
                        {
                          key: payment.id,
                          content: (
                            <PaymentId
                              payment={payment}
                              direction={this.props.direction}
                              searchBy={this.props.searchBy}
                              searchText={this.props.debouncedSearchText}
                            />
                          ),
                        },
                        {
                          key: (payment.rawData as any).description,
                          content: (payment.rawData as any).description,
                        },
                        {
                          content: penaltyInfo ? (
                            <PaymentPenaltyInfo
                              paymentPenalty={penaltyInfo}
                              searchBy={this.props.searchBy}
                              searchText={this.props.debouncedSearchText}
                            />
                          ) : null,
                        },
                        {
                          content: (
                            <PaymentMetaDetails
                              paymentData={payment.rawData as any}
                              direction={this.props.direction}
                              searchBy={this.props.searchBy}
                              searchText={this.props.debouncedSearchText}
                            />
                          ),
                        },
                        {
                          content: (
                            <PaymentUserInfo paymentUser={payment.user} />
                          ),
                        },
                        {
                          key: payment.status,
                          content: (
                            <Lozenge
                              appearance={(() => {
                                if (
                                  payment.status === G.PaymentStatus.SUCCEEDED
                                ) {
                                  return 'success';
                                }
                                if (payment.status === G.PaymentStatus.FAILED) {
                                  return 'removed';
                                }

                                return 'default';
                              })()}
                            >
                              {payment.status}
                            </Lozenge>
                          ),
                        },
                        {
                          key: payment.amountInCents,
                          content: formatCurrencyAmount(
                            currencyCentsToUnits(payment.amountInCents),
                            {currency: payment.currency},
                          ),
                        },
                        {
                          key: payment.provider,
                          content: (
                            <Lozenge appearance="default">
                              {payment.provider}
                            </Lozenge>
                          ),
                        },
                        {
                          key: payment.createdAt,
                          content: formatIsoDateTime(payment.createdAt),
                        },
                        {
                          key: payment.paidAt,
                          content: is.nonEmptyString(payment.paidAt) ? (
                            <span>
                              {formatIsoDateTime(payment.paidAt)}
                              {penaltyInfo && penaltyInfo.regressing && (
                                <>
                                  <br />
                                  <Lozenge appearance="removed">
                                    Штраф не удалился
                                  </Lozenge>
                                </>
                              )}
                            </span>
                          ) : (
                            <Lozenge appearance="removed">Нет</Lozenge>
                          ),
                        },
                        {
                          content: (
                            <this.props.MoreCellComponent
                              payment={payment}
                              direction={this.props.direction}
                            />
                          ),
                        },
                        {
                          content: payment.status ===
                            G.PaymentStatus.FAILED && (
                            <Checkbox
                              isChecked={this.props.selectedPenaltyIds.includes(
                                payment.id,
                              )}
                              onChange={() =>
                                this.props.onChangeCheckbox(payment.id)
                              }
                            />
                          ),
                        },
                      ]),
                    };
                  },
                )
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
        {this.props.data &&
          this.props.data.allPayments &&
          is.positiveInt(this.props.data.allPayments.totalPageCount) && (
            <Pagination
              selectedIndex={this.props.pageIdx}
              pages={Array.from(
                {length: this.props.data.allPayments.totalPageCount},
                (_, idx) => idx + 1,
              )}
              onChange={(_event: Event, pageLabel: number) =>
                this.props.onSetPageIdx(pageLabel - 1)
              }
              i18n={{
                prev: 'Предыдущая',
                next: 'Следующая',
              }}
            />
          )}
      </TableContainer>
    );
  }
}
