import {colors} from '@atlaskit/theme';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import React from 'react';
import Tabs from '@atlaskit/tabs';
import debounce from 'lodash.debounce';
import qs from 'query-string';

import {FlagsConsumer} from 'src/containers/FlagsContext';
import {G} from 'src/types/graphql';
import {Mutation} from 'src/utils/react-apollo';
import {is, Opt} from 'shared/utils/data-types';
import {messageForApolloError} from 'src/apollo';
import {updateCurrentLocationQuery} from 'src/utils/location';

import {AllPenaltyPaymentsSearchForm} from '../components/AllPenaltyPaymentsSearchForm';
import {AllPenaltyPaymentsTableContainer} from './AllPenaltyPaymentsTableContainer';
import {RESTART_PENALTY_PAYMENTS_FOR_ADMIN_MUTATION} from '../graphql';

const PAYMENT_DIRECTION_TO_TAB_LABEL = {
  [G.PaymentDirection.OUTGOING]: 'Платежи для погашения',
  [G.PaymentDirection.INCOMING]: 'Платежи от пользователей',
};
const PAYMENT_DIRECTIONS_FOR_TABS = [
  G.PaymentDirection.OUTGOING,
  G.PaymentDirection.INCOMING,
];
const SEARCH_DEBOUNCE_DELAY = 350;

interface AllPenaltyPaymentsContainerProps {
  location: Window['location'] | undefined;
}
interface AllPenaltyPaymentsContainerState {
  location?: Window['location'];
  selectedTabIdx: number;
  searchBy: G.PenaltyPaymentSearchBy;
  searchText: string;
  debouncedSearchText: string;
  filters: G.PenaltyPaymentFilters;
  selectedPenaltyIds: string[];
}

export class AllPenaltyPaymentsContainer extends React.Component<
  AllPenaltyPaymentsContainerProps,
  AllPenaltyPaymentsContainerState
> {
  public state: AllPenaltyPaymentsContainerState = {
    selectedTabIdx: 0,
    searchBy: G.PenaltyPaymentSearchBy.IIN_NUMBER,
    searchText: '',
    debouncedSearchText: '',
    filters: {
      forRegressingPenaltiesOnly: false,
      forStuckPaymentsOnly: false,
    },
    selectedPenaltyIds: [],
  };

  public static getDerivedStateFromProps(
    props: AllPenaltyPaymentsContainerProps,
    state: AllPenaltyPaymentsContainerState,
  ) {
    if (props.location && props.location !== state.location) {
      const locationQuery: {
        direction?: G.PaymentDirection;
        searchBy?: G.PenaltyPaymentSearchBy;
        searchText?: string;
        forRegressingPenaltiesOnly?: string;
      } & {[key: string]: unknown} = qs.parse(props.location.search);

      const selectedTabIdx =
        is.nonEmptyString(locationQuery.direction) &&
        Object.values(G.PaymentDirection).includes(locationQuery.direction)
          ? PAYMENT_DIRECTIONS_FOR_TABS.indexOf(locationQuery.direction)
          : 0;
      const searchBy =
        is.nonEmptyString(locationQuery.searchBy) &&
        Object.values(G.PenaltyPaymentSearchBy).includes(locationQuery.searchBy)
          ? locationQuery.searchBy
          : G.PenaltyPaymentSearchBy.IIN_NUMBER;
      const searchText = Opt.nonEmptyString(locationQuery.searchText).getOrElse(
        '',
      );
      const filters = {
        forRegressingPenaltiesOnly:
          locationQuery.forRegressingPenaltiesOnly === 'true',
        forStuckPaymentsOnly: locationQuery.forStuckPaymentsOnly === 'true',
      };

      return {
        location: props.location,
        selectedTabIdx,
        searchBy,
        searchText,
        debouncedSearchText: searchText,
        filters,
      };
    }

    return null;
  }

  public componentDidUpdate(
    _prevProps: AllPenaltyPaymentsContainerProps,
    prevState: AllPenaltyPaymentsContainerState,
  ) {
    if (
      this.state.selectedTabIdx !== prevState.selectedTabIdx ||
      this.state.searchBy !== prevState.searchBy ||
      this.state.debouncedSearchText !== prevState.debouncedSearchText ||
      this.state.filters.forRegressingPenaltiesOnly !==
        prevState.filters.forRegressingPenaltiesOnly ||
      this.state.filters.forStuckPaymentsOnly !==
        prevState.filters.forStuckPaymentsOnly
    ) {
      updateCurrentLocationQuery({
        direction: PAYMENT_DIRECTIONS_FOR_TABS[this.state.selectedTabIdx],
        searchBy: this.state.searchBy,
        searchText: Opt.nonEmptyString(
          this.state.debouncedSearchText,
        ).toUndefined(),
        forRegressingPenaltiesOnly: this.state.filters
          .forRegressingPenaltiesOnly,
        forStuckPaymentsOnly: this.state.filters.forStuckPaymentsOnly,
      });
    }
    if (this.state.searchText !== prevState.searchText) {
      this.handleSetDebouncedSearchText(this.state.searchText);
    }
  }

  private handleSetDebouncedSearchText = debounce(searchText => {
    this.setState({debouncedSearchText: searchText});
  }, SEARCH_DEBOUNCE_DELAY);

  public render() {
    return (
      <>
        <FlagsConsumer>
          {({onAddFlag}) => (
            <Mutation
              mutation={RESTART_PENALTY_PAYMENTS_FOR_ADMIN_MUTATION}
              update={(cache, result) => {
                if (!result.data || !result.data.penaltyRepaymentRestart)
                  return;

                result.data.penaltyRepaymentRestart.restartedPaymentIds.forEach(
                  paymentId => {
                    cache.writeData<{status: G.PaymentStatus}>({
                      id: paymentId,
                      data: {
                        status: G.PaymentStatus.CREATED,
                      },
                    });
                  },
                );
                this.setState({selectedPenaltyIds: []});
              }}
              onCompleted={() => {
                onAddFlag({
                  autoDismiss: true,
                  icon: <CheckIcon label="Успех" primaryColor={colors.G400} />,
                  title: `успешно выполнен`,
                });
              }}
              onError={err => {
                onAddFlag({
                  autoDismiss: true,
                  icon: <ErrorIcon label="Ошибка" primaryColor={colors.R300} />,
                  title: 'Произошла ошибка',
                  description: `Не удалось: ${messageForApolloError(err)}`,
                });
              }}
            >
              {penaltyRepaymentRestart => (
                <AllPenaltyPaymentsSearchForm
                  searchBy={this.state.searchBy}
                  onSetSearchBy={searchBy => {
                    this.setState({searchBy});
                  }}
                  searchText={this.state.searchText}
                  onSetSearchText={searchText => {
                    this.setState({searchText});
                  }}
                  filters={this.state.filters}
                  onSetFilters={filters => {
                    this.setState({filters});
                  }}
                  disabledFilterKeys={
                    this.state.selectedTabIdx === 1
                      ? ['forRegressingPenaltiesOnly']
                      : []
                  }
                  restartRepaymentButton={this.state.selectedTabIdx === 0}
                  selectedPenaltyIds={this.state.selectedPenaltyIds}
                  onClickResendPayments={penaltyRepaymentRestart}
                />
              )}
            </Mutation>
          )}
        </FlagsConsumer>
        <Tabs
          selected={this.state.selectedTabIdx}
          tabs={PAYMENT_DIRECTIONS_FOR_TABS.map(direction => ({
            label: PAYMENT_DIRECTION_TO_TAB_LABEL[direction],
            content: (
              <AllPenaltyPaymentsTableContainer
                direction={direction}
                searchBy={this.state.searchBy}
                debouncedSearchText={this.state.debouncedSearchText}
                filters={this.state.filters}
                selectedPenaltyIds={this.state.selectedPenaltyIds}
                onChangeCheckbox={paymentId => {
                  this.setState(prevState => {
                    const newSelectedPenaltyIds = prevState.selectedPenaltyIds.includes(
                      paymentId,
                    )
                      ? prevState.selectedPenaltyIds.filter(
                          selectedPenaltyId => selectedPenaltyId !== paymentId,
                        )
                      : [...prevState.selectedPenaltyIds, paymentId];

                    return {
                      selectedPenaltyIds: newSelectedPenaltyIds,
                    };
                  });
                }}
              />
            ),
          }))}
          onSelect={(
            _data: {label: string; content: React.ReactNode},
            tabIdx: number,
          ) => {
            this.setState({selectedTabIdx: tabIdx});
          }}
        />
      </>
    );
  }
}
