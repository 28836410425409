import {render} from 'react-dom';
import React from 'react';

import {Root} from './Root';

render(<Root />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.WHY_DID_YOU_UPDATE === 'true'
) {
  const {whyDidYouUpdate} = require('why-did-you-update'); // eslint-disable-line global-require, import/no-extraneous-dependencies

  whyDidYouUpdate(React);
}
