import {gridSize} from '@atlaskit/theme';
import React from 'react';
import styled from 'styled-components';

import {SearchBar} from 'src/components/SearchBar';

const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSize()}px;
  margin-right: ${gridSize() * 3}px;
  flex: 1;
`;
const SearchBarWrapper = styled.div`
  display: flex;
`;

interface AllCarsSearchFormProps {
  searchText: string;
  onSetSearchText: (searchText: string) => any;
}

export const AllCarsSearchForm: React.FunctionComponent<
  AllCarsSearchFormProps
> = props => (
  <SearchFormContainer>
    <SearchBarWrapper>
      <SearchBar
        value={props.searchText}
        onChange={event => {
          const untrimmedSearchText = event.target.value;

          props.onSetSearchText(untrimmedSearchText);
        }}
        shouldFitContainer
      />
    </SearchBarWrapper>
  </SearchFormContainer>
);
