import Icon, {IconProps} from '@atlaskit/icon';
import React from 'react';

import {Omit} from 'shared/ts-utils';

type YellowCarIconProps = Omit<IconProps, 'glyph' | 'dangerouslySetGlyph'>;

export const YellowCarIcon: React.FunctionComponent<
  YellowCarIconProps
> = props => (
  <Icon
    dangerouslySetGlyph={`
    <svg width="280" height="233" viewBox="0 0 280 233" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="140" cy="112" rx="140" ry="112" fill="url(#paint0_radial)" fill-opacity="0.12"/>
      <ellipse rx="134" ry="9" transform="matrix(1 0 0 -1 140 224)" fill="url(#paint1_radial)" fill-opacity="0.32"/>
      <g filter="url(#filter0_i)">
      <path d="M179.88 196.401V212.5C179.88 218.838 185.043 224 191.382 224H199.029C205.368 224 210.531 218.838 210.531 212.5V196.336C210.204 196.401 207.035 196.336 207.035 196.336L179.88 196.401Z" fill="#607D8B"/>
      </g>
      <path d="M232.164 125.923L221.576 124.943C218.439 124.682 215.694 127.165 215.694 130.301V135.202C215.694 138.207 218.112 140.56 221.053 140.56H231.641C234.647 140.56 237 138.142 237 135.202V131.281C237 128.537 234.909 126.185 232.164 125.923Z" fill="#FFC107"/>
      <path d="M62.3711 130.301C62.3711 127.165 59.6262 124.616 56.4892 124.943L45.9016 125.923C43.1567 126.185 41 128.471 41 131.281V135.202C41 138.207 43.4181 140.56 46.3591 140.56H56.9466C59.953 140.56 62.3058 138.142 62.3058 135.202V130.301H62.3711Z" fill="#FFC107"/>
      <g filter="url(#filter1_i)">
      <path d="M67.5342 196.336V212.5C67.5342 218.838 72.6972 224 79.0367 224H86.6832C93.0227 224 98.1857 218.838 98.1857 212.5V196.401H68.4491C68.1224 196.401 67.861 196.336 67.5342 196.336Z" fill="#607D8B"/>
      </g>
      <path d="M151.45 107.824C151.45 105.276 149.424 103.25 146.875 103.25H131.19C128.641 103.25 126.615 105.276 126.615 107.824C126.615 110.372 128.641 112.398 131.19 112.398H146.875C149.424 112.398 151.45 110.372 151.45 107.824Z" fill="#607D8B"/>
      <path d="M207.002 130.105L199.486 103.381C197.264 95.4744 190.532 89.5937 182.363 88.5483C169.815 86.9148 154.979 86 139.098 86C123.151 86 108.316 86.9148 95.8329 88.5483C87.6635 89.5937 80.9319 95.4091 78.7099 103.381L71.0633 130.105C70.083 133.568 69.2987 137.097 68.7759 140.625C64.7892 145.134 62.3711 151.08 62.3711 157.548V187.278C62.3711 193.878 67.7302 199.17 74.2657 199.17H203.734C210.335 199.17 215.629 193.812 215.629 187.278V157.548C215.629 151.08 213.211 145.134 209.224 140.625C208.701 137.097 207.982 133.568 207.002 130.105ZM91.1927 106.909C92.0423 104.034 94.4605 101.878 97.4014 101.551C109.819 99.9829 124.197 99.1335 139.033 99.1335C153.868 99.1335 168.246 99.9829 180.664 101.551C183.605 101.943 186.023 104.034 186.873 106.909L193.473 130.366C191.055 129.909 188.506 129.517 185.892 129.125C183.147 121.676 176.024 116.384 167.658 116.384C160.404 116.384 154.064 120.369 150.731 126.315C146.941 126.185 143.019 126.119 139.033 126.119C118.054 126.119 98.97 127.753 84.5918 130.366L91.1927 106.909ZM86.8139 163.364C80.5398 163.364 75.4421 158.267 75.4421 151.994C75.4421 145.722 80.5398 140.625 86.8139 140.625C93.088 140.625 98.1857 145.722 98.1857 151.994C98.1857 158.267 93.088 163.364 86.8139 163.364ZM158.639 126.577C160.992 124.29 164.129 122.918 167.658 122.918C171.906 122.918 175.632 125.009 177.984 128.145C171.972 127.491 165.501 126.969 158.639 126.577ZM191.251 163.364C184.977 163.364 179.88 158.267 179.88 151.994C179.88 145.722 184.977 140.625 191.251 140.625C197.525 140.625 202.623 145.722 202.623 151.994C202.623 158.267 197.525 163.364 191.251 163.364Z" fill="url(#paint2_linear)"/>
      <g filter="url(#filter2_i)">
      <circle cx="87" cy="152" r="12" fill="#FAFAFA"/>
      <circle cx="87" cy="152" r="12" fill="#FFA000"/>
      </g>
      <g filter="url(#filter3_i)">
      <circle cx="192" cy="152" r="12" fill="#FAFAFA"/>
      <circle cx="192" cy="152" r="12" fill="#FFA000"/>
      </g>
      <ellipse cx="87" cy="151" rx="12" ry="11" fill="#FFC106"/>
      <ellipse cx="192" cy="151" rx="12" ry="11" fill="#FFC106"/>
      <ellipse cx="139.402" cy="150.348" rx="3.88889" ry="3.8764" fill="#FFA000"/>
      <ellipse cx="139.5" cy="177" rx="5.5" ry="5" fill="#DF8C00"/>
      <defs>
      <filter id="filter0_i" x="179.88" y="196.336" width="30.6515" height="31.6641" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
      </filter>
      <filter id="filter1_i" x="67.5342" y="196.336" width="30.6516" height="31.6641" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="3"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
      </filter>
      <filter id="filter2_i" x="75" y="139" width="24" height="25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="-1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.24 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
      </filter>
      <filter id="filter3_i" x="180" y="139" width="24" height="25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="-1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.24 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
      </filter>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(140 112) rotate(90) scale(112 140)">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(134 9) rotate(90) scale(9 134)">
      <stop stop-color="#333333"/>
      <stop offset="1" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint2_linear" x1="139" y1="86" x2="139" y2="199.17" gradientUnits="userSpaceOnUse">
      <stop offset="0.705608" stop-color="#FFC107"/>
      <stop offset="1" stop-color="#FFA000"/>
      </linearGradient>
      </defs>
      </svg>
      `}
    {...props}
  />
);
