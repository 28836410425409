import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {TableContainer} from 'src/components/styled';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

interface AllDriversTableProps {
  loading: boolean;
  error?: ApolloError;
  data?: G.allPartnerDrivers;
  DeleteButton: React.ComponentType<{
    partnerDriver: G.allPartnerDrivers_allPartnerDrivers_edges_node;
  }>;
}

export class AllDriversTable extends React.Component<AllDriversTableProps> {
  public static fragments = {
    partner: gql`
      fragment AllDriversTable_partner on Partner {
        id
        name
        driversCount
      }
    `,
    partnerDrivers: gql`
      fragment AllDriversTable_partnerDrivers on PartnerDriverConnection {
        edges {
          node {
            id
            name
            iinNumber
          }
        }
        totalPageCount
      }
    `,
    deletedPartnerDriver: gql`
      fragment AllDriversTable_deletedPartnerDriver on PartnerDriverDeletePayload {
        partnerDriver {
          id
        }
      }
    `,
    createdPartnerDriver: gql`
      fragment AllDriversTable_createdPartnerDriver on PartnerDriverCreatePayload {
        partnerDriver {
          id
          name
          iinNumber
        }
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: [
              {
                key: 'name',
                content: 'ФИО',
              },
              {
                key: 'iinNumber',
                content: 'ИИН',
              },
              {
                key: 'more',
              },
            ],
          }}
          rows={
            this.props.data && this.props.data.allPartnerDrivers
              ? nodesFromConnection(this.props.data.allPartnerDrivers).map(
                  partnerDriver => ({
                    key: partnerDriver.id,
                    cells: [
                      {
                        key: partnerDriver.name,
                        content: partnerDriver.name,
                      },
                      {
                        key: partnerDriver.iinNumber,
                        content: partnerDriver.iinNumber,
                      },
                      {
                        content: (
                          <this.props.DeleteButton
                            partnerDriver={partnerDriver}
                          />
                        ),
                      },
                    ],
                  }),
                )
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
      </TableContainer>
    );
  }
}
