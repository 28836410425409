import EmptyState from '@atlaskit/empty-state';
import MediaQuery from 'react-responsive';
import React from 'react';

interface FullscreenEmptyStateProps {
  header: string | React.ReactElement;
  description?: string | React.ReactElement;
  imageUrl?: string;
  maxImageWidth?: number;
  maxImageHeight?: number;
  primaryAction?: React.ReactElement;
  secondaryAction?: React.ReactElement;
  tertiaryAction?: React.ReactElement;
  isLoading?: boolean;
}

export const FullscreenEmptyState: React.FunctionComponent<
  FullscreenEmptyStateProps
> = props => (
  <MediaQuery query="(max-width: 704px)">
    {onMobile => <EmptyState size={onMobile ? 'narrow' : 'wide'} {...props} />}
  </MediaQuery>
);
