import {navigate} from '@reach/router';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import Highlighter from 'react-highlight-words';
import OpenIcon from '@atlaskit/icon/glyph/open';
import React from 'react';

import {G} from 'src/types/graphql';
import {fromGlobalId} from 'src/utils/graphql-relay';

interface PaymentIdProps {
  payment: G.AllPenaltyPaymentsTable_payment;
  direction: G.PaymentDirection;
  searchBy: G.PenaltyPaymentSearchBy;
  searchText: string;
}

export const PaymentId: React.FunctionComponent<PaymentIdProps> = props => (
  <span>
    <DropdownMenu
      trigger={
        props.searchBy === G.PenaltyPaymentSearchBy.ID ? (
          <Highlighter
            searchWords={[props.searchText]}
            textToHighlight={fromGlobalId(props.payment.id).id}
          />
        ) : (
          fromGlobalId(props.payment.id).id
        )
      }
      triggerType="button"
      triggerButtonProps={{
        iconAfter: <OpenIcon label="Действия" size="small" />,
        appearance: 'subtle-link',
        spacing: 'none',
        // HACK: disable the selected style
        isSelected: false,
      }}
    >
      <DropdownItemGroup>
        <DropdownItem
          onClick={() => {
            navigate(
              `/admin/payments/penalties?direction=${
                props.direction === G.PaymentDirection.INCOMING
                  ? G.PaymentDirection.OUTGOING
                  : G.PaymentDirection.INCOMING
              }&searchBy=REVERSE_ID&searchText=${
                fromGlobalId(props.payment.id).id
              }`,
              {},
            );
          }}
        >
          Найти противоположные платежи
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
    {props.direction === G.PaymentDirection.INCOMING &&
      (props.payment.rawData as any).mongoPaymentId &&
      ` / ${(props.payment.rawData as any).mongoPaymentId}`}
  </span>
);
