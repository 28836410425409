import {State} from 'react-powerplug';
import Button from '@atlaskit/button';
import Highlighter from 'react-highlight-words';
import InlineDialog from '@atlaskit/inline-dialog';
import MoreIcon from '@atlaskit/icon/glyph/more';
import React from 'react';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {formatIsoDateTime} from 'src/utils/formatting';
import {is, Opt} from 'shared/utils/data-types';

const InlineDialogWrapper = styled.div`
  /* HACK: override padding for InlineDialog's content to match that of DropdownMenu */
  & > div {
    padding: 12px;
  }
`;

interface PaymentMetaDetailsProps {
  paymentData: {
    penaltyInfo?: {
      iinNumber: string;
      checkId: number;
      sourceName: string;
    };
    description: string;
    // incoming payment
    alfaBankPaymentRegistrationInfo?: {[key: string]: unknown};
    alfaBankPaymentStatusInfo?: {[key: string]: unknown};
    outgoingPaymentIds?: number[];
    // outgoing payment
    invoiceId?: string;
    payCode?: string;
    walletOnePaymentId?: number;
    walletOnePaymentRegistered?: boolean;
    addedToAcbRegisterAt?: string;
  };
  direction: G.PaymentDirection;
  searchBy: G.PenaltyPaymentSearchBy;
  searchText: string;
}

export const PaymentMetaDetails: React.FunctionComponent<
  PaymentMetaDetailsProps
> = props => (
  <div>
    <small>ИИН плательщика: </small>
    {(() => {
      if (props.searchBy === G.PenaltyPaymentSearchBy.IIN_NUMBER) {
        return (
          <Highlighter
            searchWords={[props.searchText]}
            textToHighlight={Opt.nonEmptyString(
              props.paymentData.penaltyInfo &&
                props.paymentData.penaltyInfo.iinNumber,
            ).getOrElse('-')}
          />
        );
      }

      return (
        props.paymentData.penaltyInfo &&
        Opt.nonEmptyString(props.paymentData.penaltyInfo.iinNumber).getOrElse(
          '-',
        )
      );
    })()}
    {props.direction === G.PaymentDirection.OUTGOING && (
      <>
        <br />
        <small>УКП: </small>
        {props.paymentData.payCode}
        <br />
        <InlineDialogWrapper>
          <State initial={{dialogOpen: false}}>
            {({state, setState}) => (
              <InlineDialog
                content={
                  <span>
                    <small>Номер транзакции: </small>
                    {props.paymentData.invoiceId}
                    {!is.nil(props.paymentData.walletOnePaymentId) && (
                      <>
                        <br />
                        <small>Номер платежа W1: </small>
                        {props.paymentData.walletOnePaymentId}
                      </>
                    )}
                    {is.nonEmptyString(
                      props.paymentData.addedToAcbRegisterAt,
                    ) && (
                      <>
                        <br />
                        <small>Добавлено в реестр АКБ: </small>
                        {formatIsoDateTime(
                          props.paymentData.addedToAcbRegisterAt,
                        )}
                      </>
                    )}
                  </span>
                }
                isOpen={state.dialogOpen}
              >
                <Button
                  iconAfter={<MoreIcon label="Подробнее" />}
                  appearance="subtle"
                  spacing="none"
                  isSelected={state.dialogOpen}
                  onClick={() => {
                    setState(prevState => ({
                      dialogOpen: !prevState.dialogOpen,
                    }));
                  }}
                />
              </InlineDialog>
            )}
          </State>
        </InlineDialogWrapper>
      </>
    )}
  </div>
);
