import gql from 'graphql-tag';

import {G} from 'src/types/graphql';
import {SubscriptionDocument} from 'src/utils/graphql';

import {PenaltyCheck} from './components/PenaltyCheck';

export const RUN_PENALTY_CHECK_SUBSCRIPTION: SubscriptionDocument<
  G.runPenaltyCheck,
  G.runPenaltyCheckVariables
> = gql`
  subscription runPenaltyCheck($input: RunPenaltyCheckInput!) {
    runPenaltyCheck(input: $input) {
      penalties {
        ...PenaltyCheck_penalty
      }
      errorMessage
    }
  }
  ${PenaltyCheck.fragments.penalty}
`;
