import {ApolloError} from 'apollo-client';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';

import {G} from 'src/types/graphql';
import {TypedMutationFn} from 'src/utils/react-apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

import {AllFaqsDragAndDropTableRow} from './AllFaqsDragAndDropTableRow';

interface AllFaqsDragAndDropTableBodyProps {
  data?: G.allFaqsForAdmin;
  faqUpdate: TypedMutationFn<G.faqUpdateWeight, G.faqUpdateWeightVariables>;
  error?: ApolloError;
}

const TableBody = styled.tbody``;

export const AllFaqsDragAndDropTableBody: React.FunctionComponent<
  AllFaqsDragAndDropTableBodyProps
> = props => (
  <DragDropContext
    onDragEnd={result => {
      if (result.destination) {
        props.faqUpdate({
          variables: {
            input: {
              id: result.draggableId,
              newWeight: result.destination.index + 1,
            },
          },
        });
      }
    }}
  >
    <Droppable droppableId="dropable">
      {provided => (
        <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
          {props.data && props.data.allFaqs
            ? nodesFromConnection(props.data.allFaqs).map((faq, index) => (
                <AllFaqsDragAndDropTableRow
                  id={faq.id}
                  key={faq.title}
                  faq={faq}
                  index={index}
                />
              ))
            : []}
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  </DragDropContext>
);
