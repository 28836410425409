import React from 'react';
import qs from 'query-string';

import {G} from 'src/types/graphql';
import {Query, Mutation} from 'src/utils/react-apollo';

import {
  PARTNER_PENALTIES_FOR_CAR_QUERY,
  PARTNER_CAR_PENALTIES_MARK_SEEN,
} from '../graphql';
import {PartnerPenaltiesForCarTable} from '../components/PartnerPenaltiesForCarTable';

interface PartnerPenaltiesForCarTableContainerProps {
  location: Window['location'] | undefined;
  partner: G.partner_partner;
  selectedPenaltyIds: string[];
  onCheckboxChange: (penaltyId: string) => void;
}

export const PartnerPenaltiesForCarTableContainer: React.FunctionComponent<
  PartnerPenaltiesForCarTableContainerProps
> = props => (
  <Mutation
    mutation={PARTNER_CAR_PENALTIES_MARK_SEEN}
    // provide a noop here, otherwise an unhandled error is thrown
    onError={() => {}}
  >
    {partnerCarPenaltiesMarkSeen => {
      if (!props.location) {
        return <></>;
      }
      const partnerCarId = String(qs.parse(props.location.search).id);
      const returnUrl = `https://${props.location.host}/message/?${qs.stringify({
        returnUrl: `${props.location.pathname}${props.location.search}`,
      })}`;

      return (
        <Query
          query={PARTNER_PENALTIES_FOR_CAR_QUERY}
          fetchPolicy="cache-and-network"
          variables={{
            input: {partnerCarId},
          }}
          onCompleted={() => {
            partnerCarPenaltiesMarkSeen({
              variables: {
                input: {
                  partnerCarId,
                },
              },
            });
          }}
        >
          {({loading: partnerPenaltiesLoading, error, data}) => (
            <PartnerPenaltiesForCarTable
              loading={partnerPenaltiesLoading}
              error={error}
              data={data}
              returnUrl={returnUrl}
              selectedPenaltyIds={props.selectedPenaltyIds}
              partner={props.partner}
              onCheckboxChange={props.onCheckboxChange}
            />
          )}
        </Query>
      );
    }}
  </Mutation>
);
