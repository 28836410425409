import {State} from 'react-powerplug';
import {colors} from '@atlaskit/theme';
import Button from '@atlaskit/button';
import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';

import {G} from 'src/types/graphql';

import {PartnerCarDeleteModalDialog} from './PartnerCarDeleteModalDialog';

interface PartnerCarDeleteButtonContainerProps {
  partnerCar: G.allPartnerCars_allPartnerCars_edges_node;
}

export const PartnerCarDeleteButton: React.FunctionComponent<
  PartnerCarDeleteButtonContainerProps
> = props => (
  <State initial={{isDeleteModalOpen: false}}>
    {({state, setState}) => {
      const openDeleteModal = () => setState({isDeleteModalOpen: true});

      return (
        <>
          <Button onClick={openDeleteModal} appearance="subtle">
            {<TrashIcon label="Удалить" primaryColor={colors.R300} />}
          </Button>
          <PartnerCarDeleteModalDialog
            isDeleteModalOpen={state.isDeleteModalOpen}
            setDeleteModalOpenState={isDeleteModalOpen =>
              setState({isDeleteModalOpen})
            }
            partnerCar={props.partnerCar}
          />
        </>
      );
    }}
  </State>
);
