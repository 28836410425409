import {colors, gridSize, layers} from '@atlaskit/theme';
import Button from '@atlaskit/button';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import React from 'react';
import styled from 'styled-components';

const AppBarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: ${colors.B500};
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${gridSize()}px;
  z-index: ${layers.navigation()};
  transition: opacity 220ms;
`;

interface AppBarProps {
  navigationShown: boolean;
  onShowNavigation: () => void;
}

export const AppBar: React.FunctionComponent<AppBarProps> = props => (
  <AppBarContainer>
    <Button
      appearance="subtle"
      ariaLabel="Меню"
      onClick={props.onShowNavigation}
    >
      <MenuIcon label="Меню" size="large" primaryColor="white" />
    </Button>
  </AppBarContainer>
);
