import {
  ApolloClient,
  ApolloQueryResult,
  FetchPolicy,
  MutationOptions,
  ObservableQuery,
  QueryOptions,
  WatchQueryOptions,
} from 'apollo-client';
import {FetchResult} from 'apollo-link';

import {MutationDocument, QueryDocument} from 'src/utils/graphql';
import {Omit} from 'shared/ts-utils';
import {
  TypedDataProxyQuery,
  TypedDataProxyFragment,
  TypedDataProxyWriteDataOptions,
  TypedDataProxy,
} from 'src/utils/apollo-cache';

export type TypedWatchQueryOptions<TData, TVariables> = Omit<
  WatchQueryOptions<TVariables>,
  'query' | 'variables'
> & {
  query: QueryDocument<TData, TVariables>;
} & ([keyof TVariables] extends [never] ? {} : {variables?: TVariables});

export type TypedQueryOptions<TData, TVariables> = Omit<
  QueryOptions<TVariables>,
  'query' | 'variables'
> & {
  query: QueryDocument<TData, TVariables>;
} & ([TVariables] extends [never] ? {} : {variables: TVariables});

export type TypedMutationUpdaterFn<TData> = (
  proxy: TypedDataProxy,
  mutationResult: FetchResult<TData>,
) => void;

export type TypedMutationOptions<TData, TVariables> = Omit<
  MutationOptions<TData, TVariables>,
  'mutation' | 'update' | 'variables' | 'fetchPolicy'
> & {
  mutation: MutationDocument<TData, TVariables>;
  update?: TypedMutationUpdaterFn<TData>;
  // no-cache is the only valid value
  fetchPolicy?: Extract<FetchPolicy, 'no-cache'>;
} & ([TVariables] extends [never] ? {} : {variables: TVariables});

export type TypedWriteQueryOptions<TData, TVariables> = {
  query: QueryDocument<TData, TVariables>;
  data: TData;
} & ([TVariables] extends [never] ? {} : {variables: TVariables});

export type TypedMutationResult<TData, TContext, TExtensionData> = Omit<
  FetchResult<TData, TContext, TExtensionData>,
  'data'
> & {
  data?: TData;
};

export class TypedApolloClient<
  TCacheShape,
  TExtensionData = {}
> extends ApolloClient<TCacheShape> {
  public async query<TData, TVariables = never>(
    options: TypedQueryOptions<TData, TVariables>,
  ): Promise<ApolloQueryResult<TData>> {
    return super.query<TData>(options);
  }

  public async mutate<TData, TVariables = never, TContext = unknown>(
    options: TypedMutationOptions<TData, TVariables>,
  ): Promise<TypedMutationResult<TData, TContext, TExtensionData>> {
    return super.mutate<TData, TVariables>(options);
  }

  public watchQuery<TData, TVariables = never>(
    options: TypedWatchQueryOptions<TData, TVariables>,
  ): ObservableQuery<TData, TVariables> {
    return super.watchQuery<TData, TVariables>(options);
  }

  public readQuery<TData, TVariables = never>(
    options: TypedDataProxyQuery<TData, TVariables>,
    optimistic?: boolean,
  ): TData | null {
    return super.readQuery(options, optimistic);
  }

  public readFragment<TData, TVariables = never>(
    options: TypedDataProxyFragment<TVariables>,
    optimistic?: boolean,
  ): TData | null {
    return super.readFragment(options, optimistic);
  }

  public writeQuery<TData, TVariables = never>(
    options: TypedDataProxyQuery<TData, TVariables> & {data: TData},
  ): void {
    return super.writeQuery(options);
  }

  public writeFragment<TData, TVariables = never>(
    options: TypedDataProxyFragment<TVariables> & {data: TData},
  ): void {
    return super.writeFragment(options);
  }

  public writeData<TData>(
    options: TypedDataProxyWriteDataOptions<TData>,
  ): void {
    return super.writeData(options);
  }
}
