import {Checkbox} from '@atlaskit/checkbox';
import {FieldArray, Formik, FormikErrors} from 'formik';
import {FieldTextStateless} from '@atlaskit/field-text';
import Button from '@atlaskit/button/dist/es5/components/Button';
import Form, {FormFooter, FormHeader, FormSection} from '@atlaskit/form';
import React from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import {FormField} from 'src/components/FormField';
import {InlineFormWrapper} from 'src/components/styled';
import {is} from 'shared/utils/data-types';

interface PushFormProps {
  initialValues: {
    title: string;
    body: string;
    data: {[key: string]: string};
    tokens: string[];
    disableLogging: boolean;
  };
  updateState: (input: {
    title: string;
    body: string;
    data: any;
    tokens: string[];
    disableLogging: boolean;
  }) => any;
  onPushNotificationSend: (values: {
    title: string;
    body: string;
    data: {[key: string]: string};
    tokens: string[];
    disableLogging: boolean;
  }) => any;
}

const FieldBaseWrapper = styled.div`
  display: flex;
  margin: 15px 0;
`;
const FieldTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
const FieldButtonWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 32px;
`;

export class PushForm extends React.Component<PushFormProps> {
  public render() {
    return is.nonEmptyObject(this.props.initialValues) ? (
      <InlineFormWrapper>
        <Formik
          enableReinitialize
          initialValues={{
            title: this.props.initialValues.title,
            body: this.props.initialValues.body,
            data: this.props.initialValues.data,
            tokens: this.props.initialValues.tokens,
            disableLogging: this.props.initialValues.disableLogging,
          }}
          validate={values => {
            const mutableErrors: FormikErrors<typeof values> = {};
            if (!is.nonEmptyString(values.title)) {
              mutableErrors.title = 'Пожалуйста, заполните заголовок.';
            }
            if (!is.nonEmptyString(values.body)) {
              mutableErrors.body = 'Пожалуйста, заполните тело.';
            }

            return mutableErrors;
          }}
          onSubmit={values => {
            this.props.onPushNotificationSend({
              title: values.title,
              body: values.body,
              tokens: values.tokens,
              data: this.props.initialValues.data,
              disableLogging: values.disableLogging,
            });
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <FormHeader title="Отправить пуш(ы)" />
              <FormSection>
                <FormField
                  label="Заголовок"
                  inputId="input-title"
                  inputRequired
                  invalid={touched.title && is.nonEmptyString(errors.title)}
                  invalidMessage={errors.title}
                >
                  <FieldTextStateless
                    id="input-title"
                    type="text"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите заголовок"
                    value={values.title}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>
                <FormField label="Тело" inputId="input-body">
                  <FieldTextStateless
                    id="input-body"
                    type="text"
                    name="body"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Введите тело"
                    value={values.body}
                    required
                    isLabelHidden
                    shouldFitContainer
                  />
                </FormField>
                <FormField label="Данные" inputId="input-data" inputRequired>
                  <ReactJson
                    src={values.data}
                    name={false}
                    enableClipboard={false}
                    displayObjectSize={false}
                    onEdit={edit =>
                      this.props.updateState({
                        title: values.title,
                        body: values.body,
                        data: edit.updated_src,
                        tokens: values.tokens,
                        disableLogging: values.disableLogging,
                      })
                    }
                    onAdd={add =>
                      this.props.updateState({
                        title: values.title,
                        body: values.body,
                        data: add.updated_src,
                        tokens: values.tokens,
                        disableLogging: values.disableLogging,
                      })
                    }
                    onDelete={del =>
                      this.props.updateState({
                        title: values.title,
                        body: values.body,
                        data: del.updated_src,
                        tokens: values.tokens,
                        disableLogging: values.disableLogging,
                      })
                    }
                  />
                </FormField>
                <FormField
                  label="Токен(ы)"
                  inputId="input-tokens"
                  inputRequired
                  invalid={touched.tokens && is.nonEmptyArray(errors.tokens)}
                >
                  <FieldArray
                    name="tokens"
                    render={arrayHelpers => (
                      <div>
                        {values.tokens.length !== 0
                          ? values.tokens.map((token, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <FieldBaseWrapper key={index}>
                                <FieldTextWrapper>
                                  <FieldTextStateless
                                    id={`input-tokens.${index}`}
                                    type="text"
                                    name={`tokens.${index}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Введите токен"
                                    value={token}
                                    required
                                    isLabelHidden
                                    shouldFitContainer
                                  />
                                </FieldTextWrapper>
                                <FieldButtonWrapper>
                                  <Button
                                    type="button"
                                    appearance="danger"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </FieldButtonWrapper>
                              </FieldBaseWrapper>
                            ))
                          : null}
                        <Button
                          appearance="default"
                          type="button"
                          onClick={() => arrayHelpers.push('')}
                        >
                          Добавить токен
                        </Button>
                      </div>
                    )}
                  />
                </FormField>
                <FormField
                  label="Отключить логирование в базу"
                  inputId="input-disable_logging"
                >
                  <Checkbox
                    id="input-disable_logging"
                    name="disable_logging"
                    value={values.disableLogging}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              </FormSection>
              <FormFooter>
                <Button appearance="primary" type="submit">
                  Отправить
                </Button>
                <Button appearance="link" type="button">
                  Отмена
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </InlineFormWrapper>
    ) : null;
  }
}
