import {Grid, GridColumn} from '@atlaskit/page';
import {RouteComponentProps} from '@reach/router';
import React from 'react';

import {ProtectedAppPage} from 'src/containers/ProtectedAppPage';
import {UserRole} from 'src/types/auth';

import {PartnerCarparkNavigation} from '../navigation';
import {PartnerPenaltiesForCarContainer} from './containers/PartnerPenaltiesForCarContainer';

interface PartnerPenaltiesForCarPageParams {}
interface PartnerPenaltiesForCarPageProps
  extends RouteComponentProps<PartnerPenaltiesForCarPageParams> {}

export const PartnerPenaltiesForCarPage: React.FunctionComponent<
  PartnerPenaltiesForCarPageProps
> = props => (
  <ProtectedAppPage
    location={props.location}
    NavigationComponent={PartnerCarparkNavigation}
    roles={[UserRole.PartnerCarpark]}
  >
    <Grid layout="fluid" spacing="comfortable">
      <GridColumn>
        <PartnerPenaltiesForCarContainer location={props.location} />
      </GridColumn>
    </Grid>
  </ProtectedAppPage>
);
