import {ApolloError} from 'apollo-client';
import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import Badge from '@atlaskit/badge';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import Highlighter from 'react-highlight-words';
import Pagination from '@atlaskit/pagination';
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import gql from 'graphql-tag';
import qs from 'query-string';

import {G} from 'src/types/graphql';
import {Opt, is} from 'shared/utils/data-types';
import {TableContainer} from 'src/components/styled';
import {formatIsoDateTime} from 'src/utils/formatting';
import {messageForApolloError} from 'src/apollo';
import {nodesFromConnection} from 'src/utils/graphql-relay';

interface AllCarsTableProps {
  loading: boolean;
  error?: ApolloError;
  data?: G.allPartnerCars;
  searchText: string;
  pageIdx: number;
  onSetPageIdx: (pageIdx: number) => any;
  DeleteButton: React.ComponentType<{
    partnerCar: G.allPartnerCars_allPartnerCars_edges_node;
  }>;
  rowsPerPage: number;
  onSetRowsPerPage: (rowsPerPage: number) => any;
}

export class AllCarsTable extends React.Component<AllCarsTableProps> {
  public static fragments = {
    partner: gql`
      fragment AllCarsTable_partner on Partner {
        id
        name
        carsCount
        payMode
      }
    `,
    iinNumber: gql`
      fragment AllCarsTable_iinNumber on IinNumberInfoPayload {
        name
      }
    `,
    partnerCars: gql`
      fragment AllCarsTable_partnerCars on PartnerCarConnection {
        edges {
          node {
            id
            vin
            carNumber
            carTechPassport
            penaltiesSeenAt
            unpaidPenaltiesCount
          }
        }
        totalPageCount
      }
    `,
    deletedPartnerCar: gql`
      fragment AllCarsTable_deletedPartnerCar on PartnerCarDeletePayload {
        partnerCar {
          id
          vin
          carNumber
          carTechPassport
        }
      }
    `,
    createdPartnerCar: gql`
      fragment AllCarsTable_createdPartnerCar on PartnerCarCreatePayload {
        partnerCar {
          id
          vin
          carNumber
          carTechPassport
        }
      }
    `,
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    return (
      <TableContainer>
        <DynamicTableStateless
          head={{
            cells: [
              {
                key: 'carNumber',
                content: 'Номер машины',
              },
              {
                key: 'carTechPassport',
                content: 'Номер техпаспорта',
              },
              {
                key: 'vin',
                content: 'Вин-код',
              },
              {
                key: 'penaltiesSeenAt',
                content: 'Штрафы просмотрены',
              },
              {
                key: 'more',
                content: (
                  <DropdownMenu
                    trigger={
                      this.props.rowsPerPage === 0
                        ? 'Все'
                        : this.props.rowsPerPage
                    }
                    triggerType="button"
                    position="bottom right"
                  >
                    <DropdownItemGroup>
                      <DropdownItem
                        onClick={() => {
                          this.props.onSetRowsPerPage(25);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.props.onSetRowsPerPage(50);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.props.onSetRowsPerPage(0);
                        }}
                      >
                        Все
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                ),
              },
            ],
          }}
          rows={
            this.props.data && this.props.data.allPartnerCars
              ? nodesFromConnection(this.props.data.allPartnerCars).map(
                  car => ({
                    key: car.id,
                    cells: [
                      {
                        key: car.carNumber,
                        content: (
                          <a
                            href={`/partner/carpark/penalties/?${qs.stringify({
                              id: car.id,
                              carNumber: car.carNumber,
                            })}`}
                          >
                            <>
                              <Highlighter
                                searchWords={this.props.searchText.split(' ')}
                                textToHighlight={`${car.carNumber}${
                                  car.unpaidPenaltiesCount !== 0 ? ' ❗️' : ''
                                }`}
                              />
                              {car.unpaidPenaltiesCount !== 0 ? (
                                <Badge>{car.unpaidPenaltiesCount}</Badge>
                              ) : (
                                <></>
                              )}
                            </>
                          </a>
                        ),
                      },
                      {
                        key: car.carTechPassport,
                        content: (
                          <Highlighter
                            searchWords={this.props.searchText.split(' ')}
                            textToHighlight={car.carTechPassport}
                          />
                        ),
                      },
                      {
                        key: car.vin,
                        content: car.vin,
                      },
                      {
                        key: car.penaltiesSeenAt,
                        content: Opt.nonEmptyString(car.penaltiesSeenAt)
                          .map(penaltiesSeenAt =>
                            formatIsoDateTime(penaltiesSeenAt),
                          )
                          .getOrElse('-'),
                      },
                      {
                        content: <this.props.DeleteButton partnerCar={car} />,
                      },
                    ],
                  }),
                )
              : []
          }
          emptyView={
            <SectionMessage appearance={this.props.error ? 'error' : 'warning'}>
              {this.props.error
                ? messageForApolloError(this.props.error)
                : 'Ничего не найдено'}
            </SectionMessage>
          }
          loadingSpinnerSize="large"
          isLoading={this.props.loading}
        />
        {this.props.data &&
          this.props.data.allPartnerCars &&
          is.positiveInt(this.props.data.allPartnerCars.totalPageCount) && (
            <Pagination
              selectedIndex={this.props.pageIdx}
              pages={Array.from(
                {length: this.props.data.allPartnerCars.totalPageCount},
                (_, idx) => idx + 1,
              )}
              onChange={(_event: Event, pageLabel: number) =>
                this.props.onSetPageIdx(pageLabel - 1)
              }
              i18n={{
                prev: 'Предыдущая',
                next: 'Следующая',
              }}
            />
          )}
      </TableContainer>
    );
  }
}
